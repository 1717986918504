import { Theme } from '@emotion/react';
import { extendTheme } from '@mui/joy';
import { createTheme } from '@mui/material';
import type { PaletteRange } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
		third: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
		third: PaletteRange;
  }

	interface PaletteRange {
		shadowColor: string;
		shadowBorderColor: string;
		shadowHighColor: string;
	}
}
declare module '@mui/joy/Button' {
	interface ButtonPropsVariantOverrides {
		shadowed: true;
	}
}

declare module '@mui/joy/IconButton' {
	interface IconButtonPropsVariantOverrides {
		shadowed: true;
	}
}

export const colors = {
	black: '#000000',
	yellow: {
		light: '#fff2ab',
		main: '#FFD700',
		dark: '#cbad02',
	}, blue: {
		light: '#aadcfc',
		main: '#87CEFA',
		dark: '#87CEFA',
	},
};

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
  }
}

export const muiTheme = createTheme({
	typography: {
		fontFamily: 'Inter, sans-serif',
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					padding: '1rem',
					boxShadow: 'none',
					border: '1px solid #E4E4E4',
					borderRadius: '8px',
				}
			}
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
				}
			},
			defaultProps: {
				animation: 'wave',
			}
		},
	},
});


export const joyTheme = extendTheme({
	fontFamily: {
		body: 'Inter, sans-serif',
	},
	components: {
		JoySkeleton: {
			defaultProps: {
				animation: 'wave',
			},
		},
		JoyModal: {
			styleOverrides: {
				backdrop: {
					backdropFilter: 'blur(2px)',
				}
			},
		},
		JoyListItemButton: {
			styleOverrides: {
				root: {
					borderRadius: '5px',
				},
			},
		},
		JoyButton: {
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					...ownerState.variant === 'shadowed' && {
						boxShadow: `0 -3px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 2px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
						backgroundColor: theme.vars.palette[ownerState.color]?.solidBg,
						color: theme.vars.palette[ownerState.color]?.solidColor,
						border: `1px solid ${theme.vars.palette[ownerState.color]?.shadowBorderColor}`,
						transition: 'box-shadow 0.05s ease, background-color 0.05s ease',
						'&:hover': {
							backgroundColor: theme.vars.palette[ownerState.color]?.solidHoverBg,
							boxShadow: `0 -2px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 0px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
						},
						'&:active': {
							boxShadow: `0 0px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 0px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
						},
						'&:disabled': {
							boxShadow: `0 0px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 0px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
							filter: 'brightness(0.95)',
						},
						...ownerState.size === 'sm' && {
							borderRadius: '5px',
						}
					},
				}),
			}
		},
		JoyIconButton: {
			styleOverrides: {
				root: ({ theme, ownerState }) => ({
					...ownerState.variant === 'shadowed' && {
						boxShadow: `0 -3px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 2px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
						backgroundColor: theme.vars.palette[ownerState.color]?.solidBg,
						color: theme.vars.palette[ownerState.color]?.solidColor,
						border: `1px solid ${theme.vars.palette[ownerState.color]?.shadowBorderColor}`,
						transition: 'box-shadow 0.05s ease',
						'&:hover': {
							backgroundColor: theme.vars.palette[ownerState.color]?.solidHoverBg,
							boxShadow: `0 -2px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 0px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
						},
						'&:active': {
							boxShadow: `0 0px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 0px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
						},
						'&:disabled': {
							boxShadow: `0 0px inset ${theme.vars.palette[ownerState.color]?.shadowColor}, 0 0px inset ${theme.vars.palette[ownerState.color]?.shadowHighColor}`,
							filter: 'brightness(0.95)',
						},
					},
				}),
			},
		},
	},
	colorSchemes: {
		light: {
			palette: {
				text: {
					primary: '#333333',
					secondary: '#5a5a5a',
				},
				divider: '#E4E4E4',
				secondary: {
					'50': '#f9fafb',
					'100': '#f3f4f6',
					'200': '#e5e7eb',
					'300': '#d1d5db',
					'400': '#9ca3af',
					'500': '#6b7280',
					'600': '#4b5563',
					'700': '#374151',
					'800': '#1f2937',
					'900': '#111827',
					solidBg: '#333333',
					shadowColor: '#000000',
					shadowBorderColor: 'transparent',
					shadowHighColor: '#444444',
					solidHoverBg: '#2a2a2a',
					solidColor: '#ffffff',
					softBg: '#eaeaea',
					softColor: '#333333',
				},
				third: {
					solidBg: '#f9f9f9',
					solidHoverBg: '#f5f5f5',
					shadowBorderColor: '#E4E4E4',
					solidColor: '#333333',
					shadowColor: '#E4E4E4',
					shadowHighColor: '#ffffff',
				},
			}
		}
	} });

