import styled from '@emotion/styled';

const Main = styled.main`
	padding: 1rem;
	max-width: 100%;
	box-sizing: border-box;
	width: 1400px;
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: hidden;
	max-width: 100%;
	@media (max-width: 768px) {
		padding: 1rem
	}
`;

export default Main;
