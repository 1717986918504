// import 'core-js/stable';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './public/index.css';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { joyTheme, muiTheme } from '@/styles/theme';
import App from './App';
import { AppProvider } from './context/AppContext';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
	experimental_extendTheme as materialExtendTheme,
	Experimental_CssVarsProvider as MaterialCssVarsProvider,
	THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import 'reactflow/dist/style.css';
import { BuilderContextProvider } from './pages/Builder/BuilderContext';

const container = document.getElementById('root');


const options: Partial<PostHogConfig> = {
	api_host: 'https://us.i.posthog.com',
	person_profiles: 'always',
};

if (!container) {
	throw new Error('No root element found');
}

const PostHogProviderWrapper = ({ children }: { children: React.ReactNode }) => {
	if (window.location.origin.includes('localhost')) {
		return children;
	} else {
		return (
			<PostHogProvider apiKey='phc_uWve9EDT3UICDnt1l5toiR9SvNBOndI2JK8sOK1LeKj' options={options}>
				{children}
			</PostHogProvider>
		);
	}
};


const root = createRoot(container);
const materialTheme = materialExtendTheme(muiTheme);

root.render((
	<AppProvider>
		<BuilderContextProvider>
			<PostHogProviderWrapper>
				<MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
					<JoyCssVarsProvider theme={joyTheme}>
						<CssBaseline>
							<Router>
								<App />
							</Router>
						</CssBaseline>
					</JoyCssVarsProvider>
				</MaterialCssVarsProvider>
			</PostHogProviderWrapper>
		</BuilderContextProvider>
	</AppProvider>
));

// serviceWorker.unregister();
