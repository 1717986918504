import React from 'react';

export default function Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			clipRule="evenodd"
			fillRule="evenodd"
			height="512"
			strokeLinejoin="round"
			strokeMiterlimit="2"
			viewBox="0 0 24 24"
			width="512"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g id="Icon">
				<path d="m21.189 19.574c.809-1.124.947-2.598.362-3.853-.407-.872-.896-1.922-1.37-2.937-.492-1.055-1.382-1.871-2.475-2.269-1.094-.398-2.301-.345-3.355.147-.583.271-.991.462-.991.462-.503.113-.938.031-1.156-.436-.387-.832-1.003-2.151-1.48-3.175-.185-.396-.519-.702-.929-.851s-.862-.13-1.258.055c-.395.184-.701.518-.85.928-.15.41-.13.863.055 1.258 1.186 2.545 3.476 7.455 3.476 7.455s-1.27-.525-2.402-.992c-.451-.186-.958-.18-1.405.016-.446.197-.793.568-.96 1.026 0 .001-.001.002-.001.003-.331.909.131 1.915 1.036 2.256 1.546.583 3.631 1.368 3.631 1.368 1.67.608 2.105.813 2.734 2.008.266.41.794.562 1.238.355 1.873-.852 6.1-2.824 6.1-2.824z" fillRule="nonzero" />
				<path d="m8.205 4.642c.175.376.013.822-.362.997s-.822.013-.997-.363l-.867-1.86c-.175-.375-.013-.822.362-.997s.822-.012.997.363z" />
				<path d="m11.6 4.445c-.141.389-.572.59-.961.449-.389-.142-.59-.573-.448-.962l.702-1.929c.141-.389.572-.589.961-.448.389.142.59.572.448.961z" />
				<path d="m5.026 6.341c.389.141.59.572.448.961-.141.389-.572.59-.961.448l-1.929-.702c-.389-.141-.59-.572-.448-.961.141-.389.572-.59.961-.448z" />
				<path d="m5.006 9.27c.375-.175.822-.012.997.363s.012.822-.363.997l-1.86.867c-.375.175-.822.013-.997-.362-.175-.376-.012-.822.363-.997z" />
				<path d="m14.308 4.933c.375-.175.822-.013.997.363.175.375.012.821-.363.996l-1.86.868c-.375.175-.822.012-.997-.363s-.012-.822.363-.997z" />
			</g>
		</svg>
	);
}
