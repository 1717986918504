import axios from 'axios';

let BASE_URL = window.location.origin;

if (ENVIRONMENT === 'production' && !BASE_URL.includes('localhost')) {
	if (!BASE_URL.startsWith('https://')) {
		BASE_URL = BASE_URL.replace('http://', 'https://');
	}
}


class API {
	static async getUserFromSession() {
		const user = await axios.get(BASE_URL + '/api/user/from-session');

		return user.data;
	}

	static async viewAsUser(email: string) {
		const res = await axios.post(BASE_URL + '/api/admin/view-as', { email });
		const { data } = res;
		if (!data?.id) {
			console.error('User not found');
			return;
		}
		document.cookie = `view-as=${data.publicId}`;
		window.location.href = '/';
	}

	static async clearViewAs() {
		document.cookie = 'view-as=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = '/';
	}

	static async logout() {
		await axios.post(BASE_URL + '/api/user/logout');
	}

	static async createChatMessage(message: string) {
		const res = await axios.post(BASE_URL + '/api/chat/message', { message });
		return res.data;
	}

	static async getIntegrationSources() {
		const res = await axios.get(BASE_URL + '/api/integration-sources');
		return res.data;
	}

	static async createIntegration(integrationSourceId: string, data: any) {
		const res = await axios.post(BASE_URL + '/api/integrations', { integration_source_id: integrationSourceId, data });
		return res.data;
	}

	static async getIntegrations() {
		const res = await axios.get(BASE_URL + '/api/integrations');
		return res.data;
	}

	static async getIntegrationByName(name: string) {
		const res = await axios.get(`${BASE_URL}/api/integrations/${name}`);
		return res.data;
	}

	static async getUsage() {
		const res = await axios.get(BASE_URL + '/api/usage');
		return res.data;
	}

	static async getBrowserSessions() {
		const res = await axios.get(BASE_URL + '/api/browser/sessions');
		return res.data;
	}

	static async callIntegrationMethod(integrationMethodId: number, userIdentifier: string, params: any) {
		const res = await axios.post(`${BASE_URL}/api/integrations/method/${integrationMethodId}/call`, { user_identifier: userIdentifier, params });
		return res.data;
	}

	static async getBuilderSession() {
		const res = await axios.get(BASE_URL + '/api/browser/session/builder');
		return res.data;
	}

	static async createWebFlow(webFlow: any) {
		const res = await axios.post(BASE_URL + '/api/web-flows', webFlow);
		return res.data;
	}

	static async getWebFlows() {
		const res = await axios.get(BASE_URL + '/api/web-flows');
		return res.data;
	}

	static async runWebFlowOnBrowserSession(webFlowId: number, browserSessionId: string, parameters: any, options: any) {
		const res = await axios.post(`${BASE_URL}/api/web-flows/${webFlowId}/run-on-browser-session/${browserSessionId}`, { parameters, options }, {
			timeout: 360000 // 6 minutes timeout to match server-side timeout
		});
		return res.data;
	}

	static async runWebFlow(webFlowId: number, parameters: any, options: any) {
		const res = await axios.post(`${BASE_URL}/api/web-flows/${webFlowId}/run`, { parameters, options });
		return res.data;
	}

	static async runNodeOnBrowserSession(node: any, browserSessionId: string) {
		const res = await axios.post(`${BASE_URL}/api/web-flows/run-node-on-browser-session/${browserSessionId}`, { node });
		return res.data;
	}

	static async getWebFlowRuns() {
		const res = await axios.get(`${BASE_URL}/api/web-flow-runs`);
		return res.data;
	}

	static async submitContactForm(message: string) {
		await axios.post(`${BASE_URL}/api/user/contact-form`, { message });
	}

	static async navigateBrowserSession(browserSessionId: string, url: string) {
		const res = await axios.post(`${BASE_URL}/api/browser/session/${browserSessionId}/navigate`, { url });
		return res.data;
	}

	static async runWebTask(browserSessionId: string, task: any, startUrl?: string) {
		const res = await axios.post(`${BASE_URL}/api/plato/task`, { task, session_id: browserSessionId, start_url: startUrl });
		return res.data;
	}

	static async getBrowserSessionLogs(browserSessionId: string) {
		const res = await axios.get(`${BASE_URL}/api/browser/session/${browserSessionId}/logs`);
		return res.data;
	}
}

export default API;
