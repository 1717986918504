import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import Header, { HeaderLanding } from '@/components/Header';
import axios from 'axios';
// import Plan from './Plan';
import SocialProof from './SocialProof';
import PricingFAQs from './PricingFAQ';
import Footer from '@/components/Footer';
import { Box, Chip, Typography, Button, AspectRatio } from '@mui/joy';
import { NavLink } from 'react-router-dom';
import RocketIcon from '@/icons/RocketIcon';
import Plan, { PlanType } from './Plan';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
	max-width: 900px;
	margin: 0 auto;
	padding-top: 8rem;
	min-height: 100vh;
`;

const Main = styled.main`
	background-color: #ffffff;
	padding: 1rem;
	padding-bottom: 4rem;
`;

const TopContainer = styled.div`
	padding-top: 8rem;
	padding-bottom: 12rem;
	padding-left: 2rem;
	padding-right: 2rem;
	position: relative;
	background-color: #ffffff;
	background-size: 42px 42px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	@media (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;

const RadialGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	mask-image: radial-gradient(ellipse at center,transparent 1%,#000 75%);
	background-color: white;
`;

const HeroContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	z-index: 10;
	position: relative;
	gap: 1rem;
	max-width: 1000px;
	margin: 0 auto;
`;


export const PlansContainer = styled.div`
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
	z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
	@media (max-width: 900px) {
		grid-template-columns: 1fr;
		max-width: 350px;
	}
  gap: 1rem;
	width: 100%;
	padding-bottom: 4rem;
`;


export const plans: PlanType[] = [
	// {
	// 	name: 'Free',
	// 	price: 0,
	// 	features: {
	// 		numCustomAutomations: 1,
	// 		numRequests: 100,
	// 	},
	// 	additionalFeatures: ['Email support']
	// },
	// {
	// 	name: 'Pro',
	// 	price: 49,
	// 	features: {
	// 		numCustomAutomations: 5,
	// 		numRequests: 1000,
	// 	},
	// 	additionalFeatures: ['Priority support']
	// },
	{
		name: 'Custom',
		price: null,
		features: {
		// numCustomAutomations: 20,
		// numRequests: 10000,
		},
		additionalFeatures: ['Priority support']
	},
	// {
	// 	name: 'Enterprise',
	// 	price: null,
	// 	features: {
	// 		numCustomAutomations: 100,
	// 		numRequests: 50000,
	// 	},
	// 	additionalFeatures: ['Priority support']
	// }
];

const Pricing = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<HeaderLanding />
			<Main>
				<TopContainer>
					<HeroContent>
						<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' marginBottom={6}>
							<Chip size='md' sx={{ mb: 0.5 }} startDecorator={<RocketIcon width={12} height={12} fill='currentColor' />} color='primary'>Pricing Plans</Chip>
							<Typography fontFamily='"Source Code Pro", sans-serif' textAlign='center' lineHeight={1.1} letterSpacing={-2.5} fontSize={58} level='h1'>Choose a Plan</Typography>
							<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
							Start building browser-based integrations with confidence.
							</Typography>
						</Box>
					</HeroContent>
					<RadialGradient />
				</TopContainer>
				<PlansContainer style={{ top: '-10rem' }}>
					<div/>
					{plans.map((plan) => (
						<Plan key={plan.name} plan={plan} />
					))}
					<div/>
				</PlansContainer>
				<Box display='flex' flexDirection='column' alignItems='center' >
					<Typography marginBottom={4} fontFamily='"Source Code Pro", sans-serif' textAlign='center' lineHeight={1.0} letterSpacing={-2.3} fontSize={42} level='h2'>Frequently Asked Questions</Typography>
					<PricingFAQs />
				</Box>
			</Main>
			<Footer />
		</div>
	);
};

export default Pricing;
