import { Box, Input, Divider, Button, Select, Option, Chip, Typography, IconButton, Tooltip, Switch } from '@mui/joy';
import React, { useContext, useEffect, useState } from 'react';
import ParameterInput from './ParameterInput';
import Events from '../Events';
import API from '@/api';
import { BuilderContext } from '../BuilderContext';
import PlusIcon from '@/icons/PlusIcon';

interface WebActionProps {
	action: any;
	onChange: (action: any) => void;
	onRemove: () => void;
	activeId: string;
	onSelected: (action: any) => void;
}

const WebAction = ({ action, onChange, onRemove, activeId, onSelected }: WebActionProps) => {
	const { browserSession, runningAction } = useContext(BuilderContext);
	const { filters, subNodes } = action;
	const isActive = activeId === action.id;
	const isRunning = runningAction?.id === action.id;
	const [isSelectingElement, setIsSelectingElement] = useState(false);

	useEffect(() => {
		function handleActiveActionEvent(event) {
			if (isSelectingElement) {
				onChange({ element: event.detail.action.element });
			} else {
				const newSubNodes = subNodes ? [...subNodes] : [];
				const newAction = event.detail.action;
				newSubNodes.push(newAction);
				onChange({ subNodes: newSubNodes });
			}
		}

		if (isActive) {
			window.addEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		} else {
			window.removeEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		}
		return () => {
			window.removeEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		};
	}, [isActive, filters, subNodes, action, isSelectingElement]);


	const onAddFilter = () => {
		const newFilter = {
			element: null,
			attribute: null,
			operator: 'equals',
			value: '',
		};
		onChange({ filters: [...(filters || []), newFilter] });
	};

	const onAddSubAction = () => {
		const newSubAction = {
			id: `action_${Date.now()}`,
			type: 'action',
			parameter: {
				type: 'text',
				isArray: false,
				name: ''
			},
		};
		onChange({ subNodes: [...(subNodes || []), newSubAction] });
	};

	const onAddPagination = () => {
		const newSubAction = {
			id: `action_${Date.now() + 1}`,
			type: 'pagination',
			parameter: {
				name: '',
				type: 'pagination',
				isArray: false,
			},
		};
		onChange({ subNodes: [...(subNodes || []), newSubAction] });
	};

	const onRun = () => {
		API.runActionOnBrowserSession(action, browserSession.publicId);
	};

	const onSelectElement = () => {
		setIsSelectingElement(!isSelectingElement);
	};
	return (
		<Box display='flex' flexDirection='column' position='relative'>
			<Box
				display='flex'
				flexDirection='column'
				onClick={(e) => {
					e.stopPropagation();
					onSelected(action);
				}}>
				<ParameterInput
					key={action.id}
					onRemove={onRemove}
					isActive={isActive}
					parameter={action.parameter}
					onAddFilter={onAddFilter}
					onSelectElement={onSelectElement}
					isRunning={isRunning}
					onRun={onRun}
					onChange={(update) => {
						onChange({ ...action, parameter: { ...action.parameter, ...update } });
					}}
				/>
				{filters?.map((filter, index) => (
					<>
						<Divider />
						<Box key={index} display='grid' gap={1} gridTemplateColumns='1fr'>
							<Input
								value={filter.value}
								size='sm'
								fullWidth
								sx={{
									paddingLeft: 0,
									flexGrow: 1,
									borderRadius: 0,
									'&:before': {
										boxShadow: 'none',
									},
								}}
								variant='plain'
								placeholder='value'
								onChange={(e) => {
									const newFilters = [...filters];
									newFilters[index].value = e.target.value;
									onChange({ filters: newFilters });
								}}
								startDecorator={
									<>
										<Button size='sm' variant='plain' color='neutral' sx={{ whiteSpace: 'nowrap', borderTopRightRadius: 0, borderBottomRightRadius: 0, margin: 0, height: '100%', fontSize: '12px', paddingX: '6px' }}>
											{filter.element ? `${filter.element.tagName.toLowerCase()}.${filter.attribute}` : '-'}
										</Button>
										<Divider orientation='vertical' />
										<Select
											size='sm'
											variant='plain'
											color='neutral'
											sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRadius: 0, overflow: 'hidden' }}
											defaultValue='equals'
											indicator={null}
											renderValue={(value) => {
												return <Chip size='sm' variant='outlined' color='neutral'>{value.label}</Chip>;
											}}
											value={filter.operator}
											onChange={(e, newValue) => {
												const newFilters = [...filters];
												newFilters[index].operator = newValue;
												onChange({ filters: newFilters });
											}}
										>
											<Option value='exists'><Chip size='sm' variant='outlined' color='neutral'>Exists</Chip></Option>
											<Option value='equals'><Chip size='sm' variant='outlined' color='neutral'>Equals</Chip></Option>
											<Option value='contains'><Chip size='sm' variant='outlined' color='neutral'>Contains</Chip></Option>
											<Option value='regex'><Chip size='sm' variant='outlined' color='neutral'>Regex</Chip></Option>
										</Select>
										<Divider orientation='vertical' />
									</>
								}
							/>
						</Box>
					</>
				))}
			</Box>
			{['object', 'act', 'click', 'extract', 'navigate'].includes(action.parameter?.type) && (
				<>
					<Box display='flex' flexDirection='column' paddingLeft={2} bgcolor='#E4E4E4'>
						<>
							{isActive && !['navigate'].includes(action.parameter?.type) && (
								<>
									<Divider />
									<Box display='flex' justifyContent='flex-end'>
										<Button
											onClick={onAddSubAction}
											size='sm'
											variant='soft'
											color='neutral'
											sx={{
												borderRadius: 0,
												justifyContent: 'flex-start',
												backgroundColor: 'var(--joy-palette-background-surface)',
												'&:hover': {
													backgroundColor: 'var(--joy-palette-background-surface)',
												},
											}}
											fullWidth
											startDecorator={
												<Chip size='sm' variant='outlined' color='neutral'
													startDecorator={<PlusIcon fill='currentColor' width={10} height={10} />}
												>
													Add
												</Chip>
											}
										/>
									</Box>
								</>
							)}
							{isActive && ['navigate'].includes(action.parameter?.type) && !action.subNodes.length && (
								<>
									<Divider />
									<Box display='flex' justifyContent='flex-end'>
										<Button
											onClick={onAddPagination}
											size='sm'
											variant='soft'
											color='neutral'
											sx={{
												borderRadius: 0,
												justifyContent: 'flex-start',
												backgroundColor: 'var(--joy-palette-background-surface)',
												'&:hover': {
													backgroundColor: 'var(--joy-palette-background-surface)',
												},
											}}
											fullWidth
											startDecorator={
												<Chip size='sm' variant='outlined' color='neutral'
													startDecorator={<PlusIcon fill='currentColor' width={10} height={10} />}
												>
													Pagination
												</Chip>
											}
										/>
									</Box>
								</>
							)}

							{subNodes?.map((subAction, index) => (
								<>
									<Divider />
									<WebAction
										key={subAction.id}
										action={subAction}
										activeId={activeId}
										onSelected={onSelected}
										onChange={(update) => {
											const newSubNodes = [...subNodes];
											newSubNodes[index] = { ...newSubNodes[index], ...update };
											onChange({ subNodes: newSubNodes });
										}}
										onRemove={() => {
											const newSubNodes = [...subNodes];
											newSubNodes.splice(index, 1);
											onChange({ subNodes: newSubNodes });
										}}
									/>
								</>
							))}
						</>
					</Box>
				</>
			)}
		</Box>
	);
};

export default WebAction;
