import React from 'react';

export default function LogoIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<g clipPath='url(#clip0_6680_2705)'>
				<path fillRule='evenodd' clipRule='evenodd' d='M247.154 0.0372906C287.793 -0.239147 331.198 1.00483 377.09 4.32208C446.758 9.02152 502.465 64.1709 507.579 133.833C510.62 174.47 512 215.244 512 256.019C512 296.379 510.621 336.738 507.718 377.098C502.88 446.761 447.588 502.463 377.782 507.577C337.281 510.48 296.64 512 256 512C215.637 512 175.273 510.48 134.91 507.577C65.1038 502.878 9.53444 447.59 4.28171 377.789C1.37889 337.43 0 296.931 0 256.295C0 215.797 1.37889 175.299 4.28171 134.801C9.11975 65.1384 64.277 9.43618 134.083 4.32208C168.64 1.83414 206.514 0.175509 247.154 0.0372906ZM155.783 246.896L228.078 279.378C245.772 287.256 266.228 287.256 283.922 279.378L356.217 246.896C368.382 241.506 376.12 229.481 376.12 216.073C376.12 202.804 368.382 190.641 356.217 185.251L283.922 152.907C266.228 144.891 245.772 144.891 228.078 152.907L155.783 185.251C143.618 190.641 135.88 202.804 135.88 216.073C135.88 229.481 143.618 241.506 155.783 246.896ZM383.308 253.669C378.332 259.198 372.113 263.759 365.063 266.8L292.907 299.281C269.408 309.786 242.592 309.786 219.093 299.281L146.937 266.8C139.887 263.759 133.668 259.198 128.692 253.669L122.883 256.157C110.995 261.133 103.255 272.743 103.255 285.597C103.255 298.452 110.995 310.062 122.883 315.038L229.323 359.683C246.325 366.87 265.675 366.87 282.677 359.683L389.117 315.038C401.005 310.062 408.745 298.452 408.745 285.597C408.745 272.743 401.005 261.133 389.117 256.157L383.308 253.669Z' fill='#333333'/>
			</g>
			<defs>
				<clipPath id='clip0_6680_2705'>
					<rect width='512' height='512' fill='white'/>
				</clipPath>
			</defs>
		</svg>
	);
}
