import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import LogoIcon from '@/icons/LogoIcon';
import { AppContext } from '../context/AppContext';
import { Box, Button, Typography } from '@mui/joy';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';


const HeaderWrapper = styled.header<{ flat: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 1rem;
  justify-content: space-between;
	margin-bottom: 1rem;
	z-index: 10000;
	border: ${props => props.flat ? 'none' : '1px solid #E4e4e4'};
	background-color: ${props => props.flat ? 'transparent' : 'white'};
	border-radius: ${props => props.flat ? '0px' : '8px'};
	max-width: ${props => props.flat ? '100%' : 'calc(100vw - 2rem)'};
		background-color: rgba(255, 255, 255, 0.90);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
`;

const HeaderLandingWrapper = styled.header<{isCollapsed: boolean}>`
	position: fixed;
	display: flex;
	align-items: center;
	gap: 3rem;
	justify-content: space-between;
	max-width: calc(100% - 2rem);
	width: fit-content;
	padding: 8px 12px;
	border: 1px solid #E4e4e4;
	border-radius: 8px;
	top: 1rem;
	left: 1rem;
	right: 1rem;
	transition: width 0.15s ease, padding 0.15s ease;
	margin: 0 auto;
	background-color: rgba(240, 240, 240, 0.70);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	min-width: 500px;
	z-index: 10000;
	@media (max-width: 768px) {
		width: calc(100% - 2rem);
		min-width: calc(100% - 2rem);
		gap: 0.5rem;
	}
`;

const Header: React.FC<{ flat?: boolean }> = ({ flat = false }) => {
	const { user: { user, loaded } } = useContext(AppContext);
	const isLoggedIn = loaded && user?.email;
	const isMobile = useMediaQuery('(max-width: 768px)');

	return (
		<HeaderWrapper flat={flat}>
			<Box display='flex' alignItems='center' gap={1}>
				<a href='/' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<LogoIcon style={{ borderRadius: '6px' }} width='28px' height='28px' />
				</a>
				{!isMobile && (
					<Typography fontFamily='"Source Code Pro", monospace' fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
				)}
			</Box>
			{isLoggedIn ? (
				<Box display='flex' gap={.5}>
					<NavLink to='/test'>
						<Button size='sm' variant='plain' sx={{ padding: '0px 8px' }} color='neutral'>Test</Button>
					</NavLink>
					<NavLink to='/integrations'>
						<Button size='sm' variant='plain' sx={{ padding: '0px 8px' }} color='neutral'>Integrations</Button>
					</NavLink>
					<NavLink to='/settings'>
						<Button size='sm' variant='plain' sx={{ padding: '0px 8px' }} color='neutral'>Settings</Button>
					</NavLink>
					<NavLink to='/usage'>
						<Button size='sm' variant='plain' sx={{ padding: '0px 8px' }} color='neutral'>Usage</Button>
					</NavLink>
					<NavLink to='/'>
						<Button variant='shadowed' color='secondary' size='sm'>Dashboard</Button>
					</NavLink>
				</Box>
			) : (
				<NavLink to='/login'>
					<Button variant='shadowed' color='secondary' size='sm'>Sign in</Button>
				</NavLink>
			)}
		</HeaderWrapper>
	);
};

export const HeaderLanding = () => {
	const [isCollapsed, setIsCollapsed] = useState(false);
	const isMobile = useMediaQuery('(max-width: 768px)');


	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			setIsCollapsed(scrollPosition > 42);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isCollapsed]);

	return (
		<HeaderLandingWrapper isCollapsed={isCollapsed}>
			<Box display='flex' alignItems='center' gap={1}>
				<a href='/' style={{ display: 'flex',  gap: '8px', alignItems: 'center', justifyContent: 'center' }}>
					<LogoIcon style={{ borderRadius: '5px' }} width='28px' height='28px' />
					{!isMobile && (
						<Typography fontFamily='"Source Code Pro", monospace' fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
					)}
				</a>
			</Box>
			<Box display='flex' gap={.5} alignItems='center'>
				{/* <NavLink to='/tools'>
					<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Pre-built Tools</Button>
				</NavLink> */}
				<a href='https://docs.plato.so' target='_blank' rel='noreferrer'>
					<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Docs</Button>
				</a>
				{/* <NavLink to='/pricing'>
					<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Pricing</Button>
				</NavLink> */}
				{/* <a href='mailto:rob@plato.so'>
					<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Work with us</Button>
				</a> */}
				<NavLink to='/login'>
					<Button sx={{ marginLeft: '4px' }} variant='shadowed' size='sm' color='secondary'>Get started</Button>
				</NavLink>
			</Box>
		</HeaderLandingWrapper>
	);
};

export default Header;
