import React from 'react';

export default function Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' id='fi_3601998' {...props}>
			<path d='m2 5c-.552 0-1-.448-1-1v-1c0-1.654 1.346-3 3-3h1c.552 0 1 .448 1 1s-.448 1-1 1h-1c-.551 0-1 .449-1 1v1c0 .552-.448 1-1 1z'></path>
			<path d='m20 5c-.552 0-1-.448-1-1v-1c0-.551-.449-1-1-1h-1c-.552 0-1-.448-1-1s.448-1 1-1h1c1.654 0 3 1.346 3 3v1c0 .552-.448 1-1 1z'></path>
			<path d='m13 2h-4c-.552 0-1-.448-1-1s.448-1 1-1h4c.552 0 1 .448 1 1s-.448 1-1 1z'></path>
			<path d='m11 20h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z'></path>
			<path d='m5 20h-1c-1.654 0-3-1.346-3-3v-1c0-.552.448-1 1-1s1 .448 1 1v1c0 .551.449 1 1 1h1c.552 0 1 .448 1 1s-.448 1-1 1z'></path>
			<path d='m2 13c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4c0 .552-.448 1-1 1z'></path>
			<path d='m20 11c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2c0 .552-.448 1-1 1z'></path>
			<path d='m23.796 18.624-9.433-9.433c-.123-.123-.286-.191-.461-.191-.36 0-.652.292-.652.652v13.695c0 .36.292.652.652.652.176 0 .342-.069.469-.199l3.917-4.062h5.049c.36 0 .652-.292.652-.652 0-.172-.07-.34-.193-.462z'></path>
		</svg>
	);
}
