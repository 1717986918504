import React from 'react';

export default function Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			id="Layer_1"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 1"
			{...props}
		>
			<path d="m330.61 419.141a54.433 54.433 0 0 1 -36.647.482l34.542 81.377 32-66.792 70.275 23.378-44.888-105.752a24.673 24.673 0 0 0 -18.434 19.035l-1.829 8.292a54.167 54.167 0 0 1 -35.019 39.98zm-204.5-67.307-44.893 105.752 70.276-23.378 32.007 66.792 34.541-81.376a55.345 55.345 0 0 1 -71.664-40.463l-1.828-8.292a24.676 24.676 0 0 0 -18.438-19.035zm129.89-253.808a111.269 111.269 0 1 0 111.269 111.274 111.269 111.269 0 0 0 -111.269-111.274zm65.24 83.846-65.9 76.876a17.505 17.505 0 0 1 -26.82 1.007l-37.487-41.755a17.474 17.474 0 1 1 25.977-23.379l24.212 26.963 53.495-62.409a17.454 17.454 0 1 1 26.523 22.7zm134.822-3a39.174 39.174 0 0 0 -24.243-30.172l-7.848-3.239a39.751 39.751 0 0 1 -24.5-42.44l1.119-8.419a40.187 40.187 0 0 0 -51.97-43.602l-8.1 2.563a39.748 39.748 0 0 1 -46.048-16.763l-4.552-7.164a40.185 40.185 0 0 0 -67.839 0l-4.552 7.164a39.749 39.749 0 0 1 -46.052 16.761l-8.095-2.561a40.186 40.186 0 0 0 -51.967 43.606l1.119 8.417a39.749 39.749 0 0 1 -24.5 42.441l-7.849 3.24a40.185 40.185 0 0 0 -11.785 66.806l6.268 5.73a39.747 39.747 0 0 1 8.51 48.26l-3.929 7.525a40.187 40.187 0 0 0 33.919 58.752l8.483.359a39.752 39.752 0 0 1 37.541 31.5l1.828 8.292a40.185 40.185 0 0 0 63.747 23.2l6.73-5.178a39.748 39.748 0 0 1 49.006 0l6.73 5.178a40.187 40.187 0 0 0 63.748-23.2l1.829-8.292a39.746 39.746 0 0 1 37.541-31.5l8.48-.36a40.186 40.186 0 0 0 33.921-58.75l-3.929-7.527a39.748 39.748 0 0 1 8.509-48.261l6.267-5.73a39.168 39.168 0 0 0 12.463-36.637zm-200.062 156.692a126.269 126.269 0 1 1 126.269-126.264 126.273 126.273 0 0 1 -126.269 126.264z" fill-rule="evenodd" />
		</svg>
	);
}
