import Main from '@/components/Main';
import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/Header';
import { Box, Input, Skeleton, Typography } from '@mui/joy';
import API from '../api';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const Container = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  width: 100%;
  height: 100%;
`;

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ChatContainer = styled(Box)`
	flex-grow: 1;
	overflow-y: auto;
	width: 100%;
	padding-bottom: 4rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const StyledMain = styled(Main)`
	max-width: 900px;
`;

const SearchBarContainer = styled(Box)`
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ChatPage: React.FC = () => {
	const [message, setMessage] = useState('');
	const [chatHistory, setChatHistory] = useState<any[]>([]);
	const [loading, setLoading] = useState(false);
	const chatContainerRef = useRef<HTMLDivElement>(null);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const payload = {
			message,
			isAi: false
		};
		setLoading(true);
		setMessage('');
		setChatHistory([...chatHistory, payload]);
		const response = await API.createChatMessage(message);
		console.log(response.message);
		setChatHistory([...chatHistory, payload, { message: response.message, isAi: true }]);
		setLoading(false);
	};

	useEffect(() => {
		window.scrollTo(0, document.body.scrollHeight);
	}, [chatHistory]);

	return (
		<PageContainer>
			<StyledMain style={{ flexGrow: 1 }}>
				<Header />
				<Container onSubmit={handleSubmit}>
					<ChatContainer ref={chatContainerRef}>
						{chatHistory.map((message, index) => (
							<Box
								key={index}
								display='flex'
								flexDirection='column'
								alignItems={message.isAi ? 'flex-start' : 'flex-end'}
							>
								<Typography
									sx={{ background: message.isAi ? 'transparent' : '#eaeaea', borderRadius: '5px', padding: '0rem 1rem' }}
								>
									<ReactMarkdown remarkPlugins={[remarkGfm]}>{message.message}</ReactMarkdown>
								</Typography>
							</Box>
						))}
						{loading && <Skeleton width='35%' loading variant='text' />}
					</ChatContainer>
					<SearchBarContainer>
						<Input
							value={message}
							sx={{ maxWidth: '900px' }}
							fullWidth
							size='lg'
							onChange={(e) => setMessage(e.target.value)}
							disabled={loading}
						/>
					</SearchBarContainer>
				</Container>
			</StyledMain>
		</PageContainer>
	);
};

export default ChatPage;
