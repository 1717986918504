import React from 'react';

export default function CapaIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			version='1.1'
			id='Capa_1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			x='0px'
			y='0px'
			viewBox='0 0 408.576 408.576'
			style={{ enableBackground: 'new 0 0 408.576 408.576' }}
			xmlSpace='preserve'
			{...props}
		>
			<g>
				<g>
					<path d='M204.288,0C91.648,0,0,91.648,0,204.288s91.648,204.288,204.288,204.288s204.288-91.648,204.288-204.288S316.928,0,204.288,0z M318.464,150.528l-130.56,129.536c-7.68,7.68-19.968,8.192-28.16,0.512L90.624,217.6c-8.192-7.68-8.704-20.48-1.536-28.672c7.68-8.192,20.48-8.704,28.672-1.024l54.784,50.176L289.28,121.344c8.192-8.192,20.992-8.192,29.184,0C326.656,129.536,326.656,142.336,318.464,150.528z' />
				</g>
			</g>
		</svg>
	);
}
