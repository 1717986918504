import React from 'react';

export default function DashIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg version="1.1" id="fi_271207" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 492 492" {...props}>
			<g>
				<g>
					<path d="M465.064,207.562H26.908C12.076,207.562,0,219.698,0,234.53v22.804c0,14.832,12.072,27.104,26.908,27.104h438.156
			c14.84,0,26.936-12.272,26.936-27.104V234.53C492,219.698,479.904,207.562,465.064,207.562z"></path>
				</g>
			</g>
		</svg>
	);
}
