import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, IconButton } from '@mui/joy';
import { Paper } from '@mui/material';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';
import PlayIcon from '../icons/PlayIcon';
import BrowserIFrame from './Builder/BrowserIFrame';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const WebFlowStateToChipColor = {
	completed: 'success',
	pending: 'warning',
	running: 'primary',
	failed: 'danger',
};

const WebFlowsPage: React.FC = () => {
	const [webFlows, setWebFlows] = useState([]);
	const [selectedWebFlow, setSelectedWebFlow] = useState(null);
	const [view, setView] = useState('response');
	const [runningBrowserSessionId, setRunningBrowserSessionId] = useState(null);
	const iframeContainerRef = useRef<HTMLDivElement>(null);
	const [iframeZoom, setIframeZoom] = useState(0.5);

	useEffect(() => {
		if (iframeContainerRef.current) {
			const handleResize = () => {
				setIframeZoom((iframeContainerRef.current.clientWidth - 16) / 1600);
			};

			handleResize();

			window.addEventListener('resize', handleResize);

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, [iframeContainerRef]);

	useEffect(() => {
		API.getWebFlows().then((data) => {
			console.log(data);
			setWebFlows(data);
			setSelectedWebFlow(data[0]);
		});
	}, []);

	useEffect(() => {
		if (selectedWebFlow) {
			setView('response');
		}
	}, [selectedWebFlow]);

	const onRunWebFlow = async (wf) => {
		setSelectedWebFlow(wf);
		const res = await API.runWebFlow(wf.id, {}, {});
		const { browserSessionId } = res;
		setRunningBrowserSessionId(browserSessionId);
	};

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Web Flows</Typography>
			<Grid container spacing={2}>
				<Grid xs={12} md={7}>
					<Paper style={{ padding: 0 }} ref={iframeContainerRef}>
						<table>
							<thead>
								<tr>
									<th><Typography level='title-sm' color='neutral'>Name</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Last updated</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Run</Typography></th>
								</tr>
							</thead>
							<tbody>
								{webFlows.map((wf) => (
									<>
										<UsageRow active={selectedWebFlow?.id === wf.id} key={wf.id} onClick={() => setSelectedWebFlow(wf)}>
											<td>
												<Box display='flex' alignItems='center' gap={1}>
													<img width={16} height={16} src={wf?.icon_url || '/favicon.ico'} alt={wf?.name} />
													<Typography level='title-sm'>{wf?.name}</Typography>
												</Box>
											</td>
											<td>
												<Typography level='body-sm'>{moment(wf.updated_at).fromNow()}</Typography>
											</td>
											<td>
												<IconButton size='sm' variant='shadowed' color='third' onClick={() => onRunWebFlow(wf)}>
													<PlayIcon fill='currentColor' width={12} height={12} />
												</IconButton>
											</td>
										</UsageRow>
										{selectedWebFlow?.id === wf.id && runningBrowserSessionId && (
											<tr>
												<td
													colSpan={3}
													style={{
														padding: '8px',
														backgroundColor: '#fafafa',
														backgroundImage: 'linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px)',
														backgroundSize: '12px 12px',
													}}
												>
													<Box
														boxShadow='md'
														sx={{
															borderRadius: '5px',
															overflow: 'hidden',
															position: 'relative',
															border: '1px solid #e4e4e4',
															width: '100%',
															height: 0,
															paddingTop: 'calc(9/16 * 100%)',
														}}
													>
														<BrowserIFrame
															style={{
																position: 'absolute',
																width: '1600px',
																height: '900px',
																top: 0,
																left: 0,
																transform: `scale(${iframeZoom})`,
																transformOrigin: '0 0',
															}}
															browserSessionId={runningBrowserSessionId}
															hideNav
														/>
													</Box>
												</td>
											</tr>
										)}
									</>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={5}>
					<Paper style={{ padding: 0 }}>
						<Section>
							<Typography level='title-md'>{selectedWebFlow?.publicId}</Typography>
						</Section>
						<Divider/>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Name</Typography>
								<Box display='flex' alignItems='center' gap={0.5}>
									<img width={16} height={16} src={selectedWebFlow?.icon_url || '/favicon.ico'} alt={selectedWebFlow?.name} />
									<Typography level='title-sm'>{selectedWebFlow?.name}</Typography>
								</Box>
								<Typography whiteSpace='nowrap' color='neutral' level='title-sm'>Last updated</Typography>
								<Typography level='body-sm'>{moment(selectedWebFlow?.updated_at).fromNow()}</Typography>
							</Box>
						</Section>
						<Divider/>
						{/* <Section>
							<Box display='flex' gap={1} marginBottom={1}>
								<ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='response' size='sm' color={view === 'response' ? 'secondary' : 'neutral'} variant={view === 'response' ? 'solid' : 'outlined'}>Response</Button>
									<Button value='request' size='sm' color={view === 'request' ? 'secondary' : 'neutral'} variant={view === 'request' ? 'solid' : 'outlined'}>Request</Button>
								</ToggleButtonGroup>
							</Box>
							{view === 'response' ? (
								<>
									<Typography level='title-md'>Response body</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedWebFlow?.response, null, 2)}
									</SyntaxHighlighter>
								</>
							) : (
								<>
									<Typography level='title-md'>Request parameters</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedWebFlow?.parameters || {}, null, 2)}
									</SyntaxHighlighter>
								</>
							)}
						</Section> */}
					</Paper>
				</Grid>
			</Grid>
		</DashboardContainer>
	);
};

export default WebFlowsPage;
