import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { AppContext, SnackbarQueueItem } from './context/AppContext';
import Pricing from './pages/Pricing';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';
import { Snackbar } from '@mui/joy';
import TestIntegrationsPage from './pages/TestIntegrations';
import AppsPage from './pages/Apps';
import SettingsPage from './pages/Settings';
import LandingPage from './pages/LandingPage';
import UsagePage from './pages/Usage';
import AdminPage from './pages/Admin';
import DashboardPage from './pages/Dashboard';
import BuilderPage from './pages/Builder';
import SessionsPage from './pages/SessionsPage';
import WebFlowRunsPage from './pages/WebFlowRunsPage';
import WebFlowsPage from './pages/WebFlowsPage';
import BrowserDemoWrapper from './pages/Builder/BrowseDemo';
import WebEditorWrapper from './pages/WebEditor';
import TrainerPage from './pages/Builder/Trainer';
import ViewerPage from './pages/Builder/Viewer';
import AnnotatorLandingPage from './pages/AnnotatorLandingPage';
import ResearchPage from './pages/ResearchPage';


// const ViewAsContainer = styled.div`
// 	position: fixed;
// 	bottom: 1rem;
// 	left: 1rem;
// 	border: 1px dashed black;
// 	background: white;
// 	border-radius: 8px;
// 	padding: 1rem;
// 	background-color: white;
// 	z-index: 1000;
// 	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
// `;

// const BetaAccessContainer = styled.div`
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	display: flex;
// 	flex-direction: column;
// 	gap: 8px;
// 	justify-content: center;
// 	align-items: center;
// 	background-color: rgba(243, 244, 255, 0.25);
// 	backdrop-filter: blur(4px);
// `;


const App = () => {
	const { user, snackbarQueue, setSnackbarQueue } = useContext(AppContext);
	const [searchParams, _] = useSearchParams();
	const [snackbarItem, setSnackbarItem] = useState<SnackbarQueueItem | null>(null);

	const userWasLoaded = localStorage.getItem('user_loaded') === 'true' || searchParams.get('login') === 'true';

	const rollbarConfig: Rollbar.Configuration = {
		accessToken: 'eefc00eba2c64d4a9dee975af8a7bca9',
		captureUncaught: true,
		captureUnhandledRejections: true,
		environment: ENVIRONMENT,
		payload: {
			person: {
				id: user?.user?.id,
				username: user?.user?.email,
				email: user?.user?.email,
			},
		},
	};

	function handleMessage(message: MessageEvent) {
		const { type, data } = message.data;
		console.log('handleMessage', type, data);
		window.dispatchEvent(new CustomEvent(type, { detail: data }));
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	useEffect(() => {
		// This is not tested but whatever
		if (snackbarQueue.length > 0) {
			const nextItem = snackbarQueue[0];
			setSnackbarItem(nextItem);
			setSnackbarQueue(snackbarQueue.slice(1));
		}
	}, [snackbarQueue, snackbarItem]);

	return (
		<Provider config={rollbarConfig}>
			<Snackbar
				autoHideDuration={3000}
				onClose={() => setSnackbarItem(null)}
				open={Boolean(snackbarItem)}
			>
				{snackbarItem?.content}
			</Snackbar>

			<ErrorBoundary>
				{/* {user.user?.email && !user.user?.has_beta_access && (
					<Modal
						open
						sx={{
							'& .MuiModal-backdrop': {
								backdropFilter: 'blur(4px)',
							},
						}}
					>
						<ModalDialog
							sx={{
								p: 2,
								backgroundColor: 'rgba(250, 250, 250, 0.75)',
								backdropFilter: 'blur(6px)',
								boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
							}}
						>
							<LogoIcon width={42} height={42} />
							<Typography level='title-lg'>No Access</Typography>
							<Typography level='body-sm'>
								Plato is currently in closed Beta. If you would like early access, please email <a href='mailto:rob@plato.so'>rob@plato.so</a>
							</Typography>
						</ModalDialog>
					</Modal>
				)} */}
				<Routes>
					{(!user.loaded && userWasLoaded) || (user.loaded && user?.user?.email) ? (
						<>
							<Route path='/usage' element={<UsagePage />} />
							<Route path='/web-flow-runs' element={<WebFlowRunsPage />} />
							<Route path='/web-flows' element={<WebFlowsPage />} />
							<Route path='/sessions' element={<SessionsPage />} />
							<Route path='/test' element={<TestIntegrationsPage />} />
							<Route path='/apps' element={<AppsPage />} />
							<Route path='/settings' element={<SettingsPage />} />
							<Route path='/admin' element={<AdminPage />} />
							<Route path='/builder' element={<BuilderPage />} />
							<Route path='/viewer' element={<ViewerPage />} />
							<Route path='/trainer' element={<TrainerPage />} />
							<Route path='/' element={<DashboardPage />} />
							<Route path='/demo' element={<BrowserDemoWrapper />} />
							<Route path='/web-editor' element={<WebEditorWrapper />} />
						</>
					) : (
						<>
							<Route path='/' element={<AnnotatorLandingPage />} />
						</>
					)}

					<Route path='/landing' element={<AnnotatorLandingPage />} />
					<Route path='/old-landing' element={<LandingPage />} />
					<Route path='/login' element={<LoginPage />} />
					<Route path='/pricing' element={<Pricing />} />
					<Route path='/privacy' element={<PrivacyPolicyPage />} />
					<Route path='/research' element={<ResearchPage />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</ErrorBoundary>
		</Provider>
	);
};

export default App;
