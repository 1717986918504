import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { Box, Button, Divider, Grid, Input, Switch, Typography } from '@mui/joy';
import Header from '../../components/Header';
import Main from '@/components/Main';
import API from '@/api';

const AppsContainer = styled(Grid)``;

const AppsPage: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [apps, setApps] = useState([]);

	useEffect(() => {
		setLoading(true);
		// API.getApps().then((data) => {
		// 	setApps(data);
		// 	setLoading(false);
		// });
		setApps([
			{
				id: 1,
				name: 'Clockwise',
				description: 'Conversational calendar experience that makes scheduling a breeze',
				icon_url: 'https://cdn.prod.website-files.com/5f15081919fdf673994ab5fd/64a9e6d2c317ef1898993e89_Clockwise-Logo.svg',
				installed: true,
			},
		]);
		setLoading(false);
	}, []);

	return (
		<div>
			<Header />
			<Main style={{ minHeight: '90vh' }}>
				<Typography level='h2'>Apps</Typography>
				<Typography level='body-md'>Discover and manage your apps.</Typography>
				<Box display='flex' justifyContent='space-between' marginTop={2}>
					<Box display='flex' gap={1}>
						<Button size='sm' color='secondary' variant='solid'>All</Button>
						<Button size='sm' color='neutral' variant='outlined'>Installed</Button>
					</Box>
					<Input placeholder='Search' sx={{ minWidth: 300 }} />
				</Box>

				<AppsContainer marginTop={3} container spacing={2}>
					{apps.map((app) => (
						<Grid key={app.id} xs={12} sm={6} md={3} lg={3} xl={3}>
							<Paper sx={{ padding: 0 }}>
								<Box padding={2}>
									<img src={app.icon_url} alt={app.name} width={32} height={32} />
									<Typography level='title-lg'>{app.name}</Typography>
									<Typography level='body-sm'>{app.description}</Typography>
								</Box>
								<Divider />
								<Box paddingY={1} paddingX={2} display='flex' justifyContent='space-between'>
									<Button size='sm' variant='outlined'>
										{app.installed ? 'Manage' : 'Install'}
									</Button>
									<Switch checked={app.installed} color={app.installed ? 'primary' : 'neutral'} />
								</Box>
							</Paper>
						</Grid>
					))}
				</AppsContainer>
			</Main>
		</div>
	);
};

export default AppsPage;
