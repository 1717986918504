import React from 'react';
import styled from '@emotion/styled';

const IFrame = styled.iframe`
	width: 100%;
	height: 100%;
  border: none;
  outline: none;
  overflow: hidden;
`;

interface BrowserIFrameProps {
	browserSessionId: string;
	hideNav?: boolean;
	isBuilder?: boolean;
	style?: React.CSSProperties;
}

const BrowserIFrame = React.forwardRef<HTMLIFrameElement, BrowserIFrameProps>(({ browserSessionId, hideNav, isBuilder, style }, ref) => {
	const browserUrl = `${window.location.protocol}//browser.${window.location.host}`;

	const queryParams = new URLSearchParams();
	queryParams.set('browserSessionId', browserSessionId);
	if (hideNav) {
		queryParams.set('hideNav', 'true');
	}
	if (isBuilder) {
		queryParams.set('isBuilder', 'true');
	}

	return (
		<IFrame
			ref={ref}
			src={`${browserUrl}/plato?${queryParams.toString()}`}
			style={style}
		/>
	);
});

BrowserIFrame.displayName = 'BrowserIFrame';

export default BrowserIFrame;
