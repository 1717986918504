import React from 'react';

export default function CursorPointerIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M178 300.5L193 57H230.5L240.5 168H339L433 203.5L441 326.5L396 442L291.5 466.5L215.5 434.5L81.5 281L116 260.5L178 300.5Z" fill="white" stroke="black"/>
			<path d="M208 32C181.688 32 160 53.6875 160 80V269L149.5 258L145.5 254.499C127.063 236.062 96.9374 236.062 78.5 254.499C60.0625 272.938 60.0625 303.062 78.5 321.501V322L209.501 451.501L210.499 452L211 453C232.563 469.187 260.062 480 291 480H318.499C391.062 480 449.501 421.563 449.501 349V224C449.501 197.688 427.813 176 401.501 176C394.688 176 388.374 177.875 382.499 180.499C377.25 159.688 358.312 144 336 144C323.75 144 312.499 148.812 304 156.5C295.501 148.812 284.25 144 272 144C266.374 144 261.062 145.188 256 147V80C256 53.6875 234.312 32 208 32ZM208 64C216.875 64 224 71.125 224 80V256H256V192C256 183.125 263.125 176 272 176C280.875 176 288 183.125 288 192V256H320V192C320 183.125 327.125 176 336 176C344.875 176 352 183.125 352 192V256H385.501V224C385.501 215.125 392.626 208 401.501 208C410.374 208 417.501 215.125 417.501 224V349C417.501 404.437 373.938 448 318.499 448H291C267.563 448 247.312 439.626 230.499 427L101 299C93.875 291.875 93.875 284.125 101 277C108.125 269.875 115.875 269.875 123 277L192 346V80C192 71.125 199.125 64 208 64Z" fill="black"/>
		</svg>

	);
}
