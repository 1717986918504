import React from 'react';

export default function ObjectIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg height='512' viewBox='0 0 32 32' width='512' xmlns='http://www.w3.org/2000/svg' id='Object' {...props}>
			<path d='m30.71 7.29-6-6a1 1 0 0 0 -.71-.29h-2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2-2v-8h-6a3 3 0 0 0 -3 3v24a3 3 0 0 0 3 3h2v-9a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v9h2a3 3 0 0 0 3-3v-20a1 1 0 0 0 -.29-.71z'/>
			<path d='m12 1h8v8h-8z'/>
			<path d='m23 21h-14a1 1 0 0 0 -1 1v9h16v-9a1 1 0 0 0 -1-1z'/>
		</svg>
	);
}
