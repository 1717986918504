import React, { useContext } from 'react';
import { Typography, Box, Input, Button } from '@mui/joy';
import { AppContext } from '../context/AppContext';
import DashboardContainer from '../components/DashboardContainer';

const SettingsPage: React.FC = () => {
	const { user: { loaded, user } } = useContext(AppContext);

	const plan = user?.planMembers?.[0]?.plan;

	const copyToClipboard = () => {
		navigator.clipboard.writeText(plan?.apiKey);
	};

	return (
		<DashboardContainer>
			<Typography level='h2'>Settings</Typography>
			<Typography level='body-md'>Manage your account settings and preferences.</Typography>

			<Box marginTop={3}>
				<Box display='flex' alignItems='center' gap={2}>
					<Box width='25%'>
						<Typography level='title-md'>API Key</Typography>
						<Typography level='body-sm'>Use your API key to access our API.</Typography>
					</Box>
					<Box flexGrow={1} display='flex'>
						{loaded && (
							<Input
								sx={{ width: '420px' }}
								onChange={() => {}}
								value={plan?.apiKey}
								endDecorator={<Button onClick={copyToClipboard} size='sm' variant='shadowed' color='secondary'>Copy</Button>}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</DashboardContainer>
	);
};

export default SettingsPage;
