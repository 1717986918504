import React, { useEffect } from 'react';
import { Box, Divider, Typography, Button, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, Chip, Breadcrumbs, Link, Option, Select, Input, TabPanel, FormLabel, tabClasses, TabList, Tab, Tabs, IconButton } from '@mui/joy';
import { BuilderContext, Pages, SELECT_MODE } from '../BuilderContext';
import { useContext, useState } from 'react';
import ChevronRightIcon from '@/icons/ChevronRightIcon';
import MacCommandIcon from '@/icons/MacCommandIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import styled from '@emotion/styled';
import Events from '../Events';
import WebAction from '../components/WebAction';
import API from '@/api';

const States = {
	INTEGRATION: 0,
	ADD_ENDPOINT: 1,
	ADD_SCRAPER: 2,
	EDIT_ENDPOINT: 3,
	ADD_SECURITY_SCHEME: 4,
};

const BorderedImage = styled.img`
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	padding: 2px;
	box-sizing: border-box;
`;

const ViewIntegration = () => {
	const { selectedIntegrationSource, setPage } = useContext(BuilderContext);
	const [state, setState] = useState(States.INTEGRATION);
	const [selectedMethod, setSelectedMethod] = useState(null);

	let content = (
		<>
			<Divider sx={{ marginBottom: '8px' }} />
			<Box marginBottom={2}>
				<Box marginBottom={1} display='flex' justifyContent='space-between' alignItems='center'>
					<Box>
						<Typography level='title-lg'>API Authentication</Typography>
						<Typography level='body-xs'>Configure authentication for this integration</Typography>
					</Box>
					<Button
						size='sm'
						color='secondary'
						variant='shadowed'
						onClick={(e) => {
							e.stopPropagation();
							setState(States.ADD_SECURITY_SCHEME);
						}}
					>
              Configure
					</Button>
				</Box>
				<Typography level='body-xs'>{JSON.stringify(selectedIntegrationSource?.api_components?.securitySchemes?.[0], null, 2)}</Typography>
			</Box>
			<Box marginBottom={2}>
				<Box marginBottom={1} display='flex' justifyContent='space-between' alignItems='center'>
					<Box>
						<Typography level='title-lg'>API Endpoints</Typography>
						<Typography level='body-xs'>Configure endpoints for this integration</Typography>
					</Box>
					<Button
						size='sm'
						color='secondary'
						variant='shadowed'
						onClick={(e) => {
							e.stopPropagation();
							setState(States.ADD_ENDPOINT);
						}}
					>
              Add
					</Button>
				</Box>
				<List>
					{selectedIntegrationSource.methods?.filter(m => m.type === 'endpoint').map((sourceEndpoint) => (
						<ListItem key={sourceEndpoint.id}>
							<ListItemButton onClick={() => {
								setSelectedMethod(sourceEndpoint);
								setState(States.EDIT_ENDPOINT);
							}}>
								<ListItemContent>
									<Box display='flex' gap={1} alignItems='center'>
										<Chip size='sm' color='neutral'>{sourceEndpoint.method}</Chip>
										<Typography level='title-md'>{sourceEndpoint.name}</Typography>
									</Box>
									<Typography level='body-xs'>{sourceEndpoint.description}</Typography>
								</ListItemContent>
								<ChevronRightIcon style={{ flexShrink: 0 }} width={12} height={12} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Box>
			<Box marginBottom={2}>
				<Box marginBottom={1} display='flex' justifyContent='space-between' alignItems='center'>
					<Box>
						<Typography level='title-lg'>Web Scrapers</Typography>
						<Typography level='body-xs'>Configure web scrapers for this integration</Typography>
					</Box>
					<Button
						size='sm'
						color='secondary'
						variant='shadowed'
						onClick={(e) => {
							e.stopPropagation();
							setState(States.ADD_SCRAPER);
						}}
					>
              Add
					</Button>
				</Box>
				<List>
					{selectedIntegrationSource.methods?.filter(m => m.type === 'scraper').map((sourceScraper) => (
						<ListItem key={sourceScraper.id}>
							<ListItemButton onClick={() => {
								setSelectedMethod(sourceScraper);
								setState(States.ADD_SCRAPER);
							}}>
								<ListItemContent>
									<Box display='flex' gap={0.5} alignItems='center'>
										<img src={selectedIntegrationSource.icon_url} alt={selectedIntegrationSource.name} width={14} height={14} />
										<Typography level='title-md'>{sourceScraper.name}</Typography>
									</Box>
									<Typography level='body-xs'>{sourceScraper.description}</Typography>
								</ListItemContent>
								<ChevronRightIcon style={{ flexShrink: 0 }} width={12} height={12} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Box>
		</>
	);

	// if (state === States.ADD_ENDPOINT) {
	// 	content = <AddEndpointFlow method={selectedMethod} />;
	// }

	if (state === States.ADD_SCRAPER) {
		content = <AddScraperFlow method={selectedMethod} onClose={() => setState(States.INTEGRATION)} />;
	}

	// if (state === States.EDIT_ENDPOINT) {
	// 	content = (
	// 		<Box>
	// 			<Endpoint endpoint={selectedMethod} integrationId={selectedIntegration.id} />
	// 		</Box>
	// 	);
	// }

	// if (state === States.ADD_SECURITY_SCHEME) {
	// 	content = <AddSecuritySchemeFlow />;
	// }


	return (
		<Box height='100%' display='flex' flexDirection='column' p={2}>
			<Breadcrumbs sx={{ padding: 0 }}>
				<Link onClick={() => setPage(Pages.HOME)}>
					<Typography level='body-sm'>Integrations</Typography>
				</Link>
				<Link onClick={() => { setState(States.INTEGRATION); setSelectedMethod(null); }}>
					<img style={{ marginRight: '4px' }} src={selectedIntegrationSource.icon_url} alt={selectedIntegrationSource.name} width={12} height={12} />
					<Typography fontWeight={!selectedMethod ? 'bold' : 'normal'} level='body-sm'>{selectedIntegrationSource.name}</Typography>
				</Link>
				{selectedMethod && (
					<Link>
						<Typography fontWeight='bold' level='body-sm'>{selectedMethod.name}</Typography>
					</Link>
				)}
			</Breadcrumbs>
			<Box display='flex' justifyContent='space-between' alignItems='flex-end' marginBottom={1}>
				<Box display='flex' alignItems='center' gap={0.5}>
					<BorderedImage src={selectedIntegrationSource.icon_url} alt={selectedIntegrationSource.name} width={20} height={20} />
					<Typography level='h4'>{selectedIntegrationSource.name}</Typography>
				</Box>
			</Box>
			<Box flexGrow={1}>
				{content}
			</Box>
		</Box>
	);
};

interface AddScraperFlowProps {
	method: any;
	onClose: () => void;
}

const ADD_SCRAPER_TABS = {
	SETUP: 'setup',
	ACTIONS: 'actions',
	TEST: 'test',
};

export const AddScraperFlow = ({ method, ...props }: AddScraperFlowProps) => {
	const { activeAction, setActiveAction, frameUrl, selectMode } = useContext(BuilderContext);
	const [actions, setActions] = useState(method?.actions || []);
	const [name, setName] = useState(method?.name || 'New Web Flow');
	const [description, setDescription] = useState(method?.description || '');
	const [endpointMethod, setEndpointMethod] = useState(method?.method || 'GET');
	const [inputParameters, setInputParameters] = useState(method?.parameters || []);
	const [currentTab, setCurrentTab] = useState(ADD_SCRAPER_TABS.ACTIONS);
	const [testData, setTestData] = useState({});
	const [testLoading, setTestLoading] = useState(false);
	const [url, setUrl] = useState(frameUrl);

	const sendTabAction = async (action, data = {}) => {
		return;
	};

	useEffect(() => {
		if (!actions.length) {
			setUrl(frameUrl);
		}
	}, [frameUrl, actions]);

	useEffect(() => {
		if (activeAction) {
			setActiveAction(actions.find(a => a.id === activeAction.id));
		}
	}, [actions]);


	useEffect(() => {
		function handleActionCreated(event) {
			console.log(event.detail);
			const action = event.detail;
			setCurrentTab(ADD_SCRAPER_TABS.ACTIONS);
			if (activeAction) {
				window.dispatchEvent(new CustomEvent(Events.SUB_ACTION_ADDED, { detail: action }));
			} else {
				const lastAction = actions?.length ? actions[actions.length - 1] : null;
				if (action.type === 'type' && lastAction?.type === 'type') {
					onActionUpdate(lastAction.id, { attribute: lastAction.attribute + action.attribute });
				} else {
					setActions([...actions, action]);
					if (action?.type === 'object' || !activeAction) {
						setActiveAction(action);
					}
				}
			}
		}

		window.addEventListener(Events.ON_CREATE_ACTION, handleActionCreated);

		return () => {
			window.removeEventListener(Events.ON_CREATE_ACTION, handleActionCreated);
		};
	}, [actions, activeAction]);

	const testScraper = async () => {
		setTestLoading(true);
		// const cookies = await sendRuntimeAction('GET_COOKIES');
		// await API.postIntegration(selectedIntegration.id, '2', { cookies });
		// const response = await API.callIntegrationMethod(method.id, '2', testData);
		// console.log(response);
		setTestLoading(false);
	};

	// useEffect(() => {
	// 	sendTabAction('GET_PAGE_DETAILS').then((pageDetails) => {
	// 		console.log('pageDetails', pageDetails);
	// 		if (!method) {
	// 			setUrl(pageDetails.href);
	// 		}
	// 	});
	// 	sendTabAction('START_RECORDING');
	// 	console.log('method', method);

	// 	return () => {
	// 		onClose();
	// 	};
	// }, []);

	useEffect(() => {
		sendTabAction('SET_ACTIVE_ACTION', { action: activeAction });
	}, [activeAction]);

	const onClose = async () => {
		await sendTabAction('STOP_RECORDING');
		props.onClose();
	};


	const onSave = async () => {
		setTestLoading(true);
		console.log('saving', name, description, actions, inputParameters);
		try {
			const updatedMethod = await API.createIntegrationMethod({
				id: method?.id,
				name,
				description,
				actions,
				parameters: inputParameters,
			});
		} catch (error) {
			console.warn(error);
		}
		setTestLoading(false);
		onClose();
	};

	const onRemoveAction = (id) => {
		setActions(actions.filter((a) => a.id !== id));
		if (activeAction?.id === id) {
			setActiveAction(null);
		}
	};

	const onActionUpdate = (id, update) => {
		setActions(actions.map((a) => a.id === id ? { ...a, ...update } : a));
	};

	return (
		<Box p={2} height='100%' display='flex' flexDirection='column' gap={1} onClick={() => {setActiveAction(null); }}>
			<Tabs
				value={currentTab}
				onChange={(_, newValue) => setCurrentTab(String(newValue))}
				sx={{ height: '100%', background: 'none' }}
			>
				{/* <TabList
					sx={{
						p: 0.5,
						gap: 0.5,
						borderRadius: '6px',
						bgcolor: '#E4E4E4',
						[`& .${tabClasses.root}[aria-selected="true"]`]: {
							boxShadow: 'sm',
							bgcolor: '#f9f9f9',
						},
						[`& .${tabClasses.root}`]: {

						},
						mb: 1,
					}}
					size='sm'
					disableUnderline
				>
					<Tab disableIndicator sx={{ fontWeight: '500', borderRadius: '6px' }} value={ADD_SCRAPER_TABS.SETUP}>Setup</Tab>
					<Tab disableIndicator sx={{ fontWeight: '500', borderRadius: '6px' }} value={ADD_SCRAPER_TABS.ACTIONS}>Actions</Tab>
					<Tab disableIndicator sx={{ fontWeight: '500', borderRadius: '6px' }} value={ADD_SCRAPER_TABS.TEST}>Test</Tab>
				</TabList> */}

				<TabPanel sx={{ p: 0 }} value={ADD_SCRAPER_TABS.SETUP}>
					<Box height='100%' display='flex' flexDirection='column' gap={1}>
						<Typography level='title-lg'>Add Flow</Typography>
						<Input
							size='sm'
							placeholder='Name'
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
						<Input
							size='sm'
							placeholder='Description'
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
						{/* <Input
							size='sm'
							placeholder='URL'
							value={url}
							onChange={(e) => setUrl(e.target.value)}
						/> */}
						<FormLabel>Method</FormLabel>
						<Select
							value={endpointMethod}
							size='sm'
							onChange={(e, newValue) => setEndpointMethod(newValue)}
						>
							<Option value='GET'>GET</Option>
							<Option value='POST'>POST</Option>
							<Option value='PUT'>PUT</Option>
							<Option value='DELETE'>DELETE</Option>
						</Select>
						<FormLabel>Input parameters</FormLabel>
						{/* {inputParameters.map((param, index) => (
							<ParameterInput
								key={index}
								parameter={param}
								onChange={(update) => {
									const newParams = [...inputParameters];
									newParams[index] = { ...newParams[index], ...update };
									setInputParameters(newParams);
								}}
								onRemove={() => {
									const newParams = [...inputParameters];
									newParams.splice(index, 1);
									setInputParameters(newParams);
								}}
							/>
						))} */}
						<Button size='sm' onClick={() => setInputParameters([...inputParameters, { name: '', type: 'string' }])} variant='soft' color='neutral' fullWidth>Add</Button>
						<Box display='flex' justifyContent='flex-end' gap={1}>
							<Button loading={testLoading} size='sm' color='secondary' variant='shadowed' onClick={onSave}>Save</Button>
							<Button disabled={testLoading} color='third' variant='shadowed' size='sm' onClick={onClose}>Cancel</Button>
						</Box>
					</Box>
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={ADD_SCRAPER_TABS.ACTIONS}>
					<Box component='form' height='100%' display='flex' flexDirection='column' gap={1} onSubmit={onSave}>
						<Box display='grid' gridTemplateColumns='1fr 1fr' marginBottom={1}>
							<Box display='flex' alignItems='center' gap={1}>
								<IconButton
									color='third'
									variant='shadowed'
									size='sm'
									sx={{
										'--IconButton-size': '1.75rem',
									}}
									onClick={onClose}
									disabled={Boolean(selectMode)}
								>
									<MacCommandIcon fill='currentColor' width={12} height={12} />
								</IconButton>
								<Typography level='title-sm'>Select One</Typography>
							</Box>
							<Box display='flex' alignItems='center' gap={.5}>
								<IconButton
									color='third'
									variant='shadowed'
									size='sm'
									sx={{
										'--IconButton-size': '1.75rem',
									}}
									onClick={onClose}
									disabled={Boolean(selectMode)}
								>
									<MacCommandIcon fill='currentColor' width={12} height={12} />
								</IconButton>
								<Typography level='title-md'>+</Typography>
								<IconButton
									color='third'
									variant='shadowed'
									size='sm'
									sx={{
										'--IconButton-size': '1.75rem',
									}}
									onClick={onClose}
									disabled={selectMode === SELECT_MODE.ALL}
								>
									<ArrowRightIcon style={{ transform: 'rotate(-90deg)' }} fill='currentColor' width={11} height={11} />
								</IconButton>
								<Typography level='title-sm'>Select All</Typography>
							</Box>
						</Box>
						<Box>
							<Input
								variant='plain'
								size='sm'
								placeholder='New Web Flow'
								value={name}
								onChange={(e) => setName(e.target.value)}
								sx={{
									'--Input-radius': '0px',
									background: 'transparent',
									borderBottom: '2px solid',
									borderColor: 'neutral.outlinedBorder',
									padding: 0,
									'&:hover': {
										borderColor: 'neutral.outlinedHoverBorder',
									},
									'&::before': {
										border: '1px solid var(--Input-focusedHighlight)',
										transform: 'scaleX(0)',
										left: 0,
										right: 0,
										bottom: '-2px',
										top: 'unset',
										transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
										borderRadius: 0,
									},
									'&:focus-within::before': {
										transform: 'scaleX(1)',
									},
								}}
							/>
						</Box>
						<Box border='1px solid #E4E4E4' borderRadius={5} overflow='hidden'>
							<Input
								variant='plain'
								sx={{
									flexGrow: 1,
									paddingLeft: 0,
									border: 0,
									borderRadius: 0,
									'&:before': {
										boxShadow: 'none',
									},
								}}
								slotProps={{
									startDecorator: {
										sx: {
											margin: 0,
											padding: '2px 8px',
										},
									},
								}}
								startDecorator={<Chip size='sm' variant='soft' color='primary'>Start</Chip>}
								disabled
								size='sm'
								value={url}
								onChange={(e) => setUrl(e.target.value)}
							/>
						</Box>
						<Box display='flex' flexDirection='column' gap={1} marginBottom={1}>
							{actions.map((action, index) => {
								return (
									<Box key={action.id} border='1px solid #E4E4E4' borderRadius={5} overflow='hidden'>
										<WebAction
											onSelected={(action) => setActiveAction(action)}
											onRemove={() => onRemoveAction(action.id)}
											activeId={activeAction?.id}
											action={action}
											onChange={(update) => onActionUpdate(action.id, update)}
										/>
									</Box>
								);
							})}
						</Box>
						<Box display='flex' justifyContent='flex-end' gap={1}>
							<Button type='submit' loading={testLoading} size='sm' color='third' variant='shadowed'>Test</Button>
							<Button type='submit' loading={testLoading} size='sm' color='secondary' variant='shadowed'>Save</Button>
							{/* <Button disabled={testLoading} color='third' variant='shadowed' size='sm' onClick={onClose}>Cancel</Button> */}
						</Box>
					</Box>
				</TabPanel>

				<TabPanel sx={{ p: 0 }} value={ADD_SCRAPER_TABS.TEST}>
					<Box height='100%' display='flex' flexDirection='column' gap={1}>
						<Typography level='title-lg'>Test</Typography>
						{/* {inputParameters.map((param, index) => (
							<ParameterInputValue
								key={index}
								parameter={param}
								value={testData[param.name]}
								onChange={(update) => {
									setTestData({ ...testData, [param.name]: update.value });
								}}
							/>
						))} */}
						<Box display='flex' justifyContent='flex-end' gap={1}>
							<Button disabled={!method?.id} loading={testLoading} size='sm' color='secondary' variant='shadowed' onClick={testScraper}>Test</Button>
						</Box>
					</Box>
				</TabPanel>
			</Tabs>
		</Box>
	);
};

// interface ExtractionProps {
// 	action: any;
// 	onChange: (action: any) => void;
// 	onRemove: () => void;
// 	activeId: string;
// 	onSelected: (action: any) => void;
// }

// export const Extraction = ({ action, onChange, onRemove, activeId, onSelected }: ExtractionProps) => {
// 	const { filters, subActions } = action;
// 	const isActive = activeId === action.id;

// 	useEffect(() => {
// 		function handleActiveActionEvent(event) {
// 			const newSubActions = subActions ? [...subActions] : [];
// 			newSubActions.push(event.detail.data);
// 			onChange({ subActions: newSubActions });
// 		}

// 		if (isActive) {
// 			window.addEventListener('active-action-event', handleActiveActionEvent);
// 		} else {
// 			window.removeEventListener('active-action-event', handleActiveActionEvent);
// 		}
// 		return () => {
// 			window.removeEventListener('active-action-event', handleActiveActionEvent);
// 		};
// 	}, [isActive, filters, subActions, action]);

// 	const onAddFilter = () => {
// 		const newFilter = {
// 			element: action.element,
// 			attribute: null,
// 			operator: 'equals',
// 			value: '',
// 		};
// 		onChange({ filters: [...(filters || []), newFilter] });
// 	};

// 	const onSelectElement = () => {
// 		window.dispatchEvent(new CustomEvent('select-element-event', { detail: { data: action } }));
// 	};

// 	return (
// 		<Box component='form' display='flex' flexDirection='column' >
// 			<Box
// 				display='flex'
// 				flexDirection='column'
// 				onClick={(e) => {
// 					e.stopPropagation();
// 					onSelected(action);
// 				}}>
// 				<ParameterInput
// 					onRemove={onRemove}
// 					isActive={isActive}
// 					parameter={{ name: action.name || '', type: action.type || '', isArray: action.isArray || false, attribute: action.attribute || '' }}
// 					selectedValue={`${action.element.tagName.toLowerCase()}${action.type !== 'object' ? `.${action.attribute}` : ''}`}
// 					onAddFilter={onAddFilter}
// 					onSelectElement={onSelectElement}
// 					onChange={onChange}
// 				/>
// 				{filters?.map((filter, index) => (
// 					<>
// 						<Divider />
// 						<Box key={index} display='grid' gap={1} gridTemplateColumns='1fr'>
// 							<Input
// 								value={filter.value}
// 								size='sm'
// 								fullWidth
// 								sx={{
// 									paddingLeft: 0,
// 									flexGrow: 1,
// 									borderRadius: 0,
// 									'&:before': {
// 										boxShadow: 'none',
// 									},
// 								}}
// 								type={filter.attribute === 'index' ? 'number' : 'text'}
// 								variant='plain'
// 								placeholder='value'
// 								onChange={(e) => {
// 									const newFilters = [...filters];
// 									newFilters[index].value = e.target.value;
// 									onChange({ filters: newFilters });
// 								}}
// 								startDecorator={
// 									<>
// 										<Button size='sm' variant='plain' color='neutral' sx={{ whiteSpace: 'nowrap', borderTopRightRadius: 0, borderBottomRightRadius: 0, margin: 0, height: '100%', fontSize: '12px', paddingX: '6px' }}>
// 											{filter.element ? `${filter.element.tagName.toLowerCase()}.${filter.attribute}` : '-'}
// 										</Button>
// 										<Divider orientation='vertical' />
// 										<Select
// 											size='sm'
// 											variant='plain'
// 											color='neutral'
// 											sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRadius: 0, overflow: 'hidden' }}
// 											defaultValue='equals'
// 											indicator={null}
// 											renderValue={(value) => {
// 												return <Chip size='sm' variant='outlined' color='neutral'>{value.label}</Chip>;
// 											}}
// 											value={filter.operator}
// 											onChange={(e, newValue) => {
// 												const newFilters = [...filters];
// 												newFilters[index].operator = newValue;
// 												onChange({ filters: newFilters });
// 											}}
// 										>
// 											<Option value='exists'><Chip size='sm' variant='outlined' color='neutral'>Exists</Chip></Option>
// 											<Option value='equals'><Chip size='sm' variant='outlined' color='neutral'>Equals</Chip></Option>
// 											<Option value='contains'><Chip size='sm' variant='outlined' color='neutral'>Contains</Chip></Option>
// 											<Option value='regex'><Chip size='sm' variant='outlined' color='neutral'>Regex</Chip></Option>
// 										</Select>
// 										<Divider orientation='vertical' />
// 									</>
// 								}
// 							/>
// 						</Box>
// 					</>
// 				))}
// 			</Box>
// 			{action.type === 'object' && subActions?.length > 0 && (
// 				<>
// 					<Divider />
// 					<Box display='flex' flexDirection='column' paddingLeft={2} sx={{ backgroundColor: '#E4E4E4' }}>
// 						{subActions?.map((subAction, index) => (
// 							<>
// 								<Extraction
// 									key={index}
// 									action={subAction}
// 									activeId={activeId}
// 									onSelected={(_action) => onSelected(_action)}
// 									onChange={(update) => {
// 										const newSubActions = [...subActions];
// 										newSubActions[index] = { ...newSubActions[index], ...update };
// 										onChange({ subActions: newSubActions });
// 									}}
// 									onRemove={() => {
// 										const newSubActions = [...subActions];
// 										newSubActions.splice(index, 1);
// 										onChange({ subActions: newSubActions });
// 									}}
// 								/>
// 								{index < subActions.length - 1 && <Divider />}
// 							</>
// 						))}
// 					</Box>
// 				</>
// 			)}
// 		</Box>
// 	);
// };


export default ViewIntegration;
