import React from 'react';

export default function VisionIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg version='1.1' id='fi_561155' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' xmlSpace='preserve' {...props}>
			<g>
				<g>
					<path d='M509.188,247.27c-4.57-6.39-113.521-156.272-252.182-156.272C118.334,90.998,7.383,240.88,2.813,247.27
			c-3.75,5.22-3.75,12.25,0,17.46c4.57,6.39,115.521,156.271,254.193,156.271c138.661,0,247.612-149.881,252.182-156.272
			C512.938,259.52,512.938,252.489,509.188,247.27z M257.006,361c-57.891,0-107.001-47.11-107.001-105.001
			s49.11-105.001,107.001-105.001s105.001,47.11,105.001,105.001S314.897,361,257.006,361z'></path>
				</g>
			</g>
			<g>
				<g>
					<path d='M257.006,210.999c-24.82,0-47,20.19-47,45c0,24.82,22.18,45,47,45c24.81,0,45-20.18,45-45
			C302.006,231.189,281.816,210.999,257.006,210.999z'></path>
				</g>
			</g>
		</svg>
	);
}
