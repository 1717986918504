import Main from '@/components/Main';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import { Box, Button, Divider, Grid, Input, Switch, ToggleButtonGroup, Typography } from '@mui/joy';
import API from '@/api';
import DashboardContainer from '../components/DashboardContainer';


const IntegrationsContainer = styled(Grid)`
`;
const IntegrationsPage: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [integrationSources, setIntegrationSources] = useState([]);
	const [integrations, setIntegrations] = useState([]);

	useEffect(() => {
		setLoading(true);
		Promise.all([
			API.getIntegrationSources().then((data) => {
				data.sort((a, b) => b.methods?.length - a.methods?.length);
				console.log(data);
				setIntegrationSources(data);
			}),
		]).then(() => {
			setLoading(false);
		});

	}, []);

	return (
		<DashboardContainer>
			<Typography mb={2} level='h2'>Integrations</Typography>
			<Typography level='body-md'>Connect your favorite services to get started.</Typography>
			<Box display='flex' justifyContent='space-between' marginTop={2} marginBottom={2}>
				<ToggleButtonGroup>
					<Button size='sm' color='secondary' variant='solid'>All</Button>
					<Button size='sm' color='neutral' variant='outlined'>Connected</Button>
				</ToggleButtonGroup>
				<Input placeholder='Search' sx={{ minWidth: 300 }} />
			</Box>

			<Paper style={{ padding: 0 }}>
				<table>
					<thead>
						<tr>
							<th><Typography level='title-sm' color='neutral'>Integration</Typography></th>
							<th style={{ textAlign: 'right' }}><Typography level='title-sm' color='neutral'>Endpoints</Typography></th>
							<th style={{ textAlign: 'right' }}><Typography level='title-sm' color='neutral'></Typography></th>
						</tr>
					</thead>
					<tbody>
						{integrationSources.map((source) => {
							return (
								<tr key={source.id}>
									<td>
										<Box display='flex' gap={1} alignItems='center'>
											<img src={source.icon_url} alt={source.name} width={18} height={18} />
											<Typography fontWeight='semibold' level='title-sm'>{source.name}</Typography>
										</Box>
									</td>
									<td style={{ textAlign: 'right' }}><Typography level='body-sm'>{source.methods?.length}</Typography></td>
									<td style={{ textAlign: 'right' }}><Button size='sm' variant='shadowed' color='neutral'>Manage</Button></td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</Paper>

			<IntegrationsContainer marginTop={3} container spacing={2}>
				{/* {integrationSources.map((source) => {
						const connectedIntegrations = integrations.filter((integration) => integration.integration_source_id === source.id);
						return (
							<Grid key={source.id} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Paper sx={{ padding: 0 }}>
									<Box padding={2}>
										<img src={source.icon_url} alt={source.name} width={32} height={32} />
										<Typography level='title-lg'>{source.name}</Typography>
										<Typography level='body-sm'>{source.description}</Typography>
									</Box>
									<Divider />
									<Box paddingY={1} paddingX={2} display='flex' justifyContent='space-between'>
										<Button size='sm' variant='soft'>Manage</Button>
										<Switch checked={connectedIntegrations.length > 0} color={connectedIntegrations.length > 0 ? 'primary' : 'neutral'} />
									</Box>
								</Paper>
							</Grid>
						);
					})} */}
			</IntegrationsContainer>
		</DashboardContainer>
	);
};

export default IntegrationsPage;
