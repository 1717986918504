import Main from '@/components/Main';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import React, { useEffect } from 'react';
import SlackIcon from '@/icons/SlackIcon';
import GoogleIcon from '@/icons/GoogleIcon';
import { HeaderLanding } from '@/components/Header';
import Footer from '@/components/Footer';
import { colors } from '../styles/theme';
import { Button, Typography } from '@mui/joy';
import { Helmet } from 'react-helmet';

const Card = styled(Paper)`
	padding: 2rem;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
`;

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	margin-bottom: 1rem;
`;

const LoginPage: React.FC = () => {

	useEffect(() => {
		// check if query param has error or loginError
		const url = new URL(window.location.href);
		const error = url.searchParams.get('error');
		const loginError = url.searchParams.get('loginError');
		if (error || loginError) {
			// TODO: show snackbar with error
			console.error('Error:', error || loginError);
		}
	}
	, []);

	return (
		<div>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
			</Helmet>
			<Main style={{ minHeight: '90vh', paddingTop: '8rem' }}>
				<HeaderLanding />
				<Container>
					<Card sx={{ maxWidth: '400px !important', backgroundColor: '#fafafa', boxSizing: 'border-box' }}>
						<Typography level='h2' textAlign='left' width='100%'>Welcome</Typography>
						<ButtonsContainer>
							<a href='/api/auth/google'>
								<Button size='lg' fullWidth variant='shadowed' color='secondary'>
									<GoogleIcon width={24} height={24}/>
									<span style={{ marginLeft: '.5rem', marginRight: 'auto', flexGrow: 1 }}>Continue with Google</span>
								</Button>
							</a>
							<a href='/api/auth/slack'>
								<Button size='lg' fullWidth variant='shadowed' color='secondary' disabled>
									<SlackIcon width={24} height={24} />
									<span style={{ marginLeft: '.5rem', marginRight: 'auto', flexGrow: 1 }}>Login with Slack</span>
								</Button>
							</a>
						</ButtonsContainer>
						<Typography level='body-sm' style={{ textAlign: 'center' }}>
							By logging in you agree to our <a style={{ color: colors.black, textDecoration: 'underline' }} href='/privacy'>Privacy Policy</a> and <a style={{ color: colors.black, textDecoration: 'underline' }} href='/terms'>Terms of Service</a>.
						</Typography>
					</Card>
				</Container>
			</Main>
			<Footer />
		</div>
	);
};

export default LoginPage;
