import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { HeaderLandingAnnotator } from '../components/Header';
import Footer from '../components/Footer';
import { AspectRatio, Box, Button, ButtonGroup, Chip, CircularProgress, Divider, IconButton, Input, LinearProgress, Typography } from '@mui/joy';
import CodeIcon from '@/icons/CodeIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import { Helmet } from 'react-helmet';
import AuthIcon from '@/icons/AuthenticationIcon';
import CursorIcon from '@/icons/CursorIcon';
import FlowIcon from '@/icons/FlowIcon';
import GitHubIcon from '@/icons/GithubIcon';
import Fade from 'react-reveal/Fade';
import { css, keyframes } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import ParameterInput from './Builder/components/ParameterInputV2';
import PlayIcon from '../icons/PlayIcon';
import VisionIcon from '../icons/VisionIcon';
import RocketIcon from '@/icons/RocketIcon';
import CursorPointerIcon from '@/icons/CursorPointerIcon';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import RedShoesIcon from '@/icons/RedShoesIcon';
import LogoIcon from '@/icons/LogoIcon';
import SchemaInput from './Builder/components/SchemaInput';
import SchemaOutput from './Builder/components/SchemaOutput';
import CheckIcon from '@/icons/CheckIcon';
import CloseIcon from '@/icons/CloseIcon';


const HeroContainer = styled.div`
	padding-top: 10rem;
	padding-bottom: 6rem;
	padding-left: 2rem;
	padding-right: 2rem;
	position: relative;
	background-color: #ffffff;
	background-size: calc(100% / 12);
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px);
	@media (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;

const PolkadoBackground = styled.div`
	position: absolute;
	top: 0;
	left: -1rem;
	width: calc(100% + 2rem);
	height: 100%;
  background-image: radial-gradient(circle, #d9d9d9 25%, transparent 25%);
  background-size: 8px 8px;
  mask-image: radial-gradient(ellipse at center, black 30%, transparent 75%);
	z-index: 1;
`;

const LinearBackground = styled.div`
	position: absolute;
	top: 0;
	left: -0.5rem;
	width: calc(100% + 1rem);
	height: 100%;
	background-size: 8px 8px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
  mask-image: radial-gradient(ellipse at center, black 30%, transparent 85%);
	z-index: 1;
`;
const GradientBackground = styled.div`
	position: absolute;
	top: 0;
	left: -0.5rem;
	width: calc(100% + 1rem);
	height: 100%;
	background: radial-gradient(ellipse at center, var(--joy-palette-neutral-300) 0%, transparent 65%);
	z-index: 1;
`;

const HeroContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	z-index: 10;
	position: relative;
	gap: 1rem;
	max-width: 1000px;
	margin: 0 auto;
`;

const RadialGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	mask-image: radial-gradient(ellipse at center,transparent 1%,#000 75%);
	background-color: white;
`;

const LogoContainer = styled(Box)`
	background-color: #e3effb;
	color: #12467b;
	padding: 5px;
	border-radius: 5px;
	max-width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
`;

const HeroCodeExample = styled(Box)`
	position: absolute;
	z-index: 10;
	bottom: -2.5rem;
	right: -2.5rem;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-neutral-600), var(--joy-palette-neutral-800);
	background-color: var(--joy-palette-neutral-700);

`;

const Section = styled.section`
	display: flex;
	justify-content: center;
	padding: 1rem;
	background-color: white;
`;

const InfoContainer = styled(Box)`
	position: relative;
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	& > * {
		z-index: 10;
	}
`;

const rotatingBorder = keyframes`
	to {
		background-position: 60px 0%, -60px 100%, 0% -60px, 100% 60px;
	}
`;

const RotatingBorder = styled(Box)<{bgHighlight?: boolean}>`
	animation: ${rotatingBorder} 10s linear infinite;
	background-image:
		linear-gradient(90deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(90deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(0deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(0deg, #0a6bcb 50%, transparent 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 6px 2px, 6px 2px, 2px 6px, 2px 6px;
	background-position: 0% 0%, 0% 100%, 0% 0%, 100% 0%;
	border: none;
	position: relative;
	${({ bgHighlight }) => bgHighlight && css`
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #0a6bcb24;
		}
	`}
`;


const ResearchPage: React.FC = () => {
	const isMobile = useMediaQuery('(max-width: 992px)');


	return (
		<div>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
			</Helmet>
			<HeaderLandingAnnotator />
			<HeroContainer>
				<HeroContent>
					<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' marginBottom={6}>
						<Fade>
							<Chip size='md' sx={{ mb: 1 }} startDecorator={<RocketIcon width={12} height={12} fill='currentColor' />} color='primary'>Research</Chip>
						</Fade>
						<Fade delay={50}>
							<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.5} fontSize={58} level='h1'>Plato Research</Typography>
						</Fade>
						<Fade delay={100}>
							<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
							Environments for evaluating your web agents
							</Typography> </Fade>
						<Fade delay={150}>
							<Box display='flex' gap={1} marginTop={4}>
								<Button size='md' color='third' variant='shadowed' onClick={() => document.getElementById('learn-more')?.scrollIntoView({ behavior: 'smooth' })}>Learn more</Button>
								<a href='https://cal.com/rob-plato/30min' target='_blank' rel='noreferrer'>
									<Button size='md' color='secondary' variant='shadowed' endDecorator={<ArrowRightIcon width={12} height={12} fill='currentColor' />}>Talk with us</Button>
								</a>
							</Box>
						</Fade>
					</Box>
				</HeroContent>
				<RadialGradient />
			</HeroContainer>
			<main style={{ padding: '0rem 0rem', minHeight: '40vh' }}>

			</main>
			<Footer />
		</div>
	); };

export default ResearchPage;
