import styled from '@emotion/styled';
import { Box, List, Typography, ListItem, ListItemButton, Button, Avatar } from '@mui/joy';
import React, { useContext, useState } from 'react';
import LogoIcon from '@/icons/LogoIcon';
import UsageIcon from '@/icons/UsageIcon';
import FlowIcon from '@/icons/FlowIcon';
import HomeIcon from '@/icons/HomeIcon';
import SettingsIcon from '@/icons/SettingsIcon';
import WebIcon from '@/icons/WebIcon';
import { NavLink, useLocation } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import StartTrainerSessionModal from './StartTrainerSessionModal';


const Container = styled(Box)`
  width: 100%;
  // background-color: #fafafa;
	background: var(--joy-palette-background-level1);
  height: 100vh;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
`;

const ListContainer = styled(Box)`

`;

const BottomListContainer = styled(Box)`
`;

const BottomSection = styled(Box)`
  display: flex;
	align-items: flex-end;
	flex-grow: 1;
`;

const SideNavigation: React.FC = () => {
	const currentPath = useLocation().pathname;
	const { user: { user } } = useContext(AppContext);
	const [startTrainerSessionModalOpen, setStartTrainerSessionModalOpen] = useState(false);

	return (
		<Container>
			<StartTrainerSessionModal open={startTrainerSessionModalOpen} onClose={() => setStartTrainerSessionModalOpen(false)} />
			<Box p={3} pt={3} display='flex' gap={2} justifyContent='space-between' alignItems='center'>
				<a href='/' style={{ display: 'flex',  gap: '8px', alignItems: 'center' }}>
					<LogoIcon style={{ borderRadius: '5px' }} width='24px' height='24px' />
					<Typography fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
				</a>
				<Typography mt={0.5} level='body-xs' color='neutral'>
          v1.1.12
				</Typography>
			</Box>
			<ListContainer p={2} pt={0}>
				<NavLink to='/web-editor'>
					<Button sx={{ marginBottom: '1rem' }} fullWidth variant='shadowed' color='secondary'>
						Launch Demo
					</Button>
				</NavLink>
				<Button sx={{ marginBottom: '1rem' }} fullWidth variant='shadowed' color='third' onClick={() => setStartTrainerSessionModalOpen(true)}>
						Launch Test
				</Button>
				<List>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/' selected={currentPath === '/'}>
							<HomeIcon width='18px' height='18px' fill='currentColor' />
							Dashboard
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton disabled sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/web-flows' selected={currentPath === '/web-flows'}>
							<FlowIcon width='18px' height='18px' fill='currentColor' />
							Web Flows
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton disabled sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/web-flow-runs' selected={currentPath === '/web-flow-runs'}>
							<UsageIcon width='18px' height='18px' fill='currentColor' />
							Web Flow Runs
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/sessions' selected={currentPath === '/sessions'}>
							<WebIcon width='18px' height='18px' fill='currentColor' />
							Browser Sessions
						</ListItemButton>
					</ListItem>
				</List>
			</ListContainer>

			<BottomListContainer p={2} pt={0}>
				<List>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/settings' selected={currentPath === '/settings'}>
							<SettingsIcon width='18px' height='18px' fill='currentColor' />
							Settings
						</ListItemButton>
					</ListItem>
				</List>
			</BottomListContainer>

			<BottomSection p={2}>
				<Box display='flex' gap={2} alignItems='center' maxWidth='100%'>
					<Avatar size='sm' src={user?.image_url} />
					<Typography sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}  level='body-md'>{user?.email}</Typography>
				</Box>
			</BottomSection>
		</Container>
	);
};

export default SideNavigation;
