import styled from '@emotion/styled';
import React from 'react';
import LogoIcon from '@/icons/LogoIcon';
import { Box, Button, Typography } from '@mui/joy';
import { NavLink } from 'react-router-dom';

const StyledFooter = styled.footer`
  display: flex;
  background-color: #eef0f1;
  padding: 2rem;
  border: 1px solid #E4e4e4;
`;

const FooterContent = styled.div`
  max-width: 100%;
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;


const Footer: React.FC = () => {
	return (
		<StyledFooter>
			<FooterContent>
				<LeftContainer>
					<Box display='flex' alignItems='center' gap={1}>
						<a href='/' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<LogoIcon style={{ borderRadius: '5px' }} width='28px' height='28px' />
						</a>
						<Typography fontFamily='"Source Code Pro", monospace' fontSize='18px' fontWeight='bold' level='title-md'>Plato Agentic Systems</Typography>
					</Box>
					{/* <a href="https://twitter.com/RobFarlow" target="_blank" rel="noreferrer">
						<XTwitter width={18} height={18} fill="white" />
					</a> */}
				</LeftContainer>
				<Box display='flex' gap={1}>
					<a href='mailto:rob@plato.so'>
						<Button sx={{ padding: '0px 8px' }} size='sm' color='third' variant='shadowed' >Contact us</Button>
					</a>
					{/* <NavLink to='/privacy'>
						<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Privacy Policy</Button>
					</NavLink> */}
					{/* <NavLink to='/tools'>
						<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Pre-built Tools</Button>
					</NavLink>
					<NavLink to='/pricing'>
						<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Pricing</Button>
					</NavLink> */}
					{/* <a href='https://docs.plato.so' target='_blank' rel='noreferrer'>
						<Button sx={{ padding: '0px 8px' }} size='sm' color='neutral' variant='plain' >Docs</Button>
					</a> */}
					<a href='https://cal.com/rob-plato/30min' target='_blank' rel='noreferrer'>
						<Button variant='shadowed' color='secondary' size='sm'>Book a demo</Button>
					</a>
				</Box>
			</FooterContent>
		</StyledFooter>
	);
};

export default Footer;
