import Main from '@/components/Main';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SlackIcon from '../icons/SlackIcon';
import Header, { HeaderLanding } from '../components/Header';
import Footer from '../components/Footer';
import { colors } from '../styles/theme';
import { Box, Button, Chip, Divider, Grid, Input, Table, Typography } from '@mui/joy';
import API from '../api';
import { useParams } from 'react-router-dom';
import { CopyBlock } from 'react-code-blocks';


const IntegrationPage: React.FC = () => {
	const [integrationSource, setIntegrationSource] = useState(null);
	const { name } = useParams();

	useEffect(() => {
		API.getIntegrationByName(name).then((data) => {
			console.log(data);
			setIntegrationSource(data);
		});
	}, [name]);


	return (
		<div style={{ backgroundColor: 'white' }}>
			<Main style={{ minHeight: '90vh' }}>
				<Header />
				<img src={integrationSource?.icon_url} alt={integrationSource?.name} width={32} height={32} />
				<Typography level='h2'>{integrationSource?.name}</Typography>
				<Typography level='body-md'>{integrationSource?.description}</Typography>
				{integrationSource?.methods.map((sourceEndpoint) => {
					const parameters = sourceEndpoint.parameters || sourceEndpoint.endpoint?.parameters;
					const name = sourceEndpoint.name || sourceEndpoint.endpoint?.name;
					const url = sourceEndpoint.url || sourceEndpoint.endpoint?.urpathl;
					const method = sourceEndpoint.method || sourceEndpoint.endpoint?.method;

					return (
						<Box key={url} marginTop={2}>
							<Divider sx={{ marginY: '1rem' }} />
							<Grid container spacing={2}>
								<Grid sm={12} md={6}>
									<Typography marginBottom={4} marginTop={2} level='h4'>{name}</Typography>
									<Typography level='title-md'>Parameters</Typography>
									{parameters?.map((parameter) => (
										<div key={parameter.name}>
											<Divider sx={{ marginY: '.5rem' }} />
											<Box display='flex' gap={0.5} alignItems='center'>
												<Typography level='title-md'>{parameter.name}</Typography>
												<Chip size='sm' color='neutral' variant='plain'>{parameter.type}</Chip>
											</Box>
											<Typography level='body-xs'>{parameter.description}</Typography>
											{Boolean(parameter.sub_parameters?.length) && (
												<Box marginLeft={2} border='1px solid #e0e0e0' borderRadius={4} marginTop={1} paddingY={1}>
													{parameter.sub_parameters?.map((subParameter, index) => (
														<Box key={subParameter.name} paddingX={1}>
															{index > 0 && <Divider sx={{ marginY: '.5rem' }} />}
															<Box display='flex' gap={0.5} alignItems='center'>
																<Typography level='title-md'>{subParameter.name}</Typography>
																<Chip size='sm' color='neutral' variant='plain'>{subParameter.type}</Chip>
															</Box>
															<Typography level='body-xs'>{subParameter.description}</Typography>
														</Box>
													))}
												</Box>
											)}
										</div>
									))}
								</Grid>
								<Grid sm={12} md={6}>
									<CopyBlock
										language={'python'}
										codeBlockStyle={{
											fontSize: '14px',
										}}
										text={`shinpads.request(
	user_id,
	"${integrationSource.name.toLowerCase()}",
	"${url}",
	parameters
)`}
										showLineNumbers
										codeBlock
									/>
									<Box marginTop={2}>
										{sourceEndpoint?.actions?.map((action) => (
											<div key={action.name}>
												<Typography level='title-md'>{action.name}</Typography>
												<Box marginLeft={2}>
													{action?.subActions?.map((subAction) => (
														<div key={subAction.name}>
															<Typography level='title-md'>{subAction.name}</Typography>
														</div>
													))}
												</Box>
											</div>
										))}
									</Box>

								</Grid>
							</Grid>
						</Box>
					);
				})}
			</Main>
			<Footer />
		</div>
	);
};

export default IntegrationPage;
