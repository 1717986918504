import React, { useEffect, useState } from 'react';
import {
	Modal,
	ModalDialog,
	ModalClose,
	Typography,
	Button,
	Box,
	FormControl,
	FormLabel,
	Input,
} from '@mui/joy';
import API from '@/api';

interface ConnectIntegrationModalProps {
  open: boolean;
  onClose: () => void;
  integrationSource: any;
  integrations: any[];
}

const ConnectIntegrationModal: React.FC<ConnectIntegrationModalProps> = ({
	open,
	onClose,
	integrationSource,
	integrations,
}) => {
	const [apiKey, setApiKey] = useState(integrations[0]?.data?.api_key || '');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setApiKey(integrations[0]?.data?.api_key || '');
	}, [integrations]);

	const hasIntegrations = integrations.length > 0;

	async function handleCreateIntegration(e) {
		e.preventDefault();
		setLoading(true);
		await API.createIntegration(integrationSource.id, { api_key: apiKey });
		setLoading(false);
		onClose();
	}

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog>
				<ModalClose />
				<Typography level='h4'>{hasIntegrations ? `Manage ${integrationSource?.name} Integration` : `Connect ${integrationSource?.name}`}</Typography>
				<Typography level='body-md' mb={2}>
          Please provide the necessary information to connect to {integrationSource?.name}.
				</Typography>
				<form onSubmit={handleCreateIntegration}>
					<Box display='flex' flexDirection='column' gap={2}>
						<FormControl>
							<FormLabel>API Key</FormLabel>
							<Input type='password' required value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
						</FormControl>
						<Box display='flex' justifyContent='flex-end'>
							{hasIntegrations ? (
								<Button type='submit' color='danger' loading={loading }>Disconnect</Button>
							) : (
								<Button type='submit' loading={loading}>Connect</Button>
							)}
						</Box>
					</Box>
				</form>
			</ModalDialog>
		</Modal>
	);
};

export default ConnectIntegrationModal;
