import React, { useContext, useEffect, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, Textarea } from '@mui/joy';
import { Paper } from '@mui/material';
import moment from 'moment'; import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '@/components/DashboardContainer';
import { NavLink } from 'react-router-dom';
import API from '@/api';
import { AppContext } from '@/context/AppContext';
const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? '#f0f0f0' : 'transparent'};
`;

const DashboardPage: React.FC = () => {
	const { addSnackbar } = useContext(AppContext);
	const [usage, setUsage] = useState([]);
	const [selectedUsage, setSelectedUsage] = useState(null);
	const [view, setView] = useState('response');

	const [message, setMessage] = useState('');

	const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		await API.submitContactForm(message);
		setMessage('');
		addSnackbar({
			content: (
				<Box>
					<Typography level='title-lg'>Message sent</Typography>
					<Typography level='body-sm'>We&apos;ll get back to you as soon as possible.</Typography>
				</Box>
			),
			severity: 'success',
		});
	};

	return (
		<DashboardContainer>
			<Box maxWidth='800px' margin='0 auto' marginTop={4}>
				<Box marginBottom={2}>
					<Chip size='sm' color='primary' variant='soft'>Getting started</Chip>
					<Typography level='h2'>Introduction</Typography>
					<Typography level='body-sm'>
						Plato is a tool that allows agents and applications to interface with the web.
					</Typography>
				</Box>

				<Box marginBottom={2}>
					<Typography level='h3'>Create a new automation</Typography>
					<Typography level='body-sm'>
						You can get started by creating an automation in the builder <NavLink to='/web-editor'><Button size='sm' variant='shadowed' color='third'>Launch Web Editor</Button></NavLink	>
					</Typography>
					<Typography level='body-sm'>
						Or by using the <a href='https://docs.plato.so'>Plato API Docs</a>
					</Typography>
				</Box>

				<Box marginBottom={2} marginTop={6}>
					<Chip size='sm' color='primary' variant='soft'>Help</Chip>
					<Typography level='h2'>Questions?</Typography>
					<Typography level='body-sm'>
						Send us a message
					</Typography>
					<form onSubmit={handleFormSubmit}>
						<Textarea
							sx={{ marginTop: '8px' }}
							size='sm'
							placeholder='Message'
							minRows={4}
							maxRows={10}
							onChange={(e) => setMessage(e.target.value)}
							value={message}
							required
						/>
						<Button sx={{ marginTop: '8px' }} size='sm' variant='shadowed' color='secondary' type='submit'>Submit</Button>
					</form>
				</Box>

			</Box>
		</DashboardContainer>
	);
};

export default DashboardPage;
