import axios from 'axios';
import { JsonSchema } from './pages/Builder/components/SchemaInput';

let BASE_URL = window.location.origin;

if (ENVIRONMENT === 'production' && !BASE_URL.includes('localhost')) {
	if (!BASE_URL.startsWith('https://')) {
		BASE_URL = BASE_URL.replace('http://', 'https://');
	}
}


class API {
	static async getUserFromSession() {
		const user = await axios.get(BASE_URL + '/api/user/from-session');

		return user.data;
	}

	static async viewAsUser(email: string) {
		const res = await axios.post(BASE_URL + '/api/admin/view-as', { email });
		const { data } = res;
		if (!data?.id) {
			console.error('User not found');
			return;
		}
		document.cookie = `view-as=${data.publicId}`;
		window.location.href = '/';
	}

	static async clearViewAs() {
		document.cookie = 'view-as=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = '/';
	}

	static async logout() {
		await axios.post(BASE_URL + '/api/user/logout');
	}


	static async getBrowserSessions() {
		const res = await axios.get(BASE_URL + '/api/browser/sessions');
		return res.data;
	}

	static async getBuilderSession(options: { isTrainerSession?: boolean } = { isTrainerSession: false }) {
		const res = await axios.post(BASE_URL + '/api/browser/session/builder', { options });
		return res.data;
	}

	static async getBrowserSession(options: { type: 'builder' | 'trainer' | 'ai' }) {
		const res = await axios.post(BASE_URL + '/api/browser/session', { ...options });
		return res.data;
	}

	static async startTrainerSession(options: { startUrl: string, task: string, responseFormat: any }) {
		const res = await axios.post(BASE_URL + '/api/browser/session/trainer', { ...options });
		return res.data;
	}

	static async markTrainerSessionCompleted(browserSessionId: string, output: any) {
		await axios.post(BASE_URL + '/api/browser/session/trainer/mark-completed', { browserSessionId, output });
	}

	static async createWebFlow(webFlow: any) {
		const res = await axios.post(BASE_URL + '/api/web-flows', webFlow);
		return res.data;
	}

	static async getWebFlows() {
		const res = await axios.get(BASE_URL + '/api/web-flows');
		return res.data;
	}

	static async runWebFlow(webFlowId: number, parameters: any, options: any) {
		const res = await axios.post(`${BASE_URL}/api/web-flows/${webFlowId}/run`, { parameters, options });
		return res.data;
	}

	static async runNodeOnBrowserSession(node: any, browserSessionId: string) {
		const res = await axios.post(`${BASE_URL}/api/web-flows/run-node-on-browser-session/${browserSessionId}`, { node });
		return res.data;
	}

	static async getWebFlowRuns() {
		const res = await axios.get(`${BASE_URL}/api/web-flow-runs`);
		return res.data;
	}

	static async submitContactForm(message: string) {
		await axios.post(`${BASE_URL}/api/user/contact-form`, { message });
	}

	static async navigateBrowserSession(browserSessionId: string, url: string) {
		const res = await axios.post(`${BASE_URL}/api/browser/session/${browserSessionId}/navigate`, { url });
		return res.data;
	}

	static async runWebTask(browserSessionId: string, task: any, startUrl?: string) {
		const res = await axios.post(`${BASE_URL}/api/plato/task`, { task, session_id: browserSessionId, start_url: startUrl });
		return res.data;
	}

	static async getBrowserSessionLogs(browserSessionId: string) {
		const res = await axios.get(`${BASE_URL}/api/browser/session/${browserSessionId}/logs`);
		return res.data;
	}

	static async extractFromSelectors(browserSessionId: string, selectors: Record<string, string>, responseFormat: JsonSchema) {
		const res = await axios.post(`${BASE_URL}/api/browser/session/${browserSessionId}/populate-selectors`, { selectors, responseFormat });
		return res.data;
	}
}

export default API;
