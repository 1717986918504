import React, { useEffect } from 'react';
import { Typography, Box, Grid } from '@mui/joy';
import styled from '@emotion/styled';
import DashboardContainer from '@/components/DashboardContainer';

const Section = styled(Box)`
  padding: 1rem;
`;


const SessionsPage: React.FC = () => {

	useEffect(() => {

	}, []);


	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Admin</Typography>
			<Grid container spacing={2}>
			</Grid>
		</DashboardContainer>
	);
};

export default SessionsPage;
