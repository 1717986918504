import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { HeaderLanding } from '../components/Header';
import Footer from '../components/Footer';
import { AspectRatio, Box, Button, Chip, Divider, IconButton, Typography } from '@mui/joy';
import CodeIcon from '@/icons/CodeIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import { Helmet } from 'react-helmet';
import AuthIcon from '@/icons/AuthenticationIcon';
import CursorIcon from '@/icons/CursorIcon';
import FlowIcon from '@/icons/FlowIcon';
import GitHubIcon from '@/icons/GithubIcon';
import Fade from 'react-reveal/Fade';
import { css, keyframes } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import ParameterInput from './Builder/components/ParameterInputV2';
import PlayIcon from '../icons/PlayIcon';
import VisionIcon from '../icons/VisionIcon';
import RocketIcon from '@/icons/RocketIcon';


const HeroContainer = styled.div`
	padding-top: 10rem;
	padding-bottom: 6rem;
	padding-left: 2rem;
	padding-right: 2rem;
	position: relative;
	background-color: #ffffff;
	background-size: 42px 42px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	@media (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;

const PolkadoBackground = styled.div`
	position: absolute;
	top: 0;
	left: -1rem;
	width: calc(100% + 2rem);
	height: 100%;
  background-image: radial-gradient(circle, #d9d9d9 25%, transparent 25%);
  background-size: 8px 8px;
  mask-image: radial-gradient(ellipse at center, black 30%, transparent 75%);
	z-index: 1;
`;

const LinearBackground = styled.div`
	position: absolute;
	top: 0;
	left: -0.5rem;
	width: calc(100% + 1rem);
	height: 100%;
	background-size: 8px 8px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
  mask-image: radial-gradient(ellipse at center, black 30%, transparent 85%);
	z-index: 1;
`;
const GradientBackground = styled.div`
	position: absolute;
	top: 0;
	left: -0.5rem;
	width: calc(100% + 1rem);
	height: 100%;
	background: radial-gradient(ellipse at center, var(--joy-palette-neutral-300) 0%, transparent 65%);
	z-index: 1;
`;

const HeroContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	z-index: 10;
	position: relative;
	gap: 1rem;
	max-width: 1000px;
	margin: 0 auto;
`;

const RadialGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	mask-image: radial-gradient(ellipse at center,transparent 1%,#000 75%);
	background-color: white;
`;

const LogoContainer = styled(Box)`
	background-color: #e3effb;
	color: #12467b;
	padding: 5px;
	border-radius: 5px;
	max-width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
`;

const HeroCodeExample = styled(Box)`
	position: absolute;
	// background-color: var(--joy-palette-neutral-300);
	z-index: 10;
	// border-radius: 5px;
	bottom: -2.5rem;
	right: -2.5rem;
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor), var(--joy-shadow-md);
`;

const Section = styled.section`
	display: flex;
	justify-content: center;
	padding: 1rem;
	background-color: white;
`;

const InfoContainer = styled(Box)`
	position: relative;
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	& > * {
		z-index: 10;
	}
`;

const rotatingBorder = keyframes`
	to {
		background-position: 60px 0%, -60px 100%, 0% -60px, 100% 60px;
	}
`;

const RotatingBorder = styled(Box)<{bgHighlight?: boolean}>`
	animation: ${rotatingBorder} 10s linear infinite;
	background-image:
		linear-gradient(90deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(90deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(0deg, #0a6bcb 50%, transparent 50%),
		linear-gradient(0deg, #0a6bcb 50%, transparent 50%);
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 6px 2px, 6px 2px, 2px 6px, 2px 6px;
	background-position: 0% 0%, 0% 100%, 0% 0%, 100% 0%;
	border: none;
	position: relative;
	${({ bgHighlight }) => bgHighlight && css`
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #0a6bcb24;
		}
	`}
`;


const LandingPage: React.FC = () => {
	const isMobile = useMediaQuery('(max-width: 992px)');
	const containerRef = useRef<HTMLDivElement>(null);
	const [containerZoom, setContainerZoom] = useState(1);

	useEffect(() => {
		const handleResize = () => {
			if (containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect();
				console.log('rect.width', rect.width);
				setContainerZoom(rect.width / 800);
			}
		};
		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [containerRef]);

	// useEffect(() => {
	// 	API.getIntegrationSources().then((data) => {
	// 		console.log('data', data);
	// 		setIntegrationSources(data);
	// 	});
	// }, []);

	useEffect(() => {
		// check if query param has error or loginError
		const url = new URL(window.location.href);
		const error = url.searchParams.get('error');
		const loginError = url.searchParams.get('loginError');
		if (error || loginError) {
			// TODO: show snackbar with error
			console.error('Error:', error || loginError);
		}
	}
	, []);

	return (
		<div>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
			</Helmet>
			<HeaderLanding />
			<HeroContainer>
				<HeroContent>
					<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' marginBottom={6}>
						<Fade>
							<Chip size='md' sx={{ mb: 1 }} startDecorator={<RocketIcon width={12} height={12} fill='currentColor' />} color='primary'>Early Access</Chip>
							{/* <a href='https://github.com/useplato/plato' target='_blank' rel='noreferrer'>
								<Chip size='md' sx={{ mb: 1 }} startDecorator={<GitHubIcon width={14} height={14} fill='currentColor' />} color='primary'>Early access</Chip>
							</a> */}
							{/* <a href='https://github.com/useplato/plato' target='_blank' rel='noreferrer'>
								<Box display='flex' alignItems='center' border='1px solid #E4E4E4' borderRadius='4px' overflow='hidden' marginBottom={1}>
									<Box display='flex' gap={1} alignItems='center' paddingX={1} paddingY={.5} sx={{ backgroundColor: '#F7F7F7' }}>
										<GitHubIcon width={18} height={18} />
										<Typography level='title-sm'>Star</Typography>
									</Box>
									<Box display='flex' gap={1} alignItems='center' paddingX={1} paddingY={.5} sx={{ backgroundColor: '#ffffff' }}>
										<Typography level='body-xs'>open source</Typography>
									</Box>
								</Box>
							</a> */}
						</Fade>
						<Fade delay={50}>
							<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.5} fontSize={58} level='h1'>Structured interface for the web</Typography>
						</Fade>
						<Fade delay={100}>
							<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
							Extract data, perform actions, and automate tasks through virtual browsers
							</Typography> </Fade>
						<Fade delay={150}>
							<Box display='flex' gap={1} marginTop={4}>
								<a href='https://cal.com/rob-plato/30min' target='_blank' rel='noreferrer'>
									<Button size='md' color='third' variant='shadowed'>Talk with us</Button>
								</a>
								<NavLink to='/login'>
									<Button size='md' color='secondary' variant='shadowed' endDecorator={<ArrowRightIcon width={12} height={12} fill='currentColor' />}>Get started</Button>
								</NavLink>
							</Box>
						</Fade>
					</Box>
					<Fade delay={200}>
						<Box paddingX={1}>
							<Box ref={containerRef} position='relative' maxWidth='800px' width='100%' margin='0 auto'>
								<Box sx={{ paddingBottom: 'calc(9/16 * 100%)' }}>
									<Box width='800px' height='450px' sx={{ transform: `scale(${containerZoom})`, position: 'absolute', top: 0, left: 0, transformOrigin: '0 0' }}>
										<HeroCodeExample boxShadow='md'>
											{/* <Box display='flex' gap={1} border='1px solid #666666' borderRadius='4px' padding={1} marginBottom={1} alignItems='center'>
									<Chip size='sm' color='primary' variant='solid'>Python</Chip>
									<Divider orientation='vertical' sx={{ backgroundColor: '#666666' }} />
									<Box>
										<Typography textColor='common.white' level='body-sm'>Buy soylent on Amazon</Typography>
									</Box>
								</Box> */}
											<pre style={{ margin: 0 }}>
												<Typography component='code' level='body-sm' className='language-python' style={{ whiteSpace: 'normal', overflowWrap: 'anywhere', color: '#9ca1ac', fontSize: '13px', padding: '0px', fontFamily: '"Source Code Pro", monospace' }}>
													{/* <span style={{ color: '#d5323f' }}>session</span> = <span style={{ color: '#e4860f' }}>PlatoClient</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>start_session</span>(<span style={{ color: '#d5323f' }}>config</span>)<br/>
													<br/> */}
													{/* <span style={{ color: '#d5323f' }}>session</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>create_task</span>(<span style={{ color: '#67be28' }}>&quot;https://www.amazon.com&quot;</span>)<br/>
												<span style={{ color: '#d5323f' }}>session</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>act</span>(<span style={{ color: '#67be28' }}>&quot;click the search bar&quot;</span>)<br/>
												<span style={{ color: '#d5323f' }}>session</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>type</span>(<span style={{ color: '#67be28' }}>&quot;chocolate soylent [Enter]&quot;</span>)<br/>
												<span style={{ color: '#d5323f' }}>session</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>act</span>(<span style={{ color: '#67be28' }}>&quot;click the first result&quot;</span>)<br/>
												<span style={{ color: '#d5323f' }}>session</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>act</span>(<span style={{ color: '#67be28' }}>&quot;click add to cart&quot;</span>)<br/>
												<br/>
												<span style={{ color: '#d5323f' }}>session</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>end</span>() */}
													<span style={{ color: '#e4860f' }}>PlatoClient</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>createTask</span>{'({'}
													<br/>
													<div style={{ marginLeft: '12px' }}>
														<span style={{ color: '#d5323f' }}>startUrl</span>: <span style={{ color: '#67be28' }}>&apos;https://www.myshop.com&apos;</span>,
														<br/>
														<span style={{ color: '#d5323f' }}>task</span>: <span style={{ color: '#67be28' }}>&apos;extract product information for any red shoes&apos;</span>,
														<br/>
														<span style={{ color: '#d5323f' }}>responseFormat</span>: {'{'}
														<br/>
														<div style={{ marginLeft: '12px' }}>
															<span style={{ color: '#d5323f' }}>name</span>: <span style={{ color: '#e4860f' }}>z</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>string</span>(),
															<br/>
															<span style={{ color: '#d5323f' }}>description</span>: <span style={{ color: '#e4860f' }}>z</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>string</span>(),
															<br/>
															<span style={{ color: '#d5323f' }}>price</span>: <span style={{ color: '#e4860f' }}>z</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>number</span>(),
															<br/>
															<span style={{ color: '#d5323f' }}>image_url</span>: <span style={{ color: '#e4860f' }}>z</span>.<span style={{ color: '#61AFEF', fontWeight: '600' }}>string</span>(),
															<br/>
														</div>
														{'}'}
														<br/>
													</div>
													{'})'}
												</Typography>
											</pre>
										</HeroCodeExample>
										<Box width='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='100%' border='1px solid #E4E4E4' borderRadius='8px' overflow='hidden' boxShadow='sm'>
											<Box width='100%' height='8%' bgcolor='#ababab' display='flex' alignItems='center' padding='0 8px'>
												<Box width='10px' height='10px' bgcolor='#FF4136' borderRadius='50%' marginRight='4px'></Box>
												<Box width='10px' height='10px' bgcolor='#FFDC00' borderRadius='50%' marginRight='4px'></Box>
												<Box width='10px' height='10px' bgcolor='#01FF70' borderRadius='50%'></Box>
											</Box>
											<Box width='100%' height='92%' bgcolor='#F7F7F7' display='flex' gap={2} padding={2} paddingX={4}>
												<Box height='100%' width='18%' bgcolor='#E4E4E4' borderRadius='4px' padding='12px'>
													{/* <RotatingBorder display='flex' gap={0.5} alignItems='center' marginBottom={2} width='fit-content' padding='2px'>
												<Box width='24px' height='24px' bgcolor='#d0d0d0' borderRadius='2px' />
												<Box width='56px' height='16px' bgcolor='#d0d0d0' borderRadius='2px' />
											</RotatingBorder>
											<Box width='100%' height='6px' bgcolor='#d0d0d0' borderRadius='2px' /> */}
												</Box>
												<Box display='flex' flexDirection='column' gap={2} flexGrow={1}>
													<Box width='100%' height='48px' bgcolor='#E4E4E4' borderRadius='4px' />
													<Box display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={2} margin={0} flexGrow={1} width='100%'>
														{Array.from({ length: 8 }).map((_, index) => (
															<Box display='flex' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																<RotatingBorder position='relative' flexGrow={1} bgcolor='#E4E4E4' borderRadius='4px'>
																	{index === 0 && <Chip sx={{ position: 'absolute', top: '-10px', left: '-26px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='md' color='primary'>image_url</Chip>}
																</RotatingBorder>
																<Box display='flex' flexDirection='column' gap={.5}>
																	<RotatingBorder position='relative' width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='2px'>
																		{index === 0 && <Chip sx={{ position: 'absolute', top: '-10px', left: '-26px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='md' color='primary'>name</Chip>}
																	</RotatingBorder>
																	<RotatingBorder position='relative' display='flex' gap={.5} flexDirection='column' height='fit-content' padding='2px'>
																		{index === 0 && <Chip sx={{ position: 'absolute', top: '-4px', left: '-62px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='md' color='primary'>description</Chip>}
																		<Box width='100%' height='6px' bgcolor='#E4E4E4' borderRadius='2px' />
																		<Box width='100%' height='6px' bgcolor='#E4E4E4' borderRadius='2px' />
																	</RotatingBorder>
																	<RotatingBorder position='relative' width='25%' height='16px' bgcolor='#E4E4E4' borderRadius='2px'>
																		{index === 0 && <Chip sx={{ position: 'absolute', top: '-3px', right: '28px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='md' color='primary'>price</Chip>}
																	</RotatingBorder>
																	{/* <Box width='100%' height='8px' bgcolor='#E4E4E4' borderRadius='4px' /> */}
																</Box>
															</Box>
														))}
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Fade>
				</HeroContent>
				<RadialGradient />
			</HeroContainer>
			<main style={{ padding: '2rem 0rem' }}>
				{/* <Section style={{ paddingBottom: '5rem', paddingTop: '5rem', marginTop: '2rem' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box mb={2}>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>What Plato can do</Typography>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Run headless browsers, extract data, perform actions, and automate tasks
								</Typography>
							</Box>
						</Fade>
						<Fade>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='61.8%'>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>On-demand headless browsers</Typography>
									<Typography level='body-sm'>Pain free browser infrastructure that also works with Playwright, Selenium, Puppeteer, etc.</Typography>
									<Box position='relative' flexGrow={1} paddingY={2}>
										<Box position='relative' maxWidth='400px' zIndex={10} margin='0 auto' boxShadow='md'>
											<AspectRatio ratio={16/9} variant='plain'>
												<Box>
													<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#fff', borderRadius: '5px', overflow: 'hidden' }}>
														<Box width='100%' height='8px' bgcolor='#ababab' />
														<Box width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='12px' margin='0 auto' marginTop={2} />
														<Box flexGrow={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
															{Array.from({ length: 8 }).map((_, index) => (
																<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																</Box>
															))}
														</Box>
													</Box>
												</Box>
											</AspectRatio>
											<Box position='absolute' bottom='-8px' left='-12px' bgcolor='neutral.700' zIndex={10} padding={2} borderRadius='4px' sx={{ fontSize: '14px', maxWidth: '240px' }} boxShadow='lg'>
												<span style={{ color: '#C586C0' }}>import</span> <span style={{ color: '#4EC9B0' }}>Plato</span> <span style={{ color: '#C586C0' }}>from</span> <span style={{ color: '#CE9178' }}>&apos;plato&apos;</span><br/>
												<span style={{ color: '#9CDCFE' }}>plato</span> <span style={{ color: '#D4D4D4' }}>=</span> <span style={{ color: '#4EC9B0' }}>Plato</span><span style={{ color: '#D4D4D4' }}>.</span><span style={{ color: '#DCDCAA' }}>startSession</span><span style={{ color: '#D4D4D4' }}>()</span>
											</Box>
										</Box>

									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='38.2%'>
									<LogoContainer>
										<AuthIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Solve captchas</Typography>
									<Typography level='body-sm'>Plato can solve captchas</Typography>

									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' paddingY={2} paddingTop={4} position='relative'>
										<PolkadoBackground />

										<Box boxShadow='md' display='flex' flexDirection='column' alignItems='center' justifyContent='center' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', width: '85%', maxWidth: '260px' }} zIndex={10} position='relative'>
											<Box display='flex' flexDirection='column' gap={.5} width='100%' marginBottom={1}>
												<Box width='40%' height='18px' sx={{ backgroundColor: '#ababab', borderRadius: '4px' }} />
												<Box width='70%' height='14px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
											</Box>
											<Box display='flex' flexDirection='column' gap={2} width='100%'>
												<Box padding={1} bgcolor='#f7f7f7'>
													<RotatingBorder marginY={1} width='fit-content' marginX='auto' borderRadius='5px' padding='4px' paddingX='12px' bgHighlight>
														<Typography margin={0} textAlign='center' width='100%' level='h4' sx={{ fontFamily: 'Inter, cursive', fontStyle: 'italic', letterSpacing: '2px', fontSize: '32px', transform: 'skew(-5deg)', width: 'fit-content' }}>LSYVR</Typography>
													</RotatingBorder>
												</Box>
												<Box width='100%' height='24px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'start', paddingLeft: '8px', fontSize: '12px' }}>
													|
												</Box>
												<Button size='sm' fullWidth variant='solid' color='primary' sx={{ minHeight: '24px' }}>
													<Box width='32px' height='6px' bgcolor='#eef0f1' borderRadius='4px' />
												</Button>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='42%'>
									<LogoContainer>
										<FlowIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>UI-independent automations</Typography>
									<Typography level='body-sm'>Define high-level automations and let Plato handle the details</Typography>
									<Box position='relative' flexGrow={1} height='200px'>
										<GradientBackground />
										<Box zIndex={10} position='absolute' top={0} left={0} width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
											<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2} sx={{ transform: 'scale(0.5)' }}>
												<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' borderRadius='8px' padding={2} paddingY={4} sx={{ backgroundColor: '#fff', maxWidth: '250px', width: '100%' }} boxShadow='rgba(36, 42, 66, 0.08) 0px 8px 20px -6px, rgba(36, 42, 66, 0.06) 0px 1px 4px -1px, rgba(36, 42, 66, 0.06) 0px 0px 2px 0px'>
													<Typography textAlign='left' width='100%' level='title-lg' marginBottom={2}>Login</Typography>
													<Box display='flex' flexDirection='column' gap={2} width='100%'>
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Button disabled sx={{ marginTop: 1 }} variant='shadowed' color='secondary' size='sm' fullWidth>Login</Button>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='58%'>
									<LogoContainer>
										<VisionIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Multi-step scraping</Typography>
									<Typography level='body-sm'>Extract data across multiple pages</Typography>
									<Box position='relative'>
										<LinearBackground />
										<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
											<Box zIndex={10} marginTop={2} boxShadow='md' display='flex' justifyContent='flex-start' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', marginX: '1rem', width: '85%' }} gap={2}>
												<Box width='50%' display='flex' flexDirection='column'>
													<Typography textAlign='left' width='100%' level='h4'>Indexing</Typography>
													<Box display='flex' flexDirection='column'  marginBottom={2} sx={{ maskImage: 'linear-gradient(to bottom, transparent, white 30%, white 70%, transparent)' }}>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/about</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' fontWeight='500' textColor='primary' level='body-sm'>https://acme.com/search</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/search?category=shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/item/white-tennis-shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/cart</Typography>
													</Box>

													<Box display='flex' flexDirection='column' gap={2} width='100%'>
													</Box>
												</Box>
												<Box sx={{ width: '50%', flexGrow: 1 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
													<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%' }}>
														<Box>
															<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#f7f7f7', borderRadius: '5px', overflow: 'hidden' }}>
																<Box width='100%' height='8px' bgcolor='#ababab' />
																<RotatingBorder width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='12px' margin='0 auto' marginTop={2} />
																<Box flexGrow={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																	{Array.from({ length: 8 }).map((_, index) => (
																		<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																		</Box>
																	))}
																</Box>
															</Box>
														</Box>
													</AspectRatio>
													<Typography textAlign='center' width='100%' level='body-xs'>clicking the search bar...</Typography>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box>

						</Fade>
					</Box>
				</Section> */}
				<Section style={{ paddingBottom: '6rem', paddingTop: '6rem', marginTop: '2rem', backgroundColor: '#f7f7f7' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Get started</Typography>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Get started with our easy to use API or try out the web editor
								</Typography>
							</Box>
						</Fade>
						<Fade>

							<Box display='flex' justifyContent='center' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='50%' sx={{ backgroundColor: '#ffffff !important' }}>
									<LogoContainer>
										<CodeIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>API</Typography>
									<Typography level='body-sm'>Make requests through our simple API</Typography>
									<Box bgcolor='neutral.700' zIndex={10} padding={2} borderRadius='4px' marginTop={2} sx={{ fontSize: '14px', color: '#fff' }} boxShadow='lg'>
										<span style={{ color: '#DCDCAA' }}>curl</span> -X <span style={{ color: '#C586C0' }}>POST</span> https://api.plato.so/run-task \<br/>
											-H <span style={{ color: '#CE9178' }}>&quot;Content-Type: application/json&quot;</span> \<br/>
											-d <span style={{ color: '#CE9178' }}>{'\''}</span>{'{'}<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '16px' }}>&quot;start_url&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;https://myshop.com&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '16px' }}>&quot;task&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;Extract product information for any red shoes&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '16px' }}>&quot;response_format&quot;</span>: {'{'}<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;name&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;string&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;description&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;string&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;price&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;number&quot;</span>,<br/>
										<span style={{ color: '#9CDCFE', marginLeft: '32px' }}>&quot;image_url&quot;</span>: <span style={{ color: '#CE9178' }}>&quot;string&quot;</span><br/>
										<span style={{ marginLeft: '16px' }}>{'}'}</span><br/>
										{'}'}<span style={{ color: '#CE9178' }}>{'\''}</span>
									</Box>
									<Box display='flex' justifyContent='flex-end' marginTop={2}>
										<a href='https://docs.plato.so' target='_blank' rel='noreferrer'>
											<Button size='md' color='third' variant='shadowed' endDecorator={<ArrowRightIcon fill='currentColor' width={12} height={12} />}>View API Docs</Button>
										</a>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='50%' sx={{ backgroundColor: '#ffffff !important' }}>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Web Editor</Typography>
									<Typography level='body-sm'>Try it out and see how it works</Typography>
									<Box paddingTop={2} paddingX={2} width='100%' margin='0 auto' maxWidth='500px' position='relative' flexGrow={1} display='flex' flexDirection='column' justifyContent='center'>
										<LinearBackground />
										<Box zIndex={10} position='relative'>
											<Box boxShadow='md'>
												<Box display='flex'>
													<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)'>
														<Typography level='body-xs' fontWeight='bold'>ACT</Typography>
														<Box marginLeft={1} display='flex' alignItems='center'>
															<IconButton loading size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={() => {}}>
																<PlayIcon fill='currentColor' width={10} height={10} />
															</IconButton>
														</Box>
													</Box>
												</Box>
												<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden' marginBottom={1}>
													<ParameterInput
														value='click each company on the page'
														typeValue='click'
														typeOptions={['click', 'type', 'image', 'boolean']}
														isArray={false}
														allowArray={true}
														editable={false}
														onChange={() => {}}
													/>
												</Box>
											</Box>
											<Box boxShadow='md'>
												<Box display='flex'>
													<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)'>
														<Typography level='body-xs' fontWeight='bold'>EXTRACT</Typography>
													</Box>
												</Box>
												<Box border='1px solid #E4E4E4' borderRadius='5px' sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} padding={0} overflow='hidden'>
													<ParameterInput
														value='companies'
														typeValue='object'
														typeOptions={['object', 'text', 'image', 'boolean']}
														isArray={true}
														allowArray={true}
														editable={false}
														onChange={() => {}}
													/>
													<Box display='flex' flexDirection='column' paddingLeft={1.5} bgcolor='#E4E4E4'>
														<Divider />
														<ParameterInput
															value='name'
															typeValue='text'
															typeOptions={['object', 'text', 'image', 'boolean']}
															isArray={false}
															allowArray={false}
															editable={false}
															onChange={() => {}}
														/>
														<Divider />
														<ParameterInput
															value='description'
															typeValue='text'
															typeOptions={['object', 'text', 'image', 'boolean']}
															isArray={false}
															allowArray={false}
															editable={false}
															onChange={() => {}}
														/>
														<Divider />
														<ParameterInput
															value='image_url'
															typeValue='image'
															typeOptions={['object', 'text', 'image', 'boolean']}
															isArray={false}
															allowArray={false}
															editable={false}
															onChange={() => {}}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
									<Box display='flex' justifyContent='flex-end' marginTop={2}>
										<NavLink to='/login'>
											<Button size='md' color='third' variant='shadowed' endDecorator={<ArrowRightIcon fill='currentColor' width={12} height={12} />}>Try it out</Button>
										</NavLink>
									</Box>
								</InfoContainer>
							</Box>


							<Box style={{ maxWidth: '800px', margin: '0 auto' }} boxShadow='md'>

							</Box>
						</Fade>
					</Box>
				</Section>
				<Section style={{ paddingBottom: '7rem', paddingTop: '7rem', marginTop: '2rem' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box mb={2}>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>How it works</Typography>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Everything you need to automate stuff on browsers
								</Typography>
							</Box>
						</Fade>
						<Fade>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='61.8%'>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Website Indexing</Typography>
									<Typography level='body-sm'>Plato browses the website and learns what each element is for, then learns automate it</Typography>
									<Box position='relative'>
										<LinearBackground />
										<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
											<Box zIndex={10} marginTop={2} boxShadow='md' display='flex' justifyContent='flex-start' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', marginX: '1rem', width: '85%' }} gap={2}>
												<Box width='50%' display='flex' flexDirection='column'>
													<Typography textAlign='left' width='100%' level='h4'>Indexing</Typography>
													<Box display='flex' flexDirection='column'  marginBottom={2} sx={{ maskImage: 'linear-gradient(to bottom, transparent, white 30%, white 70%, transparent)' }}>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/about</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' fontWeight='500' textColor='primary' level='body-sm'>https://acme.com/search</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/search?category=shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/item/white-tennis-shoes</Typography>
														<Typography whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' width='100%' textColor='neutral.400' level='body-sm'>https://acme.com/cart</Typography>
													</Box>

													<Box display='flex' flexDirection='column' gap={2} width='100%'>
													</Box>
													{/* <Box width='100%' height='12px' bgcolor='#fff' display='flex' justifyContent='center' position='relative' border='1px solid #9fa6ad' borderRadius='5px'>
														<Box position='absolute' left={-1} top={-1} right='39%' height='12px' bgcolor='#333' borderRadius='5px' boxShadow='0 -2px inset #000, 0 1px inset #444' />
													</Box> */}
												</Box>
												<Box sx={{ width: '50%', flexGrow: 1 }} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
													<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%' }}>
														<Box>
															<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#f7f7f7', borderRadius: '5px', overflow: 'hidden' }}>
																<Box width='100%' height='8px' bgcolor='#ababab' />
																<RotatingBorder width='50%' height='12px' bgcolor='#E4E4E4' borderRadius='12px' margin='0 auto' marginTop={2} />
																<Box flexGrow={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																	{Array.from({ length: 8 }).map((_, index) => (
																		<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																		</Box>
																	))}
																</Box>
															</Box>
														</Box>
													</AspectRatio>
													<Typography textAlign='center' width='100%' level='body-xs'>clicking the search bar...</Typography>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='50%'>
									<LogoContainer>
										<VisionIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Vision + DOM</Typography>
									<Typography level='body-sm'>Plato uses screenshots of the page plus the actual DOM to understand pages</Typography>
									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
										<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%', maxWidth: '500px' }}>
											<Box>
												<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#fff', borderRadius: '5px', overflow: 'hidden' }}>
													<Box width='100%' height='12px' bgcolor='#ababab' />
													<Box display='flex' flexDirection='row' width='100%' height='100%'>
														<Box sx={{
															width: '100%',
															border: '2px dashed #ababab',
															margin: '8px',
															position: 'relative',
															// '&::before': {
															// 	content: '""',
															// 	position: 'absolute',
															// 	top: '-2px',
															// 	left: '-3px',
															// 	width: '32px',
															// 	height: '32px',
															// 	borderTop: '4px solid #ababab',
															// 	borderLeft: '4px solid #ababab'
															// },
															// '&::after': {
															// 	content: '""',
															// 	position: 'absolute',
															// 	top: '-2px',
															// 	right: '-3px',
															// 	width: '32px',
															// 	height: '32px',
															// 	borderTop: '4px solid #ababab',
															// 	borderRight: '4px solid #ababab'
															// }
														}}>
															<Box flexGrow={1} width='100%' height='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																{Array.from({ length: 8 }).map((_, index) => (
																	<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																	</Box>
																))}
															</Box>
														</Box>
														<Box height='100%' width='39%' bgcolor='var(--joy-palette-neutral-700)' padding={2} display='flex' flexWrap='wrap' gap={0.5} alignItems='center'>
															<Box width='48px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='64px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='24px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='40px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='56px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='48px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='24px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box width='52px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='28px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='60px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='40px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='48px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='56px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='28px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='52px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
														</Box>
													</Box>
												</Box>
											</Box>
										</AspectRatio>
									</Box>
								</InfoContainer>
								{/* <InfoContainer flexBasis='38.2%'>
									<LogoContainer>
										<FlowIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Trained on a variety of flows</Typography>
									<Typography level='body-sm'>Plato is trained on a wide variety of websites and uses vision and the DOM to understand pages</Typography>
									<Box position='relative' flexGrow={1} height='200px'>
										<GradientBackground />
										<Box zIndex={10} position='absolute' top={0} left={0} width='100%' height='100%' display='flex' justifyContent='center' alignItems='center'>
											<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2} sx={{ transform: 'scale(0.5)' }}>
												<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' borderRadius='8px' padding={2} paddingY={4} sx={{ backgroundColor: '#fff', maxWidth: '250px', width: '100%' }} boxShadow='rgba(36, 42, 66, 0.08) 0px 8px 20px -6px, rgba(36, 42, 66, 0.06) 0px 1px 4px -1px, rgba(36, 42, 66, 0.06) 0px 0px 2px 0px'>
													<Typography textAlign='left' width='100%' level='title-lg' marginBottom={2}>Login</Typography>
													<Box display='flex' flexDirection='column' gap={2} width='100%'>
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
														<Button disabled sx={{ marginTop: 1 }} variant='shadowed' color='secondary' size='sm' fullWidth>Login</Button>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</InfoContainer> */}
							</Box>
							{/* <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='50%'>
									<LogoContainer>
										<VisionIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Vision + DOM</Typography>
									<Typography level='body-sm'>Plato uses screenshots of the page plus the actual DOM to understand pages</Typography>
									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
										<AspectRatio ratio='16/9' variant='plain' sx={{ width: '100%', maxWidth: '500px' }}>
											<Box>
												<Box width='100%' height='100%' display='flex' flexDirection='column' alignItems='flex-start' sx={{ backgroundColor: '#fff', borderRadius: '5px', overflow: 'hidden' }}>
													<Box width='100%' height='12px' bgcolor='#ababab' />
													<Box display='flex' flexDirection='row' width='100%' height='100%'>
														<Box sx={{
															width: '100%',
															border: '2px dashed #ababab',
															margin: '8px',
															position: 'relative',
														}}>
															<Box flexGrow={1} width='100%' height='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={1} padding={2}>
																{Array.from({ length: 8 }).map((_, index) => (
																	<Box display='flex' bgcolor='#E4E4E4' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
																	</Box>
																))}
															</Box>
														</Box>
														<Box height='100%' width='39%' bgcolor='var(--joy-palette-neutral-700)' padding={2} display='flex' flexWrap='wrap' gap={0.5} alignItems='center'>
															<Box width='48px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='64px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='24px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='40px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='56px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='48px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='24px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box display='flex' gap={.5} width='100%' marginLeft={1}>
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
																<Box flexBasis='50%' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
																<Box flexBasis='25%' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															</Box>
															<Box width='52px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='28px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='60px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='40px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='32px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='48px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='56px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='28px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
															<Box width='52px' height='6px' bgcolor='#7cacf8' borderRadius='2px' />
															<Box width='36px' height='6px' bgcolor='#fff' borderRadius='2px' />
															<Box width='44px' height='6px' bgcolor='#fe8d59' borderRadius='2px' />
														</Box>
													</Box>
												</Box>
											</Box>
										</AspectRatio>
									</Box>
								</InfoContainer>
								<InfoContainer flexBasis='50%'>
									<LogoContainer>
										<AuthIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Caching + Codegen</Typography>
									<Typography level='body-sm'>Plato caches actions it takes so it doesn&apos;t have to re-compute them</Typography>

									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' paddingY={2} paddingTop={4} position='relative'>
										<PolkadoBackground />

										<Box boxShadow='md' display='flex' flexDirection='column' alignItems='center' justifyContent='center' border='1px solid #E4E4E4' borderRadius='5px' padding={2} paddingY={2} sx={{ backgroundColor: '#fff', width: '85%', maxWidth: '570px' }} zIndex={10} position='relative'>
											<Typography textAlign='left' width='100%' level='h4' marginBottom={1}>Calls</Typography>
											<Box display='flex' flexDirection='column' gap={2} width='100%'>
												<table style={{ width: '100%', borderSpacing: '0 0.5rem', tableLayout: 'auto' }} >
													<tbody>
														<tr>
															<td style={{ display: 'flex', gap: '12px', alignItems: 'center', padding: '8px', width: 'fit-content' }}>
																<Box display='flex' alignItems='center' gap={1}>
																	<img width={18} height={18} src={dilligencevaultIcon} alt='Plato' />
																	<Box display='flex' flexDirection='column'>
																		<Typography level='title-sm'>Diligence Vault</Typography>
																		<Typography level='body-xs' textColor='neutral.400' whiteSpace='nowrap'>get diligence reports</Typography>
																	</Box>
																</Box>

															</td>
															<td style={{ padding: '8px' }}>
																<Chip size='sm' color='success' variant='soft' startDecorator={<CheckIcon width={10} height={10} fill='currentColor' />}>
																	200
																</Chip>
															</td>
															<td style={{ textAlign: 'right', padding: '8px', }}>
																<Typography level='body-xs' textColor='primary.500'>View</Typography>
															</td>
														</tr>
														<tr>
															<td style={{ display: 'flex', gap: '12px', alignItems: 'center', padding: '8px', width: 'fit-content' }}>
																<Box display='flex' alignItems='center' gap={1}>
																	<img width={18} height={18} src={'https://www.dentrix.com/favicon.ico'} alt='Plato' />
																	<Box display='flex' flexDirection='column'>
																		<Typography level='title-sm'>Dentrix</Typography>
																		<Typography level='body-xs' textColor='neutral.400' whiteSpace='nowrap'>add new patient</Typography>
																	</Box>
																</Box>
															</td>
															<td style={{ padding: '8px' }}>
																<Chip size='sm' color='success' variant='soft' startDecorator={<CheckIcon width={10} height={10} fill='currentColor' />}>
																	200
																</Chip>
															</td>
															<td style={{ textAlign: 'right', padding: '8px', }}>
																<Typography level='body-xs' textColor='primary.500'>View</Typography>
															</td>
														</tr>
														<tr>
															<td style={{ display: 'flex', gap: '12px', alignItems: 'center', padding: '8px', width: 'fit-content' }}>
																<Box display='flex' alignItems='center' gap={1}>
																	<img width={18} height={18} src={'https://shopify.com/favicon.ico'} alt='Plato' />
																	<Box display='flex' flexDirection='column'>
																		<Typography level='title-sm'>Acme Shop</Typography>
																		<Typography level='body-xs' textColor='neutral.400' whiteSpace='nowrap'>get new product listings</Typography>
																	</Box>
																</Box>

															</td>
															<td style={{ padding: '8px' }}>
																<Chip size='sm' color='success' variant='soft' startDecorator={<CheckIcon width={10} height={10} fill='currentColor' />}>
																	200
																</Chip>
															</td>
															<td style={{ textAlign: 'right', padding: '8px', }}>
																<Typography level='body-xs' textColor='primary.500'>View</Typography>
															</td>
														</tr>
														<tr>
															<td style={{ display: 'flex', gap: '12px', alignItems: 'center', padding: '8px', width: 'fit-content' }}>
																<Box display='flex' alignItems='center' gap={1}>
																	<img width={18} height={18} src={'https://amazon.com/favicon.ico'} alt='Plato' />
																	<Box display='flex' flexDirection='column'>
																		<Typography level='title-sm'>Amazon</Typography>
																		<Typography level='body-xs' textColor='neutral.400' whiteSpace='nowrap'>purchase chocolate soylent</Typography>
																	</Box>
																</Box>
															</td>
															<td style={{ padding: '8px' }}>
																<Chip size='sm' color='success' variant='soft' startDecorator={<CheckIcon width={10} height={10} fill='currentColor' />}>
																	200
																</Chip>
															</td>
															<td style={{ textAlign: 'right', padding: '8px', }}>
																<Typography level='body-xs' textColor='primary.500'>View</Typography>
															</td>
														</tr>
													</tbody>
												</table>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box> */}
						</Fade>
						<Fade delay={100}>
							<Box display='flex' justifyContent='center' width='100%' marginTop={12}>
								<iframe
									width='800'
									height='450'
									src='https://www.youtube.com/embed/BOLTCao8tUE'
									title='Plato Demo Video'
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								/>
							</Box>
						</Fade>
					</Box>
				</Section>
				{/* <Section style={{ marginTop: '6rem', backgroundImage: 'linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px)', backgroundSize: '42px 42px', position: 'relative' }}>
					<Box display='flex' flexDirection='column' zIndex={10} width='1000px' maxWidth='100%'>
						<Box mb={2}>
							<Fade>
								<Typography textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Pricing</Typography>
							</Fade>
							<Fade delay={50}>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
									Choose a plan that fits your needs.
								</Typography>
							</Fade>
						</Box>
						<PlansContainer>
							<div/>
							{plans.map((plan, index) => (
								<Fade key={plan.name} delay={50 + (75 * index)}>
									<Plan plan={plan} />
								</Fade>
							))}
							<div/>
						</PlansContainer>
					</Box>
					<RadialGradient />
				</Section> */}
			</main>
			<Footer />
		</div>
	); };

export default LandingPage;
