import React, { useState, useEffect, useContext, useRef, FormEvent } from 'react';
import { Box, Input, Typography, Button, Chip, Textarea } from '@mui/joy';
import styled from '@emotion/styled';
import LogoIcon from '@/icons/LogoIcon';
import { BuilderContext } from '../Builder/BuilderContext';
import { NavLink } from 'react-router-dom';
import API from '@/api';
import Node from '../Builder/components/Node';
import ArrowRightIcon from '@/icons/ArrowRightIcon';

const Root = styled(Box)`
  width: 460px;
  height: 100%;
  background-color: #ffff;
  border-radius: 5px;
  flex-shrink: 0;
  overflow-y: auto;
  // border-right: 1px solid #e4e4e4;
  border: 1px solid #e4e4e4;
  position: relative;
	display: flex;
	flex-direction: column;
`;

const Header = styled(Box)`
	// background-color: rgba(240, 240, 240, 0.70);
	background: var(--joy-palette-background-level1);
	backdrop-filter: blur(8px);
	border-bottom: 1px solid #e4e4e4;
  display: flex;
  height: 58px;
  align-items: center;
  gap: 8px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const SuggestionsContainer = styled(Box)`
	display: grid;
	grid-template-columns: 1fr;
	gap: 8px;
	margin-bottom: 8px;
`;

const ChatContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex-grow: 1;
`;

const SuggestionItem = styled(Box)`
	background-color: #fafafa;
	border-radius: 5px;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor);
	padding: 8px;
	border: 1px solid #e4e4e4;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 8px;
`;

const InputForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;


const WebEditorChat = () => {
	const { page, selectedWebFlow, browserSession, setSelectedWebFlow } = useContext(BuilderContext);
	const [running, setRunning] = useState(false);

	const ref = useRef(null);
	const [input, setInput] = useState('search for companies in the F24 batch with keyword "AI". then extract a list of companies with their name, description, location and logo image url');

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		console.log('Submit', browserSession?.publicId, input, selectedWebFlow.start_url);
		setRunning(true);
		await API.runWebTask(browserSession?.publicId, input, selectedWebFlow.start_url);
		setRunning(false);
	};

	const onNodeGenerated = (e: CustomEvent) => {
		console.log('node-generated', e);
		setSelectedWebFlow({ ...selectedWebFlow, nodes: [...selectedWebFlow.nodes, e.detail] });
	};

	useEffect(() => {
		window.addEventListener('node-generated', onNodeGenerated);
		return () => {
			window.removeEventListener('node-generated', onNodeGenerated);
		};
	}, [selectedWebFlow, setSelectedWebFlow]);

	console.log('selectedWebFlow', selectedWebFlow);

	return (
		<Root boxShadow='md' ref={ref}>
			<Header p={2}>
				<NavLink style={{ display: 'flex' }} to='/'>
					<LogoIcon style={{ borderRadius: '4px' }} width={24} height={24} />
				</NavLink>
				<NavLink to='/'>
					<Typography fontSize='18px' fontWeight='bold' level='title-md'>Plato</Typography>
				</NavLink>
				<Box flexGrow={1} display='flex' justifyContent='flex-end'>
					<Button variant='shadowed' color='third' size='sm'>
						Guide
					</Button>
				</Box>
			</Header>
			<Box p={2} display='flex' flexDirection='column' gap={2}>
				{selectedWebFlow && (
					<Box>
						<Box display='flex'>
							<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)'>
								<Typography level='body-xs' fontWeight='bold'>START URL</Typography>
							</Box>
						</Box>
						<Box border='1px solid #E4E4E4' borderRadius={5} sx={{ borderRadius: 0 }} overflow='hidden'>
							<Input
								size='sm'
								variant='plain'
								sx={{
									flexGrow: 1,
									paddingLeft: 0,
									borderRadius: 0,
									'&:before': {
										boxShadow: 'none',
									},
								}}
								placeholder='URL'
								value={selectedWebFlow.start_url}
								onChange={(e) => setSelectedWebFlow({ ...selectedWebFlow, start_url: e.target.value })}
								slotProps={{
									startDecorator: {
										sx: {
											margin: 0,
											paddingX: '8px',
										},
									},
								}}
								startDecorator={<Chip size='sm' variant='outlined'>URL</Chip>}
							/>
						</Box>
						<InputForm onSubmit={onSubmit}>
							<Box sx={{
								borderTopLeftRadius: 0,
								borderTopRightRadius: 0,
								borderBottomLeftRadius: 5,
								borderBottomRightRadius: 5,
								border: '1px solid #E4E4E4',
								backgroundColor: 'var(--joy-palette-background-surface)',
								display: 'flex',
								alignItems: 'flex-start',
								padding: '6px 8px',
								gap: '8px',
							}}>
								<Chip size='sm' color='primary' variant='solid'>Task</Chip>
								<Textarea
									value={input}
									size='sm'
									onChange={(e) => setInput(e.target.value)}
									required
									minRows={2}
									placeholder='What should I do?'
									sx={{
										flexGrow: 1,
										padding: 0,
										boxShadow: 'none',
										border: 0,
										borderTop: 0,
										background: 'transparent',
										'&:before': {
											boxShadow: 'none',
										},
									}}
								/>
							</Box>
							<Box display='flex' justifyContent='flex-end'>
								<Button
									type='submit'
									variant='shadowed'
									color='secondary'
									loading={running}
									endDecorator={<ArrowRightIcon width={12} height={12} fill='currentColor' />}
									size='sm'
								>Run</Button>
							</Box>
						</InputForm>
					</Box>
				)}


				{selectedWebFlow && (
					<Box display='flex' flexDirection='column' gap={1}>
						{selectedWebFlow.nodes.map((node, index) => {
							return (
								<Node
									key={node.id}
									onChange={() => {}}
									onRemove={() => {}}
									onSelected={() => {}}
									node={node}
									activeId={null}
									editable={false}
								/>
							);
						})}
					</Box>
				)}
			</Box>
		</Root>
	);
};


export default WebEditorChat;
