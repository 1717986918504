import React from 'react';

export default function CustomIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg 
			clipRule="evenodd" 
			fillRule="evenodd" 
			height="512" 
			strokeLinejoin="round" 
			strokeMiterlimit="2" 
			viewBox="0 0 24 24" 
			width="512" 
			xmlns="http://www.w3.org/2000/svg" 
			{...props}
		>
			<g id="Icon">
				<path d="m22.75 6c0-1.26-.5-2.468-1.391-3.359s-2.099-1.391-3.359-1.391h-12c-1.26 0-2.468.5-3.359 1.391s-1.391 2.099-1.391 3.359v12c0 1.26.5 2.468 1.391 3.359s2.099 1.391 3.359 1.391h12c1.26 0 2.468-.5 3.359-1.391s1.391-2.099 1.391-3.359zm-10.262 2.163-2.4 7.2c-.13.393.082.818.475.949.393.13.818-.082.949-.475l2.4-7.2c.13-.393-.082-.818-.475-.949-.393-.13-.818.082-.949.475zm2.582 1.967 1.869 1.87s-1.869 1.87-1.869 1.87c-.293.292-.293.768 0 1.06.292.293.768.293 1.06 0l2.4-2.4c.293-.293.293-.767 0-1.06l-2.4-2.4c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06zm-7.2-1.06-2.4 2.4c-.293.293-.293.767 0 1.06l2.4 2.4c.292.293.768.293 1.06 0 .293-.292.293-.768 0-1.06l-1.869-1.87s1.869-1.87 1.869-1.87c.293-.292.293-.768 0-1.06-.292-.293-.768-.293-1.06 0z"/>
			</g>
		</svg>
	);
}
