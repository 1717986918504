import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Input, Select, Chip, IconButton, Tooltip, Checkbox, ListItem, Option, CircularProgress, Typography } from '@mui/joy';
import SelectObjectIcon from '@/icons/SelectObjectIcon';
import FilterIcon from '@/icons/FilterIcon';
import CloseIcon from '@/icons/CloseIcon';
import CubeIcon from '@/icons/CubeIcon';
import TextIcon from '@/icons/TextIcon';
import ImageIcon from '@/icons/ImageIcon';
import CursorClickIcon from '@/icons/CursorClickIcon';
import CursorTypingIcon from '@/icons/CursorTypingIcon';
import PlayIcon from '@/icons/PlayIcon';
import SparkleIcon from '@/icons/SparkleIcon';
import WebIcon from '@/icons/WebIcon';
import styled from '@emotion/styled';
import { BuilderContext } from '../BuilderContext';
import moment from 'moment';

const Key = styled(Box)`
	padding: 2px 4px;
	border: 1px solid #e4e4e4;
	border-radius: 4px;
	box-shadow: 0 -1px inset #e4e4e4;
	color: var(--joy-palette-text-primary);
	background: white;
`;


const PARAMETER_TYPES = {
	act: {
		label: 'Act',
		color: 'secondary',
		placeholder: 'What to do?',
		variant: 'solid',
		icon: <SparkleIcon fill='currentColor' width={12} height={12} />,
	},
	extract: {
		label: 'Extract',
		color: 'secondary',
		variant: 'solid',
		icon: <SparkleIcon fill='currentColor' width={12} height={12} />,
	},
	navigate: {
		label: 'Navigate',
		color: 'primary',
		placeholder: 'URL',
		icon: <WebIcon fill='currentColor' width={12} height={12} />,
	},
	object: {
		label: 'Object',
		color: 'success',
		icon: <CubeIcon fill='currentColor' width={12} height={12} />,
	},
	text: {
		label: 'Text',
		color: 'neutral',
		icon: <TextIcon fill='currentColor' width={12} height={12} />,
	},
	image: {
		label: 'Image',
		color: 'danger',
		icon: <ImageIcon fill='currentColor' width={12} height={12} />,
	},
	click: {
		label: 'Click',
		color: 'primary',
		icon: <CursorClickIcon fill='currentColor' width={12} height={12} />,
	},
	input: {
		label: 'Input',
		color: 'warning',
		icon: <CursorTypingIcon fill='currentColor' width={12} height={12} />,
	},
	pagination: {
		label: 'Pagination',
		color: 'secondary',
		placeholder: 'Give instructions',
		icon: <SparkleIcon fill='currentColor' width={12} height={12} />,
	},
	// number: {
	// 	label: 'Number',
	// 	color: 'primary',
	// },
	// boolean: {
	// 	label: 'Boolean',
	// 	color: 'warning',
	// },
};

interface ParameterInputProps {
	parameter: any;
	onChange: (value: any) => void;
	onRemove: () => void;
	onAddFilter?: () => void;
	onSelectElement?: () => void;
	onRun?: () => void;
	isActive?: boolean;
	isRunning?:	boolean;
	isRootAction?: boolean;
}

const ParameterInput = (props: ParameterInputProps) => {
	const { parameter, onChange, onRemove, onAddFilter, onSelectElement, isActive, onRun, isRunning, isRootAction } = props;
	const { isWebFlowRunning } = useContext(BuilderContext);

	const [runStartedAt, setRunStartedAt] = useState(null);
	const [runCompletedAt, setRunCompletedAt] = useState(null);

	useEffect(() => {
		if (isRunning) {
			setRunStartedAt(Date.now());
		} else if (runStartedAt) {
			setRunCompletedAt(Date.now());
		}
	}, [isRunning]);


	const renderedValue = useMemo(() => {
		if (parameter.type === 'input') {
			const words = parameter.name.split(' ');
			console.log('value', parameter.name, words);
			// render the words
			// but if the word starts with [ and ends with ], then render it in a box with a border
			return (
				<Typography level='body-sm' whiteSpace='nowrap'>
					{words.map((word, index) => {
						if (word.startsWith('[') && word.endsWith(']')) {
							return <Key component='span' key={word}>
								{word.slice(1, -1)}{index === words.length - 1 ? '' : ' '}
							</Key>;
						}
						return <Typography sx={{ color: 'var(--joy-palette-text-primary)' }} level='body-sm' key={word}>{word}{index === words.length - 1 ? '' : ' '}</Typography>;
					})}
				</Typography>
			);
		}
		return <Typography whiteSpace='nowrap' sx={{ color: 'var(--joy-palette-text-primary)' }} level='body-sm'>{parameter.name}</Typography>;
	}, [parameter.name, parameter.type]);

	return (
		<Box display='flex' alignItems='center'>
			<Input
				size='sm'
				variant='plain'
				sx={{
					flexGrow: 1,
					paddingLeft: 0,
					borderRadius: 0,
					'&:before': {
						boxShadow: 'none',
					},
				}}
				placeholder={PARAMETER_TYPES[parameter.type].placeholder || 'name'}
				value={parameter.name}
				onChange={(e) => onChange({ name: e.target.value })}
				required
				slots={!isActive && parameter.name.length > 0 ? {
					input: (props) => {
						return (
							<Typography sx={{ color: 'var(--joy-palette-text-primary)' }} flexGrow={1} display='flex' alignItems='center' level='body-sm'>{renderedValue}</Typography>
						);
					}
				} : {}}
				slotProps={{
					startDecorator: {
						sx: {
							margin: 0,
						},
					},
				}}
				startDecorator={
					<>
						<Select
							sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, overflow: 'hidden' }}
							value={parameter.type}
							size='sm'
							variant='plain'
							color='neutral'
							disabled={isRootAction}
							onChange={(e, newValue) => onChange({ type: newValue })}
							indicator={null}
							renderValue={({ value }) => {
								return <Chip startDecorator={PARAMETER_TYPES[value].icon ? PARAMETER_TYPES[value].icon : null} size='sm' variant={PARAMETER_TYPES[value].variant || 'soft'} color={PARAMETER_TYPES[value].color}>{PARAMETER_TYPES[value].label}{parameter.isArray && ' []'}</Chip>; }}
						>
							<ListItem key='array'>
								<Checkbox label='Is Array' size='sm' checked={parameter.isArray} onChange={(e) => onChange({ isArray: e.target.checked })} />
							</ListItem>
							{Object.keys(PARAMETER_TYPES).map((type) => (
								<Option value={type} key={type}>
									<Chip startDecorator={PARAMETER_TYPES[type].icon ? PARAMETER_TYPES[type].icon : null} size='sm' variant={PARAMETER_TYPES[type].variant || 'soft'} color={PARAMETER_TYPES[type].color}>{PARAMETER_TYPES[type].label}</Chip>
								</Option>
							))}
						</Select>
					</>
				}
				endDecorator={
					(isActive && !isRunning) ? (
						<>
							{onRun && (
								<Tooltip size='sm' title='Run Action'>
									<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRun}>
										<PlayIcon width={12} height={12} />
									</IconButton>
								</Tooltip>
							)}
							{!['act', 'navigate', 'input', 'extract'].includes(parameter.type) && (
								<>
									{onSelectElement && (
										<Tooltip size='sm' title='Select Element'>
											<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onSelectElement}>
												<SelectObjectIcon />
											</IconButton>
										</Tooltip>
									)}
									{onAddFilter && (
										<Tooltip size='sm' title='Filter'>
											<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onAddFilter}>
												<FilterIcon />
											</IconButton>
										</Tooltip>
									)}
								</>
							)}
							{!isRootAction && (
								<Tooltip size='sm' title='Remove'>
									<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRemove}>
										<CloseIcon width={10} height={10} />
									</IconButton>
								</Tooltip>
							)}
						</>
					) : (
						<>
							{isRunning && (
								<CircularProgress size='sm' />
							)}
							{!isRunning && runCompletedAt && (
								<Typography level='body-sm'>{moment.duration(runCompletedAt - runStartedAt).asSeconds().toFixed(1)}s</Typography>
							)}
						</>
					)
				}
			>
				{renderedValue}
			</Input>
		</Box>
	);
};

export default ParameterInput;
