import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, Breadcrumbs, Button, Chip, Divider, FormLabel, IconButton, Input, Link, Select, TabPanel, Tabs, Tooltip, Typography } from '@mui/joy';
import API from '@/api';
import Events from '../Events';
import { BuilderContext, Pages, SELECT_MODE } from '../BuilderContext';
import PlayIcon from '@/icons/PlayIcon';
import SaveIcon from '@/icons/SaveIcon';
import CloseIcon from '@/icons/CloseIcon';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Node from '@/pages/Builder/components/Node';
import { v4 as uuidv4 } from 'uuid';
import ParameterInput from '../components/ParameterInputV2';

interface WebFlowProps {
	webFlow: any;
}

const DEFAULT_WEB_FLOW = {
	name: 'New Web Flow',
	description: '',
	nodes: [],
	parameters: [],
	icon_url: null,
};

export const WebFlow = ({ webFlow: initialWebFlow }: WebFlowProps) => {
	const {
		activeAction,
		setActiveAction,
		frameUrl,
		runningNode,
		setRunningNode,
		setPage,
		browserSession,
		iconUrl,
		isWebFlowRunning,
		setIsWebFlowRunning,
	} = useContext(BuilderContext);

	const [webFlow, setWebFlow] = useState({ ...(initialWebFlow || DEFAULT_WEB_FLOW) });
	const [runResult, setRunResult] = useState(null);
	const [runLog, setRunLog] = useState([]);
	const [navigateLoading, setNavigateLoading] = useState(false);
	const runningNodeRef = useRef(runningNode);


	useEffect(() => {
		console.log('frameUrl, iconurl', { frameUrl, iconUrl, created_at: webFlow.created_at });
		if (!webFlow.created_at) {
			setWebFlow({ ...webFlow, start_url: frameUrl, icon_url: iconUrl });
		}
	}, [frameUrl, iconUrl]);

	function handleNodeStart(event: CustomEvent) {
		const { nodeId } = event.detail;
		runningNodeRef.current = webFlow.nodes.find(a => a.id === nodeId);
		setRunningNode(runningNodeRef.current);
	}

	function handleNodeEnd(event: CustomEvent) {
		if (runningNodeRef.current?.id === event.detail.nodeId) {
			runningNodeRef.current = null;
			setRunningNode(null);
		}
		setRunLog([...runLog, { ...event.detail, id: Date.now() }]);
	}

	function handleNodeUpdate(event: CustomEvent) {
		// console.log('action updated', event.detail);
	}

	useEffect(() => {

		window.addEventListener('node-start', handleNodeStart);
		window.addEventListener('node-end', handleNodeEnd);
		window.addEventListener('node-update', handleNodeUpdate);

		return () => {
			window.removeEventListener('node-start', handleNodeStart);
			window.removeEventListener('node-end', handleNodeEnd);
			window.removeEventListener('node-update', handleNodeUpdate);
		};
	}, [runningNode, setRunningNode]);


	useEffect(() => {
		function handleActionCreated(event) {
			const { action } = event.detail;
			if (activeAction) {
				window.dispatchEvent(new CustomEvent(Events.SUB_ACTION_ADDED, { detail: { action } }));
			} else {
				setWebFlow({ ...webFlow, nodes: [...webFlow.nodes, action] });
				if (action?.type === 'object' || !activeAction) {
					setActiveAction(action);
				}
			}
		}

		window.addEventListener(Events.ON_CREATE_ACTION, handleActionCreated);

		return () => {
			window.removeEventListener(Events.ON_CREATE_ACTION, handleActionCreated);
		};
	}, [webFlow.nodes, activeAction, webFlow]);


	const onSave = async (e) => {
		e.preventDefault();

		setIsWebFlowRunning(true);
		console.log('saving', webFlow);
		try {
			const updatedWebFlow = await API.createWebFlow(webFlow);
			setWebFlow(updatedWebFlow);
		} catch (error) {
			console.warn(error);
		}
		setIsWebFlowRunning(false);
	};

	const onTestRun = async () => {
		setIsWebFlowRunning(true);
		try {
			const updatedWebFlow = await API.createWebFlow(webFlow);
			setWebFlow(updatedWebFlow);
			// const webFlowRun = await API.runWebFlowOnBrowserSession(updatedWebFlow.id, browserSession.publicId, {}, {});
			// console.log(webFlowRun);
		} catch (error) {
			console.warn(error);
		}
		setIsWebFlowRunning(false);
	};

	const onNavigate = async () => {
		try {
			setNavigateLoading(true);
			await API.navigateBrowserSession(browserSession.publicId, webFlow.start_url);
		} catch (error) {
			console.warn(error);
		}
		setNavigateLoading(false);
	};

	const onRemoveAction = (id) => {
		setWebFlow({ ...webFlow, nodes: webFlow.nodes.filter((a) => a.id !== id) });
		if (activeAction?.id === id) {
			setActiveAction(null);
		}
	};

	const onActionUpdate = (id, update) => {
		setWebFlow({ ...webFlow, nodes: webFlow.nodes.map((a) => a.id === id ? { ...a, ...update } : a) });
	};

	const onAddAct = () => {
		const newAction = {
			id: uuidv4(),
			type: 'act',
			actionType: 'click',
			description: '',
			elementHint: null,
			subNodes: [],
		};
		setWebFlow({ ...webFlow, nodes: [...webFlow.nodes, newAction] });
		setActiveAction(newAction);
	};

	const onAddExtract = () => {
		const newAction = {
			id: uuidv4(),
			type: 'extract',
			schema: {
				id: uuidv4(),
				name: '',
				type: 'object',
				isArray: false,
				subParameters: [],
			},
			subNodes: [],
		};
		setWebFlow({ ...webFlow, nodes: [...webFlow.nodes, newAction] });
		setActiveAction(newAction);
	};

	return (
		<Box component='form' flexGrow={1} display='flex' flexDirection='column' onSubmit={onSave}>
			<Box display='flex' justifyContent='space-between' alignItems='center' padding='0.75rem'>
				<Breadcrumbs sx={{ padding: 0 }}>
					<Link onClick={() => setPage(Pages.HOME)}>
						<Typography level='body-sm'>Flows</Typography>
					</Link>
					<Box>
						<Input
							variant='plain'
							size='sm'
							placeholder='New Web Flow'
							value={webFlow.name}
							startDecorator={<img src={webFlow.icon_url} width={14} height={14} />}
							onChange={(e) => setWebFlow({ ...webFlow, name: e.target.value })}
							sx={{
								fontSize: 'var(--Typography-fontSize, var(--joy-fontSize-sm, 0.875rem))',
								minHeight: '0',
								height: '23px',
								top: '1px',
								'--Input-radius': '0px',
								background: 'transparent',
								borderBottom: '2px solid',
								borderColor: 'neutral.outlinedBorder',
								padding: 0,
								'&:hover': {
									borderColor: 'neutral.outlinedHoverBorder',
								},
								'&::before': {
									border: '1px solid var(--Input-focusedHighlight)',
									transform: 'scaleX(0)',
									left: 0,
									right: 0,
									bottom: '-2px',
									top: 'unset',
									transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
									borderRadius: 0,
								},
								'&:focus-within::before': {
									transform: 'scaleX(1)',
								},
							}}
						/>
					</Box>
				</Breadcrumbs>
				<Box display='flex' gap={1}>
					<IconButton type='submit' disabled={isWebFlowRunning} variant='shadowed' color='secondary' size='sm'>
						<SaveIcon fill='currentColor' width={12} height={12} />
					</IconButton>
					<IconButton loading={isWebFlowRunning} variant='shadowed' color='third' size='sm' onClick={onTestRun}>
						<PlayIcon fill='currentColor' width={12} height={12} />
					</IconButton>
				</Box>
			</Box>
			<Box p='0.75rem' pt='0.25rem' height='100%' display='flex' flexDirection='column' gap={1} onClick={() => { setActiveAction(null); }}>
				<Box height='100%' display='flex' flexDirection='column' gap={1}>
					<Box display='flex' flexDirection='column' gap={1}>
						<Box>
							<Box display='flex'>
								<Box width='100%' display='flex' height='32px' alignItems='center' justifyContent='space-between' border='1px solid #E4E4E4' borderBottom={0} borderRadius={5} paddingX={1} paddingY={0} sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} bgcolor='var(--joy-palette-background-level1)'>
									<Typography level='body-xs' fontWeight='bold'>NAVIGATE</Typography>
									<Box marginLeft={1} display='flex' alignItems='center'>
										<Tooltip size='sm' title='Run'>
											<IconButton loading={navigateLoading || (isWebFlowRunning && !runningNode)} size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onNavigate}>
												<PlayIcon fill='currentColor' width={10} height={10} />
											</IconButton>
										</Tooltip>
									</Box>
								</Box>
							</Box>
							<Box border='1px solid #E4E4E4' borderRadius={5} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} overflow='hidden'>
								<Input
									size='sm'
									variant='plain'
									sx={{
										flexGrow: 1,
										paddingLeft: 0,
										borderRadius: 0,
										'&:before': {
											boxShadow: 'none',
										},
									}}
									placeholder='URL'
									value={webFlow.start_url}
									onChange={(e) => setWebFlow({ ...webFlow, start_url: e.target.value })}
									slotProps={{
										startDecorator: {
											sx: {
												margin: 0,
												paddingX: '8px',
											},
										},
									}}
									startDecorator={<Chip size='sm' variant='outlined'>URL</Chip>}
								/>
								{/*
								<Divider />
								<Input
									size='sm'
									variant='plain'
									sx={{
										flexGrow: 1,
										paddingLeft: 0,
										borderRadius: 0,
										'&:before': {
											boxShadow: 'none',
										},
									}}
									placeholder='Pagination instructions'
									value={webFlow.repeat_instruction}
									onChange={(e) => setWebFlow({ ...webFlow, repeat_instruction: e.target.value })}
									slotProps={{
										startDecorator: {
											sx: {
												margin: 0,
												paddingX: '8px',
											},
										},
									}}
									startDecorator={<Chip size='sm' variant='outlined'>Pagination</Chip>}
								/> */}

							</Box>
						</Box>


						{webFlow.nodes.map((node, index) => {
							return (
								<Node
									key={node.id}
									onChange={(update) => onActionUpdate(node.id, update)}
									onRemove={() => onRemoveAction(node.id)}
									onSelected={(node) => setActiveAction(node)}
									node={node}
									activeId={null}
								/>
							);
						})}
						<Box display='flex' gap={1} marginTop={1}>
							<Button sx={{ flexGrow: 1, flexShrink: 0 }} size='sm' variant='shadowed' color='third' onClick={onAddAct}>Act</Button>
							<Button sx={{ flexGrow: 1, flexShrink: 0 }} size='sm' variant='shadowed' color='third' onClick={onAddExtract}>Extract</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			{runResult && (
				<Box padding='0.75rem'>
					<Box p={1} border='1px solid #E4E4E4' borderRadius={5} overflow='hidden'>
						<Box display='flex' justifyContent='space-between' alignItems='center'>
							<Typography level='title-sm'>{runResult.state}</Typography>
							<IconButton size='sm' variant='soft' color='neutral' onClick={() => setRunResult(null)}>
								<CloseIcon width={10} height={10} />
							</IconButton>
						</Box>
						<SyntaxHighlighter
							language='json'
							style={a11yLight}
							customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
							showLineNumbers={false}
							wrapLongLines
						>
							{JSON.stringify(runResult.response, null, 2)}
						</SyntaxHighlighter>
					</Box>
				</Box>
			)}
			{/* {runLog.map((log, index) => (
				<Box key={log.id} padding='0.75rem'>
					<Box p={1} border='1px solid #E4E4E4' borderRadius={5} overflow='hidden'>
						<Box display='flex' justifyContent='space-between' alignItems='center'>
							<Typography level='title-sm'>{log.state}</Typography>
							<IconButton size='sm' variant='soft' color='neutral' onClick={() => setRunLog(runLog.filter((rl) => rl.id !== log.id))}>
								<CloseIcon width={10} height={10} />
							</IconButton>
						</Box>
						<SyntaxHighlighter
							language='json'
							style={a11yLight}
							customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
							showLineNumbers={false}
							wrapLongLines
						>
							{JSON.stringify(log.response, null, 2)}
						</SyntaxHighlighter>
					</Box>
				</Box>
			))} */}
		</Box>
	);
};


export default WebFlow;
