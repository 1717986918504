import React, { useContext, useEffect, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup } from '@mui/joy';
import { Paper } from '@mui/material';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? '#f0f0f0' : 'transparent'};
`;

const UsagePage: React.FC = () => {
	const [usage, setUsage] = useState([]);
	const [selectedUsage, setSelectedUsage] = useState(null);
	const [view, setView] = useState('response');

	useEffect(() => {
		API.getUsage().then((data) => {
			console.log(data);
			setUsage(data);
			setSelectedUsage(data[0]);
		});
	}, []);

	useEffect(() => {
		if (selectedUsage) {
			setView('response');
		}
	}, [selectedUsage]);

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Usage</Typography>
			<Grid container spacing={2}>
				<Grid xs={12} md={7}>
					<Paper style={{ padding: 0 }}>
						<table>
							<thead>
								<tr>
									<th><Typography level='title-sm' color='neutral'>Source</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Status</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Method</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Time</Typography></th>
								</tr>
							</thead>
							<tbody>
								{usage.map((u) => (
									<UsageRow active={selectedUsage?.id === u.id} key={u.id} onClick={() => setSelectedUsage(u)}>
										<td>
											<Box display='flex' alignItems='center' gap={1}>
												<img width={16} src={u.integration_source_method?.integration_source?.icon_url} alt={u.integration_source_method?.integration_source?.name} />
												<Typography level='title-sm'>{u.integration_source_method?.integration_source?.name}</Typography>
											</Box>
										</td>
										<td>
											<Chip size='sm' color={u.status_code === 200 ? 'primary' : 'danger'}>{u.status_code}</Chip>
										</td>
										<td>
											<Typography level='body-sm'>{u.integration_source_method?.name}</Typography>
										</td>
										<td>
											<Typography level='body-sm'>{moment(u.created_at).fromNow()}</Typography>
										</td>
									</UsageRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={5}>
					<Paper style={{ padding: 0 }}>
						<Section>
							<Typography level='title-md'>{selectedUsage?.publicId}</Typography>
						</Section>
						<Divider/>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Source</Typography>
								<Box display='flex' alignItems='center' gap={0.5}>
									<img width={16} src={selectedUsage?.integration_source_method?.integration_source?.icon_url} alt={selectedUsage?.integration_source_method?.integration_source?.name} />
									<Typography level='title-sm'>{selectedUsage?.integration_source_method?.integration_source?.name}</Typography>
								</Box>
								<Typography color='neutral' level='title-sm'>Method</Typography>
								<Typography level='body-sm'>{selectedUsage?.integration_source_method?.name}</Typography>
								<Typography color='neutral' level='title-sm'>Status</Typography>
								<Chip size='sm' color={selectedUsage?.status_code === 200 ? 'primary' : 'danger'}>{selectedUsage?.status_code}</Chip>
								<Typography color='neutral' level='title-sm'>Time</Typography>
								<Typography level='body-sm'>{moment(selectedUsage?.created_at).fromNow()}</Typography>
							</Box>
						</Section>
						<Divider/>
						<Section>
							<Box display='flex' gap={1} marginBottom={1}>
								<ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='response' size='sm' color={view === 'response' ? 'secondary' : 'neutral'} variant={view === 'response' ? 'solid' : 'outlined'}>Response</Button>
									<Button value='request' size='sm' color={view === 'request' ? 'secondary' : 'neutral'} variant={view === 'request' ? 'solid' : 'outlined'}>Request</Button>
								</ToggleButtonGroup>
							</Box>
							{view === 'response' ? (
								<>
									<Typography level='title-md'>Response body</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedUsage?.response, null, 2)}
									</SyntaxHighlighter>
								</>
							) : (
								<>
									<Typography level='title-md'>Request parameters</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedUsage?.data || {}, null, 2)}
									</SyntaxHighlighter>
								</>
							)}
						</Section>
					</Paper>
				</Grid>
			</Grid>
		</DashboardContainer>
	);
};

export default UsagePage;
