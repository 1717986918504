import React, { useContext, useEffect, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup, CircularProgress, AspectRatio } from '@mui/joy';
import { Paper } from '@mui/material';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';
import BuilderIcon from '../icons/BuilderIcon';
import Node from './Builder/components/Node';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const formatDuration = (duration: moment.Duration) => {
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s` : ''}`;
};

const SessionsPage: React.FC = () => {
	const [sessions, setSessions] = useState([]);
	const [selectedSession, setSelectedSession] = useState(null);
	const [view, setView] = useState('response');
	const [logs, setLogs] = useState([]);
	const [logsLoading, setLogsLoading] = useState(false);

	useEffect(() => {
		API.getBrowserSessions().then((data) => {
			console.log(data);
			setSessions(data.sessions);
			setSelectedSession(data.sessions[0]);
		});

	}, []);

	useEffect(() => {
		setLogs([]);
		console.log({ selectedSession });
		if (selectedSession) {
			setView('response');
			setLogsLoading(true);
			API.getBrowserSessionLogs(selectedSession.publicId).then((data) => {
				setLogs(data.logs);
				console.log(data.logs);
				setLogsLoading(false);
			});
		}
	}, [selectedSession]);

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Browser Sessions</Typography>
			<Grid container spacing={2}>
				<Grid xs={12} md={6}>
					<Paper style={{ padding: 0 }}>
						<table>
							<thead>
								<tr>
									<th><Typography level='title-sm' color='neutral'>Type</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Status</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Started At</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Duration</Typography></th>
								</tr>
							</thead>
							<tbody>
								{sessions.map((s) => (
									<UsageRow active={selectedSession?.id === s.id} key={s.id} onClick={() => setSelectedSession(s)}>
										{/* <td>
											<Box display='flex' alignItems='center' gap={1}>
												<img width={16} src={s.integration_source_method?.integration_source?.icon_url} alt={s.integration_source_method?.integration_source?.name} />
												<Typography level='title-sm'>{s.integration_source_method?.integration_source?.name}</Typography>
											</Box>
										</td> */}
										<td>
											{s.isBuilderSession ? (
												<Box display='flex' alignItems='center' gap={1}>
													<Typography level='body-md' fontWeight={500} display='flex' alignItems='center' gap={1}>
														<BuilderIcon width={12} height={12} fill='currentColor' />
														Builder
													</Typography>
												</Box>
											) : (
												<Box display='flex' alignItems='center' gap={1}>
													<Typography level='body-md' fontWeight={500} display='flex' alignItems='center' gap={1}>
														Worker
													</Typography>
												</Box>
											)}
										</td>
										<td>
											<Chip size='sm' variant='soft' color={s.isActive ? 'primary' : 'danger'}>{s.isActive ? 'Active' : 'Ended'}</Chip>
										</td>
										<td>
											<Typography level='body-sm'>{moment(s.activeAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
										</td>
										<td>
											{s.endedAt ? (
												<Typography level='body-sm'>{formatDuration(moment.duration(moment(s.endedAt).diff(moment(s.activeAt))))}</Typography>
											) : (
												<Typography level='body-sm'>-</Typography>
											)}
										</td>
									</UsageRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={6}>
					<Paper style={{ padding: 0 }}>
						<Section>
							<Typography level='title-md'>{selectedSession?.publicId}</Typography>
						</Section>
						<Divider/>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Type</Typography>
								<Typography level='body-sm'>{selectedSession?.isBuilderSession ? 'Builder' : 'Worker'}</Typography>
								<Typography color='neutral' level='title-sm'>Status</Typography>
								<Chip size='sm' variant='soft' color={selectedSession?.isActive ? 'primary' : 'danger'}>{selectedSession?.isActive ? 'Active' : 'Ended'}</Chip>
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Started At</Typography>
								{selectedSession?.activeAt ? (
									<Typography level='body-sm'>{moment(selectedSession?.activeAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Ended At</Typography>
								{selectedSession?.endedAt ? (
									<Typography level='body-sm'>{moment(selectedSession?.endedAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm'>Duration</Typography>
								{selectedSession?.activeAt && selectedSession?.endedAt ? (
									<Typography level='body-sm'>{formatDuration(moment.duration(moment(selectedSession?.endedAt).diff(moment(selectedSession?.activeAt))))}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
							</Box>
						</Section>
						<Divider/>
						<Box>
							<Typography level='title-md' padding={2}>Logs</Typography>
							<Box display='flex' flexDirection='column' gap={2}>
								{logsLoading ? (
									<Box display='flex' justifyContent='center' marginY={2}>
										<CircularProgress size='md' color='secondary'/>
									</Box>
								) : (
									logs.map((l) => (
										<Box key={l.id} display='flex' flexDirection='column' bgcolor='white' borderRadius={5}>
											<Box padding={2} display='flex' flexDirection='column'>
												<Typography level='title-md' fontWeight={500} marginBottom={1}>
													{selectedSession?.activeAt ? (
														(() => {
															const duration = moment.duration(moment(l.timestamp).diff(moment(selectedSession.activeAt)));
															const hours = Math.floor(duration.asHours());
															const minutes = Math.floor(duration.asMinutes() % 60);
															const seconds = Math.floor(duration.asSeconds() % 60);
															return hours > 0 ?
																`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` :
																`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
														})()
													) : moment(l.timestamp).format('YYYY-MM-DD HH:mm:ss')}
												</Typography>

												{l.node ? (
													<Box>
														<Node node={l.node} onChange={() => {}} onRemove={() => {}} activeId={null} onSelected={() => {}} isRoot editable={false} />
													</Box>
												) : (
													<SyntaxHighlighter
														language='json'
														style={a11yLight}
														customStyle={{ fontSize: '12px', padding: '8px', margin: 0, background: 'white', borderRadius: 5, border: '1px solid #e4e4e4', maxHeight: '300px' }}
														wrapLongLines
													>
														{JSON.stringify(l.log, null, 2)}
													</SyntaxHighlighter>
												)}
											</Box>
											{l.screenshot && (
												<AspectRatio ratio='16 / 9' sx={{ '& > div': { 'overflow-y': 'auto' } }}>
													<Box sx={{ height: 'fit-content !important' }}>
														<img width='100%' src={l.screenshot} alt='screenshot' />
													</Box>
												</AspectRatio>
											)}
										</Box>
									))
								)}
							</Box>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</DashboardContainer>
	);
};

export default SessionsPage;
