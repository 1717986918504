import React from 'react';

export default function BuilderIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg version='1.1' id='fi_497457' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512.006 512.006'xmlSpace='preserve' {...props}>
			<g>
				<g>
					<g>
						<path d='M508.885,205.784l-42.667-42.667c-2.005-2.005-4.715-3.115-7.552-3.115H448v-10.667c0-2.837-1.131-5.547-3.115-7.552
				l-85.333-85.333C342.443,39.342,313.771,10.67,256,10.67c-57.728,0-84.203,26.475-103.552,45.781
				c-3.051,3.051-3.968,7.637-2.304,11.627c1.643,3.989,5.547,6.592,9.856,6.592c86.037,0,96,36.523,96,64
				c0,22.827-12.928,40.213-21.184,49.067l69.696,69.696c18.859-12.757,45.653-27.819,58.155-26.709v14.613
				c0,2.837,1.131,5.547,3.115,7.552l53.333,53.333c2.005,1.984,4.715,3.115,7.552,3.115h0.363c2.965-0.085,5.739-1.408,7.659-3.627
				l74.667-85.333C513.067,216.131,512.853,209.774,508.885,205.784z'></path>
						<path d='M16.128,406.126C5.739,416.515,0,430.339,0,446.296c0,30.357,24.683,55.04,55.04,55.04
				c15.467,0,30.315-6.571,40.725-18.005L288.363,271.47l-68.779-68.8L16.128,406.126z'></path>
					</g>
				</g>
			</g>
		</svg>
	);
}
