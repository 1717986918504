import ButtonPrimary, { ButtonSecondary } from '@/components/Button';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import PlusIcon from '@/icons/PlusIcon';
import MinusIcon from '@/icons/MinusIcon';
import { Box, IconButton, Typography } from '@mui/joy';
import { Collapse } from '@mui/material';

interface FAQ {
  question: string;
  answer: React.ReactNode;
}

const FaqsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;
  width: 600px;
`;

const FaqContainer = styled(Box)`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
  background-color: rgba(240, 240, 240, 0.70);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
  box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor)
`;

const FaqTextContainer = styled.div`
  flex-grow: 1;
`;

const ExpandButton = styled(IconButton)`
`;


const FAQItem = ({ faq }: { faq: FAQ }) => {
	const [open, setOpen] = useState(false);

	return (
		<FaqContainer>
			<FaqTextContainer>
				<Box height='32px' display='flex' alignItems='center'>
					<Typography level='title-lg'>{faq.question}</Typography>
				</Box>
				<Collapse in={open}>
					<Typography level='body-md' mt={1}>{faq.answer}</Typography>
				</Collapse>
			</FaqTextContainer>
			<ExpandButton size='sm' variant='shadowed' color='secondary' onClick={() => setOpen(!open)}>
				{open ? <MinusIcon fill='currentColor' width={12} height={12} /> : <PlusIcon fill='currentColor' width={12} height={12} />}
			</ExpandButton>
		</FaqContainer>
	);
};


const PricingFAQs = () => {

	const faqs: FAQ[] = [
		{
			question: 'What is the best plan for me?',
			answer: 'Try out the Free plan first and upgrade if you need more requests.'
		},
		{
			question: 'How do you process payments?',
			answer: 'We use Stripe to process payments. We do not store any credit card information on our servers.',
		},
	];


	return (
		<FaqsContainer>
			{faqs.map((faq, index) => (
				<FAQItem key={index} faq={faq} />
			))}
		</FaqsContainer>
	);
};

export default PricingFAQs;
