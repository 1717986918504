import { Box, Input, Divider, Button, Select, Option, Chip, Typography, IconButton, Tooltip, Switch, CircularProgress, Dropdown, MenuButton, Menu, MenuItem } from '@mui/joy';
import React, { useContext, useEffect, useState } from 'react';
import ParameterInput from './ParameterInputV2';
import Events from '../Events';
import API from '@/api';
import { BuilderContext } from '../BuilderContext';
import PlusIcon from '@/icons/PlusIcon';
import { v4 as uuidv4 } from 'uuid';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CloseIcon from '@/icons/CloseIcon';
import SelectObjectIcon from '@/icons/SelectObjectIcon';
import PlayIcon from '@/icons/PlayIcon';
import styled from '@emotion/styled';


const ActionHeader = styled(Box)<{ isRoot: boolean }>`
	background-color: var(--joy-palette-background-level1);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	padding: 0 8px;
	padding-right: 8px;
	height: 32px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #E4E4E4;
	border-bottom: 0;
	${({ isRoot }) => !isRoot && `
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border: 0;
		border-top: 1px solid #E4E4E4;
	`}
`;

interface NodeProps {
	node: any;
	onChange: (node: any) => void;
	onRemove: () => void;
	activeId: string;
	onSelected: (node: any) => void;
	isRoot?: boolean;
	editable?: boolean;
}

interface ExtractSchemaProps {
	schema: any;
	onChange: (schema: any) => void;
	onRemove: () => void;
	selectingElementReceiverId: string | null;
	editable?: boolean;
}

const ExtractSchema = ({ schema, onChange, onRemove, selectingElementReceiverId, editable = true }: ExtractSchemaProps) => {
	// const { setSelectingElementReceiverId } = useContext(BuilderContext);
	// const onAddSubField = () => {
	// 	const newSubField = {
	// 		id: uuidv4(),
	// 		name: '',
	// 		type: 'text',
	// 		isArray: false,
	// 		subParameters: [],
	// 	};
	// 	onChange({ ...schema, subParameters: [...schema.subParameters, newSubField] });
	// };

	// const isSelectingElementReceiver = selectingElementReceiverId === schema.id;

	// function handleElementSelected(event) {
	// 	console.log('element selected', event);
	// 	const { element } = event.detail;
	// 	onChange({ ...schema, elementHint: element });
	// 	setSelectingElementReceiverId(null);
	// }

	// useEffect(() => {
	// 	if (isSelectingElementReceiver) {
	// 		window.addEventListener(Events.ON_ELEMENT_SELECTED, handleElementSelected);
	// 	}
	// 	return () => {
	// 		window.removeEventListener(Events.ON_ELEMENT_SELECTED, handleElementSelected);
	// 	};
	// }, [selectingElementReceiverId]);

	// const extractTypes = (schema) => {
	// 	if (!schema.properties) {
	// 		return { type: schema.type || 'string' };
	// 	}
	// 	return Object.fromEntries(
	// 		Object.entries(schema.properties).map(([key, value]) => {
	// 			if (value?.type === 'object') {
	// 				return [key, extractTypes(value)];
	// 			} else if (value?.type === 'array') {
	// 				const itemType = value.items?.type === 'object' ? extractTypes(value.items) : value.items?.type || 'string';
	// 				return [key, `[${itemType}]`];
	// 			} else {
	// 				return [key, value?.type || 'string'];
	// 			}
	// 		})
	// 	);
	// };


	return (
		<SyntaxHighlighter
			language='json'
			style={a11yLight}
			customStyle={{ fontSize: '12px', padding: '8px', margin: 0, background: 'white', borderRadius: 5, border: '1px solid #e4e4e4', maxHeight: '300px' }}
			wrapLongLines
		>
			{JSON.stringify(schema, null, 2)}
		</SyntaxHighlighter>
	);

	// return (
	// 	<Box>
	// 		<ParameterInput
	// 			value={schema.name}
	// 			typeValue={schema.type}
	// 			isArray={schema.isArray}
	// 			typeOptions={['object', 'text', 'image', 'boolean']}
	// 			allowArray={true}
	// 			editable={editable}
	// 			actions={
	// 				editable ? (
	// 					<>
	// 						{schema.type === 'object' && (
	// 							<Tooltip size='sm' title='Add Sub Field'>
	// 								<IconButton size='sm' variant='plain' color='secondary' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onAddSubField}>
	// 									<PlusIcon fill='currentColor' width={10} height={10} />
	// 								</IconButton>
	// 							</Tooltip>
	// 						)}
	// 						<Tooltip size='sm' title='Give hint'>
	// 							<IconButton
	// 								size='sm'
	// 								variant={schema.elementHint && !isSelectingElementReceiver ? 'soft' : 'plain'}
	// 								color={isSelectingElementReceiver ? 'primary' : 'secondary'}
	// 								sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }}
	// 								onClick={() => {
	// 									if (isSelectingElementReceiver) {
	// 										setSelectingElementReceiverId(null);
	// 									} else {
	// 										setSelectingElementReceiverId(schema.id);
	// 									}
	// 								}}>
	// 								<SelectObjectIcon fill='currentColor' width={12} height={12} />
	// 							</IconButton>
	// 						</Tooltip>
	// 						<Tooltip size='sm' title='Remove'>
	// 							<IconButton size='sm' variant='plain' color='secondary' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRemove}>
	// 								<CloseIcon fill='currentColor' width={10} height={10} />
	// 							</IconButton>
	// 						</Tooltip>
	// 					</>
	// 				) : null
	// 			}
	// 			onChange={(update) => {
	// 				onChange({ ...schema, name: update.value, type: update.typeValue, isArray: update.isArray });
	// 			}}
	// 		/>
	// 		<Box display='flex' flexDirection='column' paddingLeft={1.5} bgcolor='#E4E4E4'>
	// 			{schema.subParameters?.map((subParameter, index) => (
	// 				<>
	// 					<Divider />
	// 					<ExtractSchema
	// 						key={index}
	// 						schema={subParameter}
	// 						editable={editable}
	// 						selectingElementReceiverId={selectingElementReceiverId}
	// 						onRemove={() => {
	// 							const newSubParameters = [...schema.subParameters].filter((es) => es.id !== subParameter.id);
	// 							onChange({ ...schema, subParameters: newSubParameters });
	// 						}}
	// 						onChange={(update) => {
	// 							const newSubParameters = [...schema.subParameters];
	// 							newSubParameters[index] = update;
	// 							onChange({ ...schema, subParameters: newSubParameters });
	// 						}}
	// 					/>
	// 				</>
	// 			))}
	// 		</Box>
	// 	</Box>
	// );
};

const ActNode = ({ node, onChange, onRemove, activeId, onSelected, editable = true }: NodeProps) => {
	const { selectingElementReceiverId, setSelectingElementReceiverId } = useContext(BuilderContext);

	function handleElementSelected(event) {
		console.log('element selected', event);
		const { element } = event.detail;
		onChange({ ...node, elementHint: element });
		setSelectingElementReceiverId(null);
	}

	const isSelectingElementReceiver = selectingElementReceiverId === node.id;


	useEffect(() => {
		if (isSelectingElementReceiver) {
			window.addEventListener(Events.ON_ELEMENT_SELECTED, handleElementSelected);
		}
		return () => {
			window.removeEventListener(Events.ON_ELEMENT_SELECTED, handleElementSelected);
		};
	}, [selectingElementReceiverId]);

	const onAddSubNode = (type: 'act' | 'extract') => {
		if (type === 'extract') {
			const newSubNode = {
				id: uuidv4(),
				type: 'extract',
				schema: {
					id: uuidv4(),
					name: '',
					type: 'object',
					isArray: false,
					subParameters: [],
				},
			};
			onChange({ subNodes: [...node.subNodes, newSubNode] });
		} else {
			const newSubNode = {
				id: uuidv4(),
				type: 'act',
				description: '',
				elementHint: null,
				actionType: 'click',
				subNodes: [],
			};
			onChange({ subNodes: [...node.subNodes, newSubNode] });
		}
	};


	return (
		<>
			<ParameterInput
				key={node.id}
				value={node.description}
				typeValue={node.actionType}
				isArray={false}
				typeOptions={['type', 'click']}
				allowArray={false}
				editable={editable}
				actions={
					editable ? (
						<>
							<Dropdown>
								<Tooltip size='sm' title='Sub Flow'>
									<MenuButton
										slots={{ root: IconButton }}
										slotProps={{ root: { size: 'sm', variant: 'plain', color: 'neutral', sx: { margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' } } }}
									>
										<PlusIcon width={10} height={10} />
									</MenuButton>
								</Tooltip>
								<Menu
									size='sm'
								>
									<MenuItem onClick={() => onAddSubNode('act')}>
									Act
									</MenuItem>
									<MenuItem onClick={() => onAddSubNode('extract')}>
									Extract
									</MenuItem>
								</Menu>
							</Dropdown>
							<Tooltip size='sm' title='Give hint'>
								<IconButton size='sm' variant={node.elementHint ? 'soft' : 'plain'} color={isSelectingElementReceiver ? 'primary' : 'secondary'} sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={() => {
									if (isSelectingElementReceiver) {
										setSelectingElementReceiverId(null);
									} else {
										console.log('setting selectingElementReceiverId', node.id);
										setSelectingElementReceiverId(node.id);
									}
								}}>
									<SelectObjectIcon width={12} height={12} />
								</IconButton>
							</Tooltip>
							<Tooltip size='sm' title='Remove'>
								<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRemove}>
									<CloseIcon width={10} height={10} />
								</IconButton>
							</Tooltip>
						</>
					) : null
				}
				onChange={(update) => {
					onChange({ ...node, description: update.value, actionType: update.typeValue });
				}}
			/>
			<Box display='flex' flexDirection='column' paddingLeft={1.5} bgcolor='#E4E4E4'>
				{node.subNodes?.map((subNode, index) => (
					<>
						<Node
							key={subNode.id}
							node={subNode}
							activeId={activeId}
							onSelected={onSelected}
							isRoot={false}
							onChange={(update) => {
								const newSubNodes = [...node.subNodes];
								newSubNodes[index] = { ...newSubNodes[index], ...update };
								onChange({ subNodes: newSubNodes });
							}}
							onRemove={() => {
								const newSubNodes = [...node.subNodes];
								newSubNodes.splice(index, 1);
								onChange({ subNodes: newSubNodes });
							}}
						/>
					</>
				))}
			</Box>
		</>
	);
};

const Node = ({ node, onChange, onRemove, activeId, onSelected, isRoot = true, editable = true }: NodeProps) => {
	const { browserSession, runningNode, selectingElementReceiverId, setSelectingElementReceiverId } = useContext(BuilderContext);
	const { filters, subNodes } = node;
	const isActive = activeId === node.id;
	const isRunning = runningNode?.id === node.id;
	const [isSelectingElement, setIsSelectingElement] = useState(false);

	useEffect(() => {
		function handleActiveActionEvent(event) {
			if (isSelectingElement) {
				onChange({ element: event.detail.action.element });
			} else {
				const newSubNodes = subNodes ? [...subNodes] : [];
				const newNode = event.detail.node;
				newSubNodes.push(newNode);
				onChange({ subNodes: newSubNodes });
			}
		}

		if (isActive) {
			window.addEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		} else {
			window.removeEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		}
		return () => {
			window.removeEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		};
	}, [isActive, filters, subNodes, node, isSelectingElement]);


	const onAddSubAction = () => {
		const newSubAction = {
			id: `action_${Date.now()}`,
			type: 'action',
			parameter: {
				type: 'text',
				isArray: false,
				name: ''
			},
		};
		onChange({ subNodes: [...(subNodes || []), newSubAction] });
	};

	const onAddPagination = () => {
		const newSubAction = {
			id: `action_${Date.now() + 1}`,
			type: 'pagination',
			parameter: {
				name: '',
				type: 'pagination',
				isArray: false,
			},
		};
		onChange({ subNodes: [...(subNodes || []), newSubAction] });
	};

	const onRun = () => {
		API.runNodeOnBrowserSession(node, browserSession.publicId);
	};

	const onSelectElement = () => {
		setIsSelectingElement(!isSelectingElement);
	};

	return (
		<Box>
			<Box display='flex'>
				<ActionHeader isRoot={isRoot}>
					<Typography level='body-xs' fontWeight='bold'>{node.type.toUpperCase()}</Typography>
					{editable && (
						<Box marginLeft={1} display='flex' alignItems='center'>
							<Tooltip size='sm' title='Run'>
								<IconButton loading={isRunning} size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRun}>
									<PlayIcon fill='currentColor' width={10} height={10} />
								</IconButton>
							</Tooltip>
						</Box>
					)}
				</ActionHeader>
			</Box>
			<Box key={node.id} border={isRoot ? '1px solid #E4E4E4' : 'none'} borderTop='1px solid #E4E4E4' borderRadius={5} sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }} overflow='hidden'>
				<Box display='flex' flexDirection='column' position='relative'>
					<Box
						display='flex'
						flexDirection='column'
						onClick={(e) => {
							e.stopPropagation();
							onSelected(node);
						}}>
						{node.type === 'act' && (
							<ActNode
								node={node}
								onChange={update => onChange({ ...node, ...update })}
								onRemove={onRemove}
								activeId={activeId}
								onSelected={onSelected}
								editable={editable}
							/>
						)}
						{node.type === 'extract' && (
							<ExtractSchema
								schema={node.schema}
								onChange={(update) => onChange({ ...node, schema: update })}
								onRemove={onRemove}
								selectingElementReceiverId={selectingElementReceiverId}
								editable={editable}
							/>
						)}
						{filters?.map((filter, index) => (
							<>
								<Divider />
								<Box key={index} display='grid' gap={1} gridTemplateColumns='1fr'>
									<Input
										value={filter.value}
										size='sm'
										fullWidth
										sx={{
											paddingLeft: 0,
											flexGrow: 1,
											borderRadius: 0,
											'&:before': {
												boxShadow: 'none',
											},
										}}
										variant='plain'
										placeholder='value'
										onChange={(e) => {
											const newFilters = [...filters];
											newFilters[index].value = e.target.value;
											onChange({ filters: newFilters });
										}}
										startDecorator={
											<>
												<Button size='sm' variant='plain' color='neutral' sx={{ whiteSpace: 'nowrap', borderTopRightRadius: 0, borderBottomRightRadius: 0, margin: 0, height: '100%', fontSize: '12px', paddingX: '6px' }}>
													{filter.element ? `${filter.element.tagName.toLowerCase()}.${filter.attribute}` : '-'}
												</Button>
												<Divider orientation='vertical' />
												<Select
													size='sm'
													variant='plain'
													color='neutral'
													sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRadius: 0, overflow: 'hidden' }}
													defaultValue='equals'
													indicator={null}
													renderValue={(value) => {
														return <Chip size='sm' variant='outlined' color='neutral'>{value.label}</Chip>;
													}}
													value={filter.operator}
													onChange={(e, newValue) => {
														const newFilters = [...filters];
														newFilters[index].operator = newValue;
														onChange({ filters: newFilters });
													}}
												>
													<Option value='exists'><Chip size='sm' variant='outlined' color='neutral'>Exists</Chip></Option>
													<Option value='equals'><Chip size='sm' variant='outlined' color='neutral'>Equals</Chip></Option>
													<Option value='contains'><Chip size='sm' variant='outlined' color='neutral'>Contains</Chip></Option>
													<Option value='regex'><Chip size='sm' variant='outlined' color='neutral'>Regex</Chip></Option>
												</Select>
												<Divider orientation='vertical' />
											</>
										}
									/>
								</Box>
							</>
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Node;
