import React from 'react';

export default function Icon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg 
			fill="none" 
			height="512" 
			viewBox="0 0 24 24" 
			width="512" 
			xmlns="http://www.w3.org/2000/svg" 
			{...props}
		>
			<g fill="currentColor">
				<path d="m2.5798 19.01-.02.02c-.27-.59-.44-1.26-.51-2 .07.73.26 1.39.53 1.98z"/>
				<path d="m9.00012 10.38c1.31448 0 2.37998-1.06557 2.37998-2.38 0-1.31444-1.0655-2.38-2.37998-2.38-1.31444 0-2.38 1.06556-2.38 2.38 0 1.31443 1.06556 2.38 2.38 2.38z"/>
				<path d="m16.19 2h-8.38c-3.64 0-5.81 2.17-5.81 5.81v8.38c0 1.09.19 2.04.56 2.84.86 1.9 2.7 2.97 5.25 2.97h8.38c3.64 0 5.81-2.17 5.81-5.81v-2.29-6.09c0-3.64-2.17-5.81-5.81-5.81zm4.18 10.5c-.78-.67-2.04-.67-2.82 0l-4.16 3.57c-.78.67-2.04.67-2.82 0l-.34-.28c-.71-.62-1.84-.68-2.64-.14l-3.74 2.51c-.22-.56-.35-1.21-.35-1.97v-8.38c0-2.82 1.49-4.31 4.31-4.31h8.38c2.82 0 4.31 1.49 4.31 4.31v4.8z"/>
			</g>
		</svg>
	);
}
