import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Divider, Typography } from '@mui/joy';
import API from '@/api';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { BuilderContext, BuilderContextProvider, SELECT_MODE } from '../Builder/BuilderContext';
import BrowserIFrame from '../Builder/BrowserIFrame';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { AppContext } from '@/context/AppContext';
import WebEditorChat from './WebEditorChat';

const PageContainer = styled(Box)`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	background-color: #fafafa;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	background-size: 32px 32px;
`;

const PageContentContainer = styled(Box)`
	display: flex;
	gap: 8px;
	height: 100%;
	padding: 8px;
`;

const LoadingContainer = styled(Box)<{ loading: boolean }>`
	width: 1600px;
	height: 900px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(240, 240, 240, 0.60);
	backdrop-filter: blur(4px);
	pointer-events: none;
	// transition: background-color 0.20s ease-in-out, backdrop-filter 0.20s ease-in-out;
  position: absolute;
  width: 1600px;
  height: 900px;
  top: 0;
  left: 0;
	transform-origin: 0 0;
`;

const IframeContainer = styled(Box)`
	flex-grow: 1;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: fit-content;
`;

const RightContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 8px;
  height: 100%;
  flex-grow: 1;
`;

const OutputContainer = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  overflow: hidden;
`;


const WebEditor: React.FC = () => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [loading, setLoading] = useState(true);
	const {
		browserSession,
		setBrowserSession,
		selectedWebFlow,
		setSelectedWebFlow,
	} = useContext(BuilderContext);
	const { user } = useContext(AppContext);
	const [output, setOutput] = useState({});
	const [iframeZoom, setIframeZoom] = useState(1);
	const iframeContainerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (iframeContainerRef.current) {
			const handleResize = () => {
				setIframeZoom((iframeContainerRef.current.clientWidth - 0) / 1600);
			};
			handleResize();
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, [iframeContainerRef, browserSession]);

	function handleNodeEnd(event: CustomEvent) {
		const { nodeId, response } = event.detail;
		const node = selectedWebFlow?.nodes.find(a => a.id === nodeId);
		console.log('node-end', node, nodeId, response);
		if (node?.type === 'extract') {
			setOutput({ ...output, ...response });
		}
	}

	useEffect(() => {

		window.addEventListener('node-end', handleNodeEnd);

		return () => {
			window.removeEventListener('node-end', handleNodeEnd);
		};
	}, [selectedWebFlow?.nodes]);


	useEffect(() => {
		const setup = async () => {
			setLoading(true);
			setSelectedWebFlow( {
				name: 'New Web Flow',
				start_url: 'https://ycombinator.com',
				description: '',
				nodes: [],
				parameters: [],
				icon_url: null,
			});
			const res = await API.getBrowserSession({ type: 'ai' });
			const session = res.browserSession;
			setBrowserSession(session);
			setLoading(false);
			console.log('Session', session);
		};
		setup();
	}, [user]);


	return (
		<PageContainer>
			<Helmet>
				<title>Plato</title>
			</Helmet>
			<PageContentContainer>
				<WebEditorChat />
				<RightContainer>
					<Box display='flex' justifyContent='center' alignItems='center'>
						<IframeContainer flexGrow={1} ref={iframeContainerRef} boxShadow='md'>
							<Box
								sx={{
									overflow: 'hidden',
									position: 'relative',
									width: '100%',
									height: 0,
									paddingTop: 'calc(9/16 * 100%)',
								}}
							>
								{browserSession ? (
									<BrowserIFrame
										style={{
											position: 'absolute',
											width: '1600px',
											height: '900px',
											top: 0,
											left: 0,
											transform: `scale(${iframeZoom})`,
											transformOrigin: '0 0',
										}}
										browserSessionId={browserSession.publicId}
									/>
								) : (
									<LoadingContainer loading={loading} sx={{ transform: `scale(${iframeZoom})` }}>
										{loading ? <CircularProgress color='secondary' /> : <Typography>No browsers available. Please try again in a couple minutes.</Typography>}
									</LoadingContainer>
								)}

							</Box>
						</IframeContainer>
					</Box>
					<OutputContainer boxShadow='md'>
						<Box padding={2} paddingY={1}>
							<Typography fontSize='18px' fontWeight='bold' level='title-md'>Output</Typography>
						</Box>
						<Divider />
						<Box p={2} bgcolor='neutral.700' flexGrow={1} sx={{ overflowY: 'auto' }}>
							<SyntaxHighlighter showLineNumbers language='json' style={a11yDark} customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }} wrapLongLines>
								{JSON.stringify(output, null, 2)}
							</SyntaxHighlighter>
						</Box>
					</OutputContainer>
				</RightContainer>
			</PageContentContainer>
		</PageContainer>
	);
};

const WebEditorWrapper = () => {
	return (
		<WebEditor />
	);
};

export default WebEditorWrapper;
