import React, { useContext, useEffect, useState } from 'react';
import Main from '@/components/Main';
import Header from '@/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup } from '@mui/joy';
import { Paper } from '@mui/material';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? 'var(--joy-palette-neutral-100)' : 'transparent'};
`;

const WebFlowStateToChipColor = {
	completed: 'success',
	pending: 'warning',
	running: 'primary',
	failed: 'danger',
};

const WebFlowRunsPage: React.FC = () => {
	const [webFlowRuns, setWebFlowRuns] = useState([]);
	const [selectedWebFlowRun, setSelectedWebFlowRun] = useState(null);
	const [view, setView] = useState('response');

	useEffect(() => {
		API.getWebFlowRuns().then((data) => {
			console.log(data);
			setWebFlowRuns(data);
			setSelectedWebFlowRun(data[0]);
		});
	}, []);

	useEffect(() => {
		if (selectedWebFlowRun) {
			setView('response');
		}
	}, [selectedWebFlowRun]);

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Web Flow Runs</Typography>
			<Grid container spacing={2}>
				<Grid xs={12} md={7}>
					<Paper style={{ padding: 0 }}>
						<table>
							<thead>
								<tr>
									<th><Typography level='title-sm' color='neutral'>Web Flow</Typography></th>
									<th><Typography level='title-sm' color='neutral'>State</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Time</Typography></th>
								</tr>
							</thead>
							<tbody>
								{webFlowRuns.map((wfr) => (
									<UsageRow active={selectedWebFlowRun?.id === wfr.id} key={wfr.id} onClick={() => setSelectedWebFlowRun(wfr)}>
										<td>
											<Box display='flex' alignItems='center' gap={1}>
												<img width={16} height={16} src={wfr?.web_flow?.icon_url || '/favicon.ico'} alt={wfr?.web_flow?.name} />
												<Typography level='title-sm'>{wfr?.web_flow?.name}</Typography>
											</Box>
										</td>
										<td>
											<Chip size='sm' color={WebFlowStateToChipColor[wfr.state]}>{wfr.state.charAt(0).toUpperCase() + wfr.state.slice(1)}</Chip>
										</td>
										<td>
											<Typography level='body-sm'>{moment(wfr.started_at).fromNow()}</Typography>
										</td>
									</UsageRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={5}>
					<Paper style={{ padding: 0 }}>
						<Section>
							<Typography level='title-md'>{selectedWebFlowRun?.publicId}</Typography>
						</Section>
						<Divider/>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Web Flow</Typography>
								<Box display='flex' alignItems='center' gap={0.5}>
									<img width={16} height={16} src={selectedWebFlowRun?.web_flow?.icon_url || '/favicon.ico'} alt={selectedWebFlowRun?.web_flow?.name} />
									<Typography level='title-sm'>{selectedWebFlowRun?.web_flow?.name}</Typography>
								</Box>
								<Typography color='neutral' level='title-sm'>State</Typography>
								<Chip size='sm' color={WebFlowStateToChipColor[selectedWebFlowRun?.state]}>{selectedWebFlowRun?.state.charAt(0).toUpperCase() + selectedWebFlowRun?.state.slice(1)}</Chip>
								<Typography whiteSpace='nowrap' color='neutral' level='title-sm'>Started at</Typography>
								<Typography level='body-sm'>{moment(selectedWebFlowRun?.started_at).fromNow()}</Typography>
								<Typography whiteSpace='nowrap' color='neutral' level='title-sm'>Completed at</Typography>
								<Typography level='body-sm'>{moment(selectedWebFlowRun?.completed_at).fromNow()}</Typography>
								<Typography whiteSpace='nowrap' color='neutral' level='title-sm'>Duration</Typography>
								<Typography level='body-sm'>{moment(selectedWebFlowRun?.completed_at).diff(selectedWebFlowRun?.started_at, 'seconds')} seconds</Typography>
							</Box>
						</Section>
						<Divider/>
						<Section>
							<Box display='flex' gap={1} marginBottom={1}>
								<ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='response' size='sm' color={view === 'response' ? 'secondary' : 'neutral'} variant={view === 'response' ? 'solid' : 'outlined'}>Response</Button>
									<Button value='request' size='sm' color={view === 'request' ? 'secondary' : 'neutral'} variant={view === 'request' ? 'solid' : 'outlined'}>Request</Button>
								</ToggleButtonGroup>
							</Box>
							{view === 'response' ? (
								<>
									<Typography level='title-md'>Response body</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedWebFlowRun?.response, null, 2)}
									</SyntaxHighlighter>
								</>
							) : (
								<>
									<Typography level='title-md'>Request parameters</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedWebFlowRun?.parameters || {}, null, 2)}
									</SyntaxHighlighter>
								</>
							)}
						</Section>
					</Paper>
				</Grid>
			</Grid>
		</DashboardContainer>
	);
};

export default WebFlowRunsPage;
