import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Input, Select, Chip, IconButton, Tooltip, Checkbox, ListItem, Option, CircularProgress, Typography } from '@mui/joy';
import CubeIcon from '@/icons/CubeIcon';
import TextIcon from '@/icons/TextIcon';
import ImageIcon from '@/icons/ImageIcon';
import CursorClickIcon from '@/icons/CursorClickIcon';
import CursorTypingIcon from '@/icons/CursorTypingIcon';
import SparkleIcon from '@/icons/SparkleIcon';
import styled from '@emotion/styled';
import { BuilderContext } from '../BuilderContext';
import moment from 'moment';

const Key = styled(Box)`
	padding: 2px 4px;
	border: 1px solid #e4e4e4;
	border-radius: 4px;
	box-shadow: 0 -1px inset #e4e4e4;
	color: var(--joy-palette-text-primary);
	background: white;
`;


const PARAMETER_TYPES = {
	act: {
		label: 'Act',
		color: 'secondary',
		placeholder: 'What to do?',
		variant: 'solid',
		icon: <SparkleIcon fill='currentColor' width={10} height={10} />,
	},
	extract: {
		label: 'Extract',
		color: 'secondary',
		variant: 'solid',
		icon: <SparkleIcon fill='currentColor' width={10} height={10} />,
	},
	navigate: {
		label: 'URL',
		color: 'neutral',
		variant: 'plain',
		placeholder: 'URL',
		icon: null,
		// icon: <WebIcon fill='currentColor' width={10} height={10} />,
	},
	object: {
		label: 'Object',
		color: 'success',
		icon: <CubeIcon fill='currentColor' width={10} height={10} />,
	},
	text: {
		label: 'Text',
		color: 'neutral',
		icon: <TextIcon fill='currentColor' width={10} height={10} />,
	},
	string: {
		label: 'String',
		color: 'neutral',
		icon: <TextIcon fill='currentColor' width={10} height={10} />,
	},
	image: {
		label: 'Image',
		color: 'danger',
		icon: <ImageIcon fill='currentColor' width={10} height={10} />,
	},
	click: {
		label: 'Click',
		color: 'primary',
		placeholder: 'The element(s) to click',
		icon: <CursorClickIcon fill='currentColor' width={10} height={10} />,
	},
	type: {
		label: 'Type',
		color: 'warning',
		placeholder: 'Text to type. [Key] to press a specific key (ex. [Enter])',
		icon: <CursorTypingIcon fill='currentColor' width={10} height={10} />,
	},
	pagination: {
		label: 'Pagination',
		color: 'secondary',
		placeholder: 'Give instructions',
		icon: <SparkleIcon fill='currentColor' width={10} height={10} />,
	},
	number: {
		label: 'Number',
		color: 'primary',
	},
	boolean: {
		label: 'Boolean',
		color: 'warning',
	},
};

interface ParameterInputProps {
	typeOptions: string[];
	value: any;
	typeValue: string;
	isArray: boolean;
	allowArray: boolean;
	disableTypeChange?: boolean;
	actions?: React.ReactNode;
	editable?: boolean;
	onChange: (value: any) => void;
	onRemove?: () => void;
	isActive?: boolean;
}

const ParameterInput = (props: ParameterInputProps) => {
	const {
		actions,
		value,
		typeValue,
		isArray,
		typeOptions,
		onChange,
		allowArray,
		disableTypeChange,
		editable = true,
	} = props;

	const _onChange = (update) => {
		onChange({ value, typeValue, isArray, ...update });
	};

	const [isFocused, setIsFocused] = useState(false);

	const renderedValue = useMemo(() => {
		if (typeValue === 'type') {
			const words = value.split(' ');
			// but if the word starts with [ and ends with ], then render it in a box with a border
			return (
				<Typography level='body-sm' whiteSpace='nowrap'>
					{words.map((word, index) => {
						if (word.startsWith('[') && word.endsWith(']')) {
							return <Key component='span' key={word}>
								{word.slice(1, -1)}{index === words.length - 1 ? '' : ' '}
							</Key>;
						}
						return <Typography sx={{ color: 'var(--joy-palette-text-primary)' }} level='body-sm' key={word}>{word}{index === words.length - 1 ? '' : ' '}</Typography>;
					})}
				</Typography>
			);
		}
		return <Typography whiteSpace='nowrap' sx={{ color: 'var(--joy-palette-text-primary)' }} level='body-sm'>{value}</Typography>;
	}, [value, typeValue]);

	return (
		<Box display='flex' alignItems='center'>
			<Input
				size='sm'
				variant='plain'
				sx={{
					flexGrow: 1,
					paddingLeft: 0,
					borderRadius: 0,
					color: 'var(--joy-palette-text-primary)',
					'&:before': {
						boxShadow: 'none',
					},
					'&:disabled': {
						color: 'var(--joy-palette-text-primary)',
					},
				}}
				onClick={(e) => setIsFocused(true)}
				onBlur={(e) => setIsFocused(false)}
				autoFocus={isFocused}
				placeholder={PARAMETER_TYPES[typeValue]?.placeholder || 'name'}
				value={value}
				onChange={(e) => _onChange({ value: e.target.value })}
				required
				slots={(typeValue === 'type' && !isFocused) || !editable ? {
					input: (props) => {
						return (
							<Typography sx={{ color: 'var(--joy-palette-text-primary)' }} flexGrow={1} display='flex' alignItems='center' level='body-sm'>{renderedValue}</Typography>
						);
					}
				} : {}}
				slotProps={{
					startDecorator: {
						sx: {
							margin: 0,
						},
					},
				}}
				startDecorator={
					<>
						<Select
							sx={{ borderRadius: 0, overflow: 'hidden' }}
							value={typeValue}
							size='sm'
							variant='plain'
							color='neutral'
							onChange={(e, newValue) => _onChange({ typeValue: newValue })}
							indicator={null}
							disabled={disableTypeChange || !editable}
							renderValue={({ value }) => {
								return <Chip sx={{ minWidth: '64px' }} startDecorator={PARAMETER_TYPES[value].icon ? PARAMETER_TYPES[value].icon : null} size='sm' variant={PARAMETER_TYPES[value].variant || 'soft'} color={PARAMETER_TYPES[value].color}>{PARAMETER_TYPES[value].label}{isArray && ' []'}</Chip>; }}
						>
							{allowArray && (
								<ListItem key='array'>
									<Checkbox label='Is Array' size='sm' checked={isArray} onChange={(e) => _onChange({ isArray: e.target.checked })} />
								</ListItem>
							)}
							{typeOptions.map((type) => (
								<Option value={type} key={type}>
									<Chip sx={{ minWidth: '64px' }} startDecorator={PARAMETER_TYPES[type].icon ? PARAMETER_TYPES[type].icon : null} size='sm' variant={PARAMETER_TYPES[type].variant || 'soft'} color={PARAMETER_TYPES[type].color}>{PARAMETER_TYPES[type].label}</Chip>
								</Option>
							))}
						</Select>
					</>
				}
				endDecorator={actions}
			>
				{renderedValue}
			</Input>
		</Box>
	);
};

export default ParameterInput;
