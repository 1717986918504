import React from 'react';

export default function CustomIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			id='svg1044'
			height='512'
			viewBox='0 0 135.46666 135.46667'
			width='512'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g id='layer1'>
				<path
					id='rect7266'
					d='m41.105359-.00000573c-4.88551 0-8.819214 3.93370003-8.819214 8.81921003 0 4.8854097 3.933704 8.8192197 8.819214 8.8192197h17.80877v100.189706h-17.80877c-4.88551 0-8.819214 3.93391-8.819214 8.81921 0 4.88552 3.933704 8.81933 8.819214 8.81933h53.26167c4.88552 0 8.813501-3.93381 8.813501-8.81933 0-4.8853-3.927981-8.81921-8.813501-8.81921h-17.81448v-100.189706h17.81448c4.88552 0 8.813501-3.93381 8.813501-8.8192197 0-4.88551-3.927981-8.81921003-8.813501-8.81921003z'
					stroke-linecap='round'
					stroke-linejoin='round'
					stroke-width='54.911'
					paint-order='stroke fill markers'
				/>
			</g>
		</svg>
	);
}
