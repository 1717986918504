import React from 'react';
import SideNavigation from './SideNavigation';
import styled from '@emotion/styled';
import { Box } from '@mui/joy';
import { Helmet } from 'react-helmet';


interface DashboardContainerProps {
	children: React.ReactNode;
	mainProps?: React.HTMLAttributes<HTMLDivElement>;
}

const Container = styled(Box)`
	height: 100vh;
	display: flex;
`;

const SideContainer = styled(Box)`
  width: 270px;
`;

const Main = styled.main`
  flex: 1;
	padding: 2rem;
	overflow-y: auto;
`;

const DashboardContainer = (props: DashboardContainerProps) => {
	// drawer side navigation on the left
	// content on the right
	return (
		<Container>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
			</Helmet>
			<SideContainer>
				<SideNavigation />
			</SideContainer>
			<Main {...props.mainProps}>
				{props.children}
			</Main>
		</Container>
	);
};

export default DashboardContainer;
