import React from 'react';

export default function CustomIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.813 511.813"
			id="fi_2641415"
			enableBackground="new 0 0 511.813 511.813"
			{...props}
		>
			<g>
				<path d="m241.151 267.565-213.946-122.254v244.247l213.946 122.255z"></path>
				<path d="m270.661 267.565v244.248l213.947-122.255v-244.247z"></path>
				<path d="m42.189 119.885 213.717 122.123 213.717-122.123-206.396-117.941c-4.536-2.592-10.105-2.592-14.641 0z"></path>
			</g>
		</svg>
	);
}
